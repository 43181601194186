import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IDeploymentAdministration } from 'app/shared/model/deployment-administration.model';
import { getEntity, updateEntity, createEntity, reset } from './deployment-administration.reducer';

export const DeploymentAdministrationUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const deploymentAdministrationEntity = useAppSelector(state => state.deploymentAdministration.entity);
  const loading = useAppSelector(state => state.deploymentAdministration.loading);
  const updating = useAppSelector(state => state.deploymentAdministration.updating);
  const updateSuccess = useAppSelector(state => state.deploymentAdministration.updateSuccess);

  const handleClose = () => {
    navigate('/deployment-administration' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.departureTime = convertDateTimeToServer(values.departureTime);
    values.dateExternal = convertDateTimeToServer(values.dateExternal);
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.lastModifiedDate = convertDateTimeToServer(values.lastModifiedDate);

    const entity = {
      ...deploymentAdministrationEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          departureTime: displayDefaultDateTime(),
          dateExternal: displayDefaultDateTime(),
          createdDate: displayDefaultDateTime(),
          lastModifiedDate: displayDefaultDateTime(),
        }
      : {
          ...deploymentAdministrationEntity,
          departureTime: convertDateTimeFromServer(deploymentAdministrationEntity.departureTime),
          dateExternal: convertDateTimeFromServer(deploymentAdministrationEntity.dateExternal),
          createdDate: convertDateTimeFromServer(deploymentAdministrationEntity.createdDate),
          lastModifiedDate: convertDateTimeFromServer(deploymentAdministrationEntity.lastModifiedDate),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="integrawebApp.deploymentAdministration.home.createOrEditLabel" data-cy="DeploymentAdministrationCreateUpdateHeading">
            <Translate contentKey="integrawebApp.deploymentAdministration.home.createOrEditLabel">
              Create or edit a DeploymentAdministration
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="deployment-administration-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('integrawebApp.deploymentAdministration.deliveryDate')}
                id="deployment-administration-deliveryDate"
                name="deliveryDate"
                data-cy="deliveryDate"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.deploymentAdministration.tourNr')}
                id="deployment-administration-tourNr"
                name="tourNr"
                data-cy="tourNr"
                type="text"
                validate={{
                  maxLength: { value: 5, message: translate('entity.validation.maxlength', { max: 5 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.deploymentAdministration.rideNr')}
                id="deployment-administration-rideNr"
                name="rideNr"
                data-cy="rideNr"
                type="text"
                validate={{
                  maxLength: { value: 2, message: translate('entity.validation.maxlength', { max: 2 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.deploymentAdministration.carrierNr')}
                id="deployment-administration-carrierNr"
                name="carrierNr"
                data-cy="carrierNr"
                type="text"
                validate={{
                  maxLength: { value: 5, message: translate('entity.validation.maxlength', { max: 5 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.deploymentAdministration.departureTime')}
                id="deployment-administration-departureTime"
                name="departureTime"
                data-cy="departureTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.deploymentAdministration.deploymentNr')}
                id="deployment-administration-deploymentNr"
                name="deploymentNr"
                data-cy="deploymentNr"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.deploymentAdministration.warehouseId')}
                id="deployment-administration-warehouseId"
                name="warehouseId"
                data-cy="warehouseId"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.deploymentAdministration.storageLocation')}
                id="deployment-administration-storageLocation"
                name="storageLocation"
                data-cy="storageLocation"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.deploymentAdministration.comment')}
                id="deployment-administration-comment"
                name="comment"
                data-cy="comment"
                type="text"
                validate={{
                  maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.deploymentAdministration.statusComparison')}
                id="deployment-administration-statusComparison"
                name="statusComparison"
                data-cy="statusComparison"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.deploymentAdministration.statusExternal')}
                id="deployment-administration-statusExternal"
                name="statusExternal"
                data-cy="statusExternal"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.deploymentAdministration.dateExternal')}
                id="deployment-administration-dateExternal"
                name="dateExternal"
                data-cy="dateExternal"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.deploymentAdministration.createdDate')}
                id="deployment-administration-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.deploymentAdministration.createdBy')}
                id="deployment-administration-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
                validate={{
                  maxLength: { value: 4, message: translate('entity.validation.maxlength', { max: 4 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.deploymentAdministration.lastModifiedDate')}
                id="deployment-administration-lastModifiedDate"
                name="lastModifiedDate"
                data-cy="lastModifiedDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.deploymentAdministration.lastModifiedBy')}
                id="deployment-administration-lastModifiedBy"
                name="lastModifiedBy"
                data-cy="lastModifiedBy"
                type="text"
                validate={{
                  maxLength: { value: 4, message: translate('entity.validation.maxlength', { max: 4 }) },
                }}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/deployment-administration" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DeploymentAdministrationUpdate;
