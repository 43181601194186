import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './nve-inhalt-warenausgang.reducer';

export const NVEInhaltWarenausgangDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const nVEInhaltWarenausgangEntity = useAppSelector(state => state.nVEInhaltWarenausgang.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="nVEInhaltWarenausgangDetailsHeading">
          <Translate contentKey="integrawebApp.nVEInhaltWarenausgang.detail.title">NVEInhaltWarenausgang</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{nVEInhaltWarenausgangEntity.id}</dd>
          <dt>
            <span id="quantityId">
              <Translate contentKey="integrawebApp.nVEInhaltWarenausgang.quantityId">Quantity Id</Translate>
            </span>
          </dt>
          <dd>{nVEInhaltWarenausgangEntity.quantityId}</dd>
          <dt>
            <span id="bookIdNr">
              <Translate contentKey="integrawebApp.nVEInhaltWarenausgang.bookIdNr">Book Id Nr</Translate>
            </span>
          </dt>
          <dd>{nVEInhaltWarenausgangEntity.bookIdNr}</dd>
          <dt>
            <span id="weight">
              <Translate contentKey="integrawebApp.nVEInhaltWarenausgang.weight">Weight</Translate>
            </span>
          </dt>
          <dd>{nVEInhaltWarenausgangEntity.weight}</dd>
          <dt>
            <span id="employeeId">
              <Translate contentKey="integrawebApp.nVEInhaltWarenausgang.employeeId">Employee Id</Translate>
            </span>
          </dt>
          <dd>{nVEInhaltWarenausgangEntity.employeeId}</dd>
          <dt>
            <span id="quantity">
              <Translate contentKey="integrawebApp.nVEInhaltWarenausgang.quantity">Quantity</Translate>
            </span>
          </dt>
          <dd>{nVEInhaltWarenausgangEntity.quantity}</dd>
          <dt>
            <span id="employeeontrolId">
              <Translate contentKey="integrawebApp.nVEInhaltWarenausgang.employeeontrolId">Employeeontrol Id</Translate>
            </span>
          </dt>
          <dd>{nVEInhaltWarenausgangEntity.employeeontrolId}</dd>
          <dt>
            <span id="quantityControl">
              <Translate contentKey="integrawebApp.nVEInhaltWarenausgang.quantityControl">Quantity Control</Translate>
            </span>
          </dt>
          <dd>{nVEInhaltWarenausgangEntity.quantityControl}</dd>
          <dt>
            <span id="controlDate">
              <Translate contentKey="integrawebApp.nVEInhaltWarenausgang.controlDate">Control Date</Translate>
            </span>
          </dt>
          <dd>
            {nVEInhaltWarenausgangEntity.controlDate ? (
              <TextFormat value={nVEInhaltWarenausgangEntity.controlDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="integrawebApp.nVEInhaltWarenausgang.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {nVEInhaltWarenausgangEntity.createdDate ? (
              <TextFormat value={nVEInhaltWarenausgangEntity.createdDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="integrawebApp.nVEInhaltWarenausgang.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{nVEInhaltWarenausgangEntity.createdBy}</dd>
          <dt>
            <span id="lastModifiedDate">
              <Translate contentKey="integrawebApp.nVEInhaltWarenausgang.lastModifiedDate">Last Modified Date</Translate>
            </span>
          </dt>
          <dd>
            {nVEInhaltWarenausgangEntity.lastModifiedDate ? (
              <TextFormat value={nVEInhaltWarenausgangEntity.lastModifiedDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="lastModifiedBy">
              <Translate contentKey="integrawebApp.nVEInhaltWarenausgang.lastModifiedBy">Last Modified By</Translate>
            </span>
          </dt>
          <dd>{nVEInhaltWarenausgangEntity.lastModifiedBy}</dd>
        </dl>
        <Button tag={Link} to="/nve-inhalt-warenausgang" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/nve-inhalt-warenausgang/${nVEInhaltWarenausgangEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default NVEInhaltWarenausgangDetail;
