import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IVehicle } from 'app/shared/model/vehicle.model';
import { getEntities } from './vehicle.reducer';

export const Vehicle = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const vehicleList = useAppSelector(state => state.vehicle.entities);
  const loading = useAppSelector(state => state.vehicle.loading);
  const totalItems = useAppSelector(state => state.vehicle.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <div>
      <h2 id="vehicle-heading" data-cy="VehicleHeading">
        <Translate contentKey="integrawebApp.vehicle.home.title">Vehicles</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="integrawebApp.vehicle.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/vehicle/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="integrawebApp.vehicle.home.createLabel">Create new Vehicle</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {vehicleList && vehicleList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="integrawebApp.vehicle.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vehicleName')}>
                  <Translate contentKey="integrawebApp.vehicle.vehicleName">Vehicle Name</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('licensePlate')}>
                  <Translate contentKey="integrawebApp.vehicle.licensePlate">License Plate</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vehicleType')}>
                  <Translate contentKey="integrawebApp.vehicle.vehicleType">Vehicle Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('costCenter')}>
                  <Translate contentKey="integrawebApp.vehicle.costCenter">Cost Center</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('registrationDate')}>
                  <Translate contentKey="integrawebApp.vehicle.registrationDate">Registration Date</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('payloadTons')}>
                  <Translate contentKey="integrawebApp.vehicle.payloadTons">Payload Tons</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('loadingVolume')}>
                  <Translate contentKey="integrawebApp.vehicle.loadingVolume">Loading Volume</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('fixedCosts')}>
                  <Translate contentKey="integrawebApp.vehicle.fixedCosts">Fixed Costs</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('variableCosts')}>
                  <Translate contentKey="integrawebApp.vehicle.variableCosts">Variable Costs</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('trailerSurcharge')}>
                  <Translate contentKey="integrawebApp.vehicle.trailerSurcharge">Trailer Surcharge</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('performanceKW')}>
                  <Translate contentKey="integrawebApp.vehicle.performanceKW">Performance KW</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('trailerSurchargeMinute')}>
                  <Translate contentKey="integrawebApp.vehicle.trailerSurchargeMinute">Trailer Surcharge Minute</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('correctionFactor')}>
                  <Translate contentKey="integrawebApp.vehicle.correctionFactor">Correction Factor</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('correctionSetupTime')}>
                  <Translate contentKey="integrawebApp.vehicle.correctionSetupTime">Correction Setup Time</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('cargoAreaSM')}>
                  <Translate contentKey="integrawebApp.vehicle.cargoAreaSM">Cargo Area SM</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('correctionSpecifiedTime')}>
                  <Translate contentKey="integrawebApp.vehicle.correctionSpecifiedTime">Correction Specified Time</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('maxPitches')}>
                  <Translate contentKey="integrawebApp.vehicle.maxPitches">Max Pitches</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('rearLoading')}>
                  <Translate contentKey="integrawebApp.vehicle.rearLoading">Rear Loading</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('freightRate')}>
                  <Translate contentKey="integrawebApp.vehicle.freightRate">Freight Rate</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('recordStatus')}>
                  <Translate contentKey="integrawebApp.vehicle.recordStatus">Record Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('carrierId')}>
                  <Translate contentKey="integrawebApp.vehicle.carrierId">Carrier Id</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('customerId')}>
                  <Translate contentKey="integrawebApp.vehicle.customerId">Customer Id</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('supplierId')}>
                  <Translate contentKey="integrawebApp.vehicle.supplierId">Supplier Id</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vehicleCertification')}>
                  <Translate contentKey="integrawebApp.vehicle.vehicleCertification">Vehicle Certification</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('setId')}>
                  <Translate contentKey="integrawebApp.vehicle.setId">Set Id</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('createdDate')}>
                  <Translate contentKey="integrawebApp.vehicle.createdDate">Created Date</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('createdBy')}>
                  <Translate contentKey="integrawebApp.vehicle.createdBy">Created By</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('lastModifiedDate')}>
                  <Translate contentKey="integrawebApp.vehicle.lastModifiedDate">Last Modified Date</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('lastModifiedBy')}>
                  <Translate contentKey="integrawebApp.vehicle.lastModifiedBy">Last Modified By</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('noMDEdevice')}>
                  <Translate contentKey="integrawebApp.vehicle.noMDEdevice">No MD Edevice</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('lockedDateFrom')}>
                  <Translate contentKey="integrawebApp.vehicle.lockedDateFrom">Locked Date From</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('lockedDateTill')}>
                  <Translate contentKey="integrawebApp.vehicle.lockedDateTill">Locked Date Till</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('siteWages')}>
                  <Translate contentKey="integrawebApp.vehicle.siteWages">Site Wages</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('carTypeWages')}>
                  <Translate contentKey="integrawebApp.vehicle.carTypeWages">Car Type Wages</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('tareWeightTons')}>
                  <Translate contentKey="integrawebApp.vehicle.tareWeightTons">Tare Weight Tons</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('tankFillingKG')}>
                  <Translate contentKey="integrawebApp.vehicle.tankFillingKG">Tank Filling KG</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('nextTUVDate')}>
                  <Translate contentKey="integrawebApp.vehicle.nextTUVDate">Next TUV Date</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('nextASUDate')}>
                  <Translate contentKey="integrawebApp.vehicle.nextASUDate">Next ASU Date</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('certificationTill')}>
                  <Translate contentKey="integrawebApp.vehicle.certificationTill">Certification Till</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('leasingStart')}>
                  <Translate contentKey="integrawebApp.vehicle.leasingStart">Leasing Start</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('leasingEnd')}>
                  <Translate contentKey="integrawebApp.vehicle.leasingEnd">Leasing End</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('nextInspectionDate')}>
                  <Translate contentKey="integrawebApp.vehicle.nextInspectionDate">Next Inspection Date</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('cardId')}>
                  <Translate contentKey="integrawebApp.vehicle.cardId">Card Id</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('emissionRate')}>
                  <Translate contentKey="integrawebApp.vehicle.emissionRate">Emission Rate</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {vehicleList.map((vehicle, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/vehicle/${vehicle.id}`} color="link" size="sm">
                      {vehicle.id}
                    </Button>
                  </td>
                  <td>{vehicle.vehicleName}</td>
                  <td>{vehicle.licensePlate}</td>
                  <td>{vehicle.vehicleType}</td>
                  <td>{vehicle.costCenter}</td>
                  <td>
                    {vehicle.registrationDate ? (
                      <TextFormat type="date" value={vehicle.registrationDate} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{vehicle.payloadTons}</td>
                  <td>{vehicle.loadingVolume}</td>
                  <td>{vehicle.fixedCosts}</td>
                  <td>{vehicle.variableCosts}</td>
                  <td>{vehicle.trailerSurcharge}</td>
                  <td>{vehicle.performanceKW}</td>
                  <td>{vehicle.trailerSurchargeMinute}</td>
                  <td>{vehicle.correctionFactor}</td>
                  <td>{vehicle.correctionSetupTime}</td>
                  <td>{vehicle.cargoAreaSM}</td>
                  <td>{vehicle.correctionSpecifiedTime}</td>
                  <td>{vehicle.maxPitches}</td>
                  <td>{vehicle.rearLoading}</td>
                  <td>{vehicle.freightRate}</td>
                  <td>{vehicle.recordStatus}</td>
                  <td>{vehicle.carrierId}</td>
                  <td>{vehicle.customerId}</td>
                  <td>{vehicle.supplierId}</td>
                  <td>{vehicle.vehicleCertification}</td>
                  <td>{vehicle.setId}</td>
                  <td>{vehicle.createdDate ? <TextFormat type="date" value={vehicle.createdDate} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{vehicle.createdBy}</td>
                  <td>
                    {vehicle.lastModifiedDate ? <TextFormat type="date" value={vehicle.lastModifiedDate} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td>{vehicle.lastModifiedBy}</td>
                  <td>{vehicle.noMDEdevice}</td>
                  <td>
                    {vehicle.lockedDateFrom ? <TextFormat type="date" value={vehicle.lockedDateFrom} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td>
                    {vehicle.lockedDateTill ? <TextFormat type="date" value={vehicle.lockedDateTill} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td>{vehicle.siteWages}</td>
                  <td>{vehicle.carTypeWages}</td>
                  <td>{vehicle.tareWeightTons}</td>
                  <td>{vehicle.tankFillingKG}</td>
                  <td>
                    {vehicle.nextTUVDate ? <TextFormat type="date" value={vehicle.nextTUVDate} format={APP_LOCAL_DATE_FORMAT} /> : null}
                  </td>
                  <td>
                    {vehicle.nextASUDate ? <TextFormat type="date" value={vehicle.nextASUDate} format={APP_LOCAL_DATE_FORMAT} /> : null}
                  </td>
                  <td>
                    {vehicle.certificationTill ? (
                      <TextFormat type="date" value={vehicle.certificationTill} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    {vehicle.leasingStart ? <TextFormat type="date" value={vehicle.leasingStart} format={APP_LOCAL_DATE_FORMAT} /> : null}
                  </td>
                  <td>
                    {vehicle.leasingEnd ? <TextFormat type="date" value={vehicle.leasingEnd} format={APP_LOCAL_DATE_FORMAT} /> : null}
                  </td>
                  <td>
                    {vehicle.nextInspectionDate ? (
                      <TextFormat type="date" value={vehicle.nextInspectionDate} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{vehicle.cardId}</td>
                  <td>{vehicle.emissionRate}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/vehicle/${vehicle.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/vehicle/${vehicle.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/vehicle/${vehicle.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="integrawebApp.vehicle.home.notFound">No Vehicles found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={vehicleList && vehicleList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Vehicle;
