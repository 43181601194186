import dayjs from 'dayjs';

export interface IForeignReceiptImport {
  id?: number;
  name?: string | null;
  createDate?: string | null;
  type?: number | null;
  processType?: string | null;
  branch?: number | null;
  warehouseId?: string | null;
  programmId?: string | null;
  release?: string | null;
  client?: number | null;
  inOut?: string | null;
  specialTreatment?: string | null;
  processingDate?: string | null;
  errorCount?: number | null;
  path?: string | null;
  fileName?: string | null;
  ltzteditdat?: string | null;
  ltzteditid?: string | null;
}

export const defaultValue: Readonly<IForeignReceiptImport> = {};
