import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import DeploymentAdministration from './deployment-administration';
import DeploymentAdministrationDetail from './deployment-administration-detail';
import DeploymentAdministrationUpdate from './deployment-administration-update';
import DeploymentAdministrationDeleteDialog from './deployment-administration-delete-dialog';

const DeploymentAdministrationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<DeploymentAdministration />} />
    <Route path="new" element={<DeploymentAdministrationUpdate />} />
    <Route path=":id">
      <Route index element={<DeploymentAdministrationDetail />} />
      <Route path="edit" element={<DeploymentAdministrationUpdate />} />
      <Route path="delete" element={<DeploymentAdministrationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DeploymentAdministrationRoutes;
