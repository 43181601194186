import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import FacilityWarehouse from './facility-warehouse';
import FacilityWarehouseDetail from './facility-warehouse-detail';
import FacilityWarehouseUpdate from './facility-warehouse-update';
import FacilityWarehouseDeleteDialog from './facility-warehouse-delete-dialog';

const FacilityWarehouseRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<FacilityWarehouse />} />
    <Route path="new" element={<FacilityWarehouseUpdate />} />
    <Route path=":id">
      <Route index element={<FacilityWarehouseDetail />} />
      <Route path="edit" element={<FacilityWarehouseUpdate />} />
      <Route path="delete" element={<FacilityWarehouseDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default FacilityWarehouseRoutes;
