import dayjs from 'dayjs';

export interface IForeignReceipt {
  id?: any;
  processType?: string | null;
  receiptKind?: number | null;
  receiptDate?: string | null;
  receiptNo?: string | null;
  customerId?: string | null;
  addressId?: number | null;
  contactPersonId?: number | null;
  diffInvoiceRecipient?: string | null;
  supplierId?: string | null;
  supplierAddress?: number | null;
  diffDebtor?: string | null;
  diffCreditor?: string | null;
  warehouseId?: string | null;
  proofType?: string | null;
  blocked?: string | null;
  barcode?: string | null;
  externalReceipt?: string | null;
  receivingWarehouse?: string | null;
  externalReceiptDate?: string | null;
  costCenter?: string | null;
  costCenterDischarge?: string | null;
  costUnit?: string | null;
  projectId?: string | null;
  glAccount?: string | null;
  paymentType?: number | null;
  priceGroup?: string | null;
  discountGroup?: string | null;
  deliveryCondition?: number | null;
  paymentCondition?: string | null;
  currency?: string | null;
  exchangeRate?: number | null;
  netrGross?: string | null;
  shippingMethod?: string | null;
  desiredDate?: string | null;
  deliveryTimeDays?: number | null;
  deliverDate?: string | null;
  creditOrDelivery?: string | null;
  hasShortageSurcharge?: string | null;
  tourId?: string | null;
  rideId?: string | null;
  externalNo?: string | null;
  externalDate?: string | null;
  partialDeliveryAllowed?: string | null;
  offerValidUntil?: string | null;
  completionRate?: number | null;
  dutyFree?: string | null;
  textId?: number | null;
  employeeId?: string | null;
  processingDate?: string | null;
  errorText?: string | null;
  receiptDid?: string | null;
  billingNo?: string | null;
  receiptDidSource?: string | null;
  keyWord?: string | null;
  valid?: string | null;
  bookingDate?: string | null;
  branch?: number | null;
  desiredTime?: string | null;
  deliveryTime?: string | null;
  referenceId?: string | null;
  countryCode?: string | null;
  bankCode?: string | null;
  bankAccount?: string | null;
  deliveryBy?: string | null;
  deliveryTo?: number | null;
  ltzteditdat?: string | null;
  ersteditdat?: string | null;
  deliveryOption?: string | null;
  vatId?: string | null;
  taxCountry?: string | null;
  transferNo?: number | null;
  transferNoAlt?: number | null;
  campaignId?: string | null;
  foreignCurrency?: string | null;
  foreignCurrencyRate?: number | null;
  customsReceiptId?: string | null;
}

export const defaultValue: Readonly<IForeignReceipt> = {};
