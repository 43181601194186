import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IForeignReceiptPosition } from 'app/shared/model/foreign-receipt-position.model';
import { getEntity, updateEntity, createEntity, reset } from './foreign-receipt-position.reducer';

export const ForeignReceiptPositionUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const foreignReceiptPositionEntity = useAppSelector(state => state.foreignReceiptPosition.entity);
  const loading = useAppSelector(state => state.foreignReceiptPosition.loading);
  const updating = useAppSelector(state => state.foreignReceiptPosition.updating);
  const updateSuccess = useAppSelector(state => state.foreignReceiptPosition.updateSuccess);

  const handleClose = () => {
    navigate('/foreign-receipt-position');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.deliveryTime = convertDateTimeToServer(values.deliveryTime);
    values.ersteditdat = convertDateTimeToServer(values.ersteditdat);
    values.ltzteditdat = convertDateTimeToServer(values.ltzteditdat);

    const entity = {
      ...foreignReceiptPositionEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          deliveryTime: displayDefaultDateTime(),
          ersteditdat: displayDefaultDateTime(),
          ltzteditdat: displayDefaultDateTime(),
        }
      : {
          ...foreignReceiptPositionEntity,
          deliveryTime: convertDateTimeFromServer(foreignReceiptPositionEntity.deliveryTime),
          ersteditdat: convertDateTimeFromServer(foreignReceiptPositionEntity.ersteditdat),
          ltzteditdat: convertDateTimeFromServer(foreignReceiptPositionEntity.ltzteditdat),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="integrawebApp.foreignReceiptPosition.home.createOrEditLabel" data-cy="ForeignReceiptPositionCreateUpdateHeading">
            <Translate contentKey="integrawebApp.foreignReceiptPosition.home.createOrEditLabel">
              Create or edit a ForeignReceiptPosition
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="foreign-receipt-position-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.positionType')}
                id="foreign-receipt-position-positionType"
                name="positionType"
                data-cy="positionType"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.articleId')}
                id="foreign-receipt-position-articleId"
                name="articleId"
                data-cy="articleId"
                type="text"
                validate={{
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.ean')}
                id="foreign-receipt-position-ean"
                name="ean"
                data-cy="ean"
                type="text"
                validate={{
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.externalPositionNo')}
                id="foreign-receipt-position-externalPositionNo"
                name="externalPositionNo"
                data-cy="externalPositionNo"
                type="text"
                validate={{
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.blockPrinting')}
                id="foreign-receipt-position-blockPrinting"
                name="blockPrinting"
                data-cy="blockPrinting"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.positionTreatment')}
                id="foreign-receipt-position-positionTreatment"
                name="positionTreatment"
                data-cy="positionTreatment"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.amount')}
                id="foreign-receipt-position-amount"
                name="amount"
                data-cy="amount"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.saleAmount')}
                id="foreign-receipt-position-saleAmount"
                name="saleAmount"
                data-cy="saleAmount"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.price')}
                id="foreign-receipt-position-price"
                name="price"
                data-cy="price"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.calcDiscount')}
                id="foreign-receipt-position-calcDiscount"
                name="calcDiscount"
                data-cy="calcDiscount"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.value')}
                id="foreign-receipt-position-value"
                name="value"
                data-cy="value"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.taxes')}
                id="foreign-receipt-position-taxes"
                name="taxes"
                data-cy="taxes"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.foreignCurrencyAmount')}
                id="foreign-receipt-position-foreignCurrencyAmount"
                name="foreignCurrencyAmount"
                data-cy="foreignCurrencyAmount"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.costOfSales')}
                id="foreign-receipt-position-costOfSales"
                name="costOfSales"
                data-cy="costOfSales"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.quantPackingUnit')}
                id="foreign-receipt-position-quantPackingUnit"
                name="quantPackingUnit"
                data-cy="quantPackingUnit"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.packageType')}
                id="foreign-receipt-position-packageType"
                name="packageType"
                data-cy="packageType"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.partialDeliveryAllowed')}
                id="foreign-receipt-position-partialDeliveryAllowed"
                name="partialDeliveryAllowed"
                data-cy="partialDeliveryAllowed"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.orderNo')}
                id="foreign-receipt-position-orderNo"
                name="orderNo"
                data-cy="orderNo"
                type="text"
                validate={{
                  maxLength: { value: 30, message: translate('entity.validation.maxlength', { max: 30 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.customerArticleId')}
                id="foreign-receipt-position-customerArticleId"
                name="customerArticleId"
                data-cy="customerArticleId"
                type="text"
                validate={{
                  maxLength: { value: 30, message: translate('entity.validation.maxlength', { max: 30 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.customerSalesPrice')}
                id="foreign-receipt-position-customerSalesPrice"
                name="customerSalesPrice"
                data-cy="customerSalesPrice"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.taxType')}
                id="foreign-receipt-position-taxType"
                name="taxType"
                data-cy="taxType"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.pricingType')}
                id="foreign-receipt-position-pricingType"
                name="pricingType"
                data-cy="pricingType"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.warehouseId')}
                id="foreign-receipt-position-warehouseId"
                name="warehouseId"
                data-cy="warehouseId"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.storageId')}
                id="foreign-receipt-position-storageId"
                name="storageId"
                data-cy="storageId"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.batchId')}
                id="foreign-receipt-position-batchId"
                name="batchId"
                data-cy="batchId"
                type="text"
                validate={{
                  maxLength: { value: 25, message: translate('entity.validation.maxlength', { max: 25 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.expireDate')}
                id="foreign-receipt-position-expireDate"
                name="expireDate"
                data-cy="expireDate"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.costUnit')}
                id="foreign-receipt-position-costUnit"
                name="costUnit"
                data-cy="costUnit"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.costCenter')}
                id="foreign-receipt-position-costCenter"
                name="costCenter"
                data-cy="costCenter"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.projectId')}
                id="foreign-receipt-position-projectId"
                name="projectId"
                data-cy="projectId"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.taxKey')}
                id="foreign-receipt-position-taxKey"
                name="taxKey"
                data-cy="taxKey"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.dutyFree')}
                id="foreign-receipt-position-dutyFree"
                name="dutyFree"
                data-cy="dutyFree"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.textId')}
                id="foreign-receipt-position-textId"
                name="textId"
                data-cy="textId"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.valid')}
                id="foreign-receipt-position-valid"
                name="valid"
                data-cy="valid"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.taxValue')}
                id="foreign-receipt-position-taxValue"
                name="taxValue"
                data-cy="taxValue"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.receiptPosDidSource')}
                id="foreign-receipt-position-receiptPosDidSource"
                name="receiptPosDidSource"
                data-cy="receiptPosDidSource"
                type="text"
                validate={{
                  maxLength: { value: 38, message: translate('entity.validation.maxlength', { max: 38 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.serviceDate')}
                id="foreign-receipt-position-serviceDate"
                name="serviceDate"
                data-cy="serviceDate"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.externalReceipt')}
                id="foreign-receipt-position-externalReceipt"
                name="externalReceipt"
                data-cy="externalReceipt"
                type="text"
                validate={{
                  maxLength: { value: 30, message: translate('entity.validation.maxlength', { max: 30 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.weight')}
                id="foreign-receipt-position-weight"
                name="weight"
                data-cy="weight"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.weightingWeight')}
                id="foreign-receipt-position-weightingWeight"
                name="weightingWeight"
                data-cy="weightingWeight"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.calculationWeight')}
                id="foreign-receipt-position-calculationWeight"
                name="calculationWeight"
                data-cy="calculationWeight"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.deliveryDate')}
                id="foreign-receipt-position-deliveryDate"
                name="deliveryDate"
                data-cy="deliveryDate"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.deliveryDateCW')}
                id="foreign-receipt-position-deliveryDateCW"
                name="deliveryDateCW"
                data-cy="deliveryDateCW"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.deliveryTime')}
                id="foreign-receipt-position-deliveryTime"
                name="deliveryTime"
                data-cy="deliveryTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.isFixedDate')}
                id="foreign-receipt-position-isFixedDate"
                name="isFixedDate"
                data-cy="isFixedDate"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.confirmedDate')}
                id="foreign-receipt-position-confirmedDate"
                name="confirmedDate"
                data-cy="confirmedDate"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.originalDate')}
                id="foreign-receipt-position-originalDate"
                name="originalDate"
                data-cy="originalDate"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.goodsConsumptionAcc')}
                id="foreign-receipt-position-goodsConsumptionAcc"
                name="goodsConsumptionAcc"
                data-cy="goodsConsumptionAcc"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.stockAcc')}
                id="foreign-receipt-position-stockAcc"
                name="stockAcc"
                data-cy="stockAcc"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.positionDid')}
                id="foreign-receipt-position-positionDid"
                name="positionDid"
                data-cy="positionDid"
                type="text"
                validate={{
                  maxLength: { value: 38, message: translate('entity.validation.maxlength', { max: 38 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.restCancel')}
                id="foreign-receipt-position-restCancel"
                name="restCancel"
                data-cy="restCancel"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.rollOutType')}
                id="foreign-receipt-position-rollOutType"
                name="rollOutType"
                data-cy="rollOutType"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.machineId')}
                id="foreign-receipt-position-machineId"
                name="machineId"
                data-cy="machineId"
                type="text"
                validate={{
                  maxLength: { value: 12, message: translate('entity.validation.maxlength', { max: 12 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.positionDidCreated')}
                id="foreign-receipt-position-positionDidCreated"
                name="positionDidCreated"
                data-cy="positionDidCreated"
                type="text"
                validate={{
                  maxLength: { value: 38, message: translate('entity.validation.maxlength', { max: 38 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.ersteditdat')}
                id="foreign-receipt-position-ersteditdat"
                name="ersteditdat"
                data-cy="ersteditdat"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.ltzteditdat')}
                id="foreign-receipt-position-ltzteditdat"
                name="ltzteditdat"
                data-cy="ltzteditdat"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.receiptControl')}
                id="foreign-receipt-position-receiptControl"
                name="receiptControl"
                data-cy="receiptControl"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.quantityUnit')}
                id="foreign-receipt-position-quantityUnit"
                name="quantityUnit"
                data-cy="quantityUnit"
                type="text"
                validate={{
                  maxLength: { value: 5, message: translate('entity.validation.maxlength', { max: 5 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.productionNo')}
                id="foreign-receipt-position-productionNo"
                name="productionNo"
                data-cy="productionNo"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.productionSeqNo')}
                id="foreign-receipt-position-productionSeqNo"
                name="productionSeqNo"
                data-cy="productionSeqNo"
                type="text"
                validate={{
                  maxLength: { value: 12, message: translate('entity.validation.maxlength', { max: 12 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.oldCallNo')}
                id="foreign-receipt-position-oldCallNo"
                name="oldCallNo"
                data-cy="oldCallNo"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.callNo')}
                id="foreign-receipt-position-callNo"
                name="callNo"
                data-cy="callNo"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.employeeId')}
                id="foreign-receipt-position-employeeId"
                name="employeeId"
                data-cy="employeeId"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.customerThirdPartyVendor')}
                id="foreign-receipt-position-customerThirdPartyVendor"
                name="customerThirdPartyVendor"
                data-cy="customerThirdPartyVendor"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.requestArticle')}
                id="foreign-receipt-position-requestArticle"
                name="requestArticle"
                data-cy="requestArticle"
                type="text"
                validate={{
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.customerReference')}
                id="foreign-receipt-position-customerReference"
                name="customerReference"
                data-cy="customerReference"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptPosition.internalQuoteId')}
                id="foreign-receipt-position-internalQuoteId"
                name="internalQuoteId"
                data-cy="internalQuoteId"
                type="text"
                validate={{
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/foreign-receipt-position" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ForeignReceiptPositionUpdate;
