import dayjs from 'dayjs';

export interface IDeploymentAdministration {
  id?: number;
  deliveryDate?: string | null;
  tourNr?: string | null;
  rideNr?: string | null;
  carrierNr?: string | null;
  departureTime?: string | null;
  deploymentNr?: string | null;
  warehouseId?: string | null;
  storageLocation?: string | null;
  comment?: string | null;
  statusComparison?: string | null;
  statusExternal?: string | null;
  dateExternal?: string | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
}

export const defaultValue: Readonly<IDeploymentAdministration> = {};
