import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/facility-warehouse">
        <Translate contentKey="global.menu.entities.facilityWarehouse" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/receipt">
        <Translate contentKey="global.menu.entities.receipt" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/artikeltexte-did">
        <Translate contentKey="global.menu.entities.artikeltexteDid" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/artikeltexte">
        <Translate contentKey="global.menu.entities.artikeltexte" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/replacement-item">
        <Translate contentKey="global.menu.entities.replacementItem" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/data-identifier-ssc">
        <Translate contentKey="global.menu.entities.dataIdentifierSsc" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/deployment">
        <Translate contentKey="global.menu.entities.deployment" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/deployment-administration">
        <Translate contentKey="global.menu.entities.deploymentAdministration" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/transport-order">
        <Translate contentKey="global.menu.entities.transportOrder" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/vehicle">
        <Translate contentKey="global.menu.entities.vehicle" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/foreign-receipt-import">
        <Translate contentKey="global.menu.entities.foreignReceiptImport" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/foreign-receipt-position">
        <Translate contentKey="global.menu.entities.foreignReceiptPosition" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/nve">
        <Translate contentKey="global.menu.entities.nve" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/nve-inhalt-wareneingang">
        <Translate contentKey="global.menu.entities.nveInhaltWareneingang" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/nve-inhalt-warenausgang">
        <Translate contentKey="global.menu.entities.nveInhaltWarenausgang" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/foreign-receipt">
        <Translate contentKey="global.menu.entities.foreignReceipt" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/company">
        <Translate contentKey="global.menu.entities.company" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/branch-master">
        <Translate contentKey="global.menu.entities.branchMaster" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
