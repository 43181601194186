import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ForeignReceiptImport from './foreign-receipt-import';
import ForeignReceiptImportDetail from './foreign-receipt-import-detail';
import ForeignReceiptImportUpdate from './foreign-receipt-import-update';
import ForeignReceiptImportDeleteDialog from './foreign-receipt-import-delete-dialog';

const ForeignReceiptImportRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ForeignReceiptImport />} />
    <Route path="new" element={<ForeignReceiptImportUpdate />} />
    <Route path=":id">
      <Route index element={<ForeignReceiptImportDetail />} />
      <Route path="edit" element={<ForeignReceiptImportUpdate />} />
      <Route path="delete" element={<ForeignReceiptImportDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ForeignReceiptImportRoutes;
