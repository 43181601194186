import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ITransportOrder } from 'app/shared/model/transport-order.model';
import { getEntity, updateEntity, createEntity, reset } from './transport-order.reducer';

export const TransportOrderUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const transportOrderEntity = useAppSelector(state => state.transportOrder.entity);
  const loading = useAppSelector(state => state.transportOrder.loading);
  const updating = useAppSelector(state => state.transportOrder.updating);
  const updateSuccess = useAppSelector(state => state.transportOrder.updateSuccess);

  const handleClose = () => {
    navigate('/transport-order' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.lastModifiedDate = convertDateTimeToServer(values.lastModifiedDate);

    const entity = {
      ...transportOrderEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdDate: displayDefaultDateTime(),
          lastModifiedDate: displayDefaultDateTime(),
        }
      : {
          ...transportOrderEntity,
          createdDate: convertDateTimeFromServer(transportOrderEntity.createdDate),
          lastModifiedDate: convertDateTimeFromServer(transportOrderEntity.lastModifiedDate),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="integrawebApp.transportOrder.home.createOrEditLabel" data-cy="TransportOrderCreateUpdateHeading">
            <Translate contentKey="integrawebApp.transportOrder.home.createOrEditLabel">Create or edit a TransportOrder</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="transport-order-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('integrawebApp.transportOrder.rideNr')}
                id="transport-order-rideNr"
                name="rideNr"
                data-cy="rideNr"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.tourOrder')}
                id="transport-order-tourOrder"
                name="tourOrder"
                data-cy="tourOrder"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.orderWeight')}
                id="transport-order-orderWeight"
                name="orderWeight"
                data-cy="orderWeight"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.orderVolume')}
                id="transport-order-orderVolume"
                name="orderVolume"
                data-cy="orderVolume"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.amountStat')}
                id="transport-order-amountStat"
                name="amountStat"
                data-cy="amountStat"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.serviceUnit')}
                id="transport-order-serviceUnit"
                name="serviceUnit"
                data-cy="serviceUnit"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.customersLoadingTime')}
                id="transport-order-customersLoadingTime"
                name="customersLoadingTime"
                data-cy="customersLoadingTime"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.customerCollectionTime')}
                id="transport-order-customerCollectionTime"
                name="customerCollectionTime"
                data-cy="customerCollectionTime"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.customerSetupTime')}
                id="transport-order-customerSetupTime"
                name="customerSetupTime"
                data-cy="customerSetupTime"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.vehicleType')}
                id="transport-order-vehicleType"
                name="vehicleType"
                data-cy="vehicleType"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.vehiclePos')}
                id="transport-order-vehiclePos"
                name="vehiclePos"
                data-cy="vehiclePos"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.carrierStatus')}
                id="transport-order-carrierStatus"
                name="carrierStatus"
                data-cy="carrierStatus"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.carriageRatePlanned')}
                id="transport-order-carriageRatePlanned"
                name="carriageRatePlanned"
                data-cy="carriageRatePlanned"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.carriageRateActual')}
                id="transport-order-carriageRateActual"
                name="carriageRateActual"
                data-cy="carriageRateActual"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.carriageFeePlanned')}
                id="transport-order-carriageFeePlanned"
                name="carriageFeePlanned"
                data-cy="carriageFeePlanned"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.carriageFeeActual')}
                id="transport-order-carriageFeeActual"
                name="carriageFeeActual"
                data-cy="carriageFeeActual"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.customerActualTime')}
                id="transport-order-customerActualTime"
                name="customerActualTime"
                data-cy="customerActualTime"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.amountTransportUnit')}
                id="transport-order-amountTransportUnit"
                name="amountTransportUnit"
                data-cy="amountTransportUnit"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.createdDate')}
                id="transport-order-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.createdBy')}
                id="transport-order-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
                validate={{
                  maxLength: { value: 4, message: translate('entity.validation.maxlength', { max: 4 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.lastModifiedDate')}
                id="transport-order-lastModifiedDate"
                name="lastModifiedDate"
                data-cy="lastModifiedDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.lastModifiedBy')}
                id="transport-order-lastModifiedBy"
                name="lastModifiedBy"
                data-cy="lastModifiedBy"
                type="text"
                validate={{
                  maxLength: { value: 4, message: translate('entity.validation.maxlength', { max: 4 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.amountBarrel')}
                id="transport-order-amountBarrel"
                name="amountBarrel"
                data-cy="amountBarrel"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.amountCrate')}
                id="transport-order-amountCrate"
                name="amountCrate"
                data-cy="amountCrate"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.amountBox')}
                id="transport-order-amountBox"
                name="amountBox"
                data-cy="amountBox"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.transportOrder.amountOthers')}
                id="transport-order-amountOthers"
                name="amountOthers"
                data-cy="amountOthers"
                type="text"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/transport-order" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TransportOrderUpdate;
