import dayjs from 'dayjs';

export interface ITransportOrder {
  id?: any;
  rideNr?: number | null;
  tourOrder?: number | null;
  orderWeight?: number | null;
  orderVolume?: number | null;
  amountStat?: number | null;
  serviceUnit?: number | null;
  customersLoadingTime?: number | null;
  customerCollectionTime?: number | null;
  customerSetupTime?: number | null;
  vehicleType?: string | null;
  vehiclePos?: string | null;
  carrierStatus?: string | null;
  carriageRatePlanned?: number | null;
  carriageRateActual?: number | null;
  carriageFeePlanned?: number | null;
  carriageFeeActual?: number | null;
  customerActualTime?: number | null;
  amountTransportUnit?: number | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  amountBarrel?: number | null;
  amountCrate?: number | null;
  amountBox?: number | null;
  amountOthers?: number | null;
}

export const defaultValue: Readonly<ITransportOrder> = {};
