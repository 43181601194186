import React, { useState, useEffect } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Row, Col, Form } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ICompany } from 'app/shared/model/company.model';
import { IBranchMaster } from 'app/shared/model/branch-master.model';

export interface ILoginModalProps {
  showModal: boolean;
  loginError: boolean;
  handleLogin: (username: string, password: string, companyId: string, branchId: string, rememberMe: boolean) => void;
  handleClose: () => void;
  companies: ReadonlyArray<ICompany>;
  branches: ReadonlyArray<IBranchMaster>;
  onChangeCompany: (event: React.ChangeEvent<HTMLInputElement>) => void;
  showCompanyAndBranch?: boolean;
}

const LoginModal = (props: ILoginModalProps) => {
  const login = ({ username, password, rememberMe }) => {
    props.handleLogin(username, password, company.toString(), branch.toString(), rememberMe);
  };

  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields },
  } = useForm({ mode: 'onTouched' });

  const { loginError, handleClose, companies, branches, onChangeCompany } = props;

  const [company, setCompany] = useState(0);
  const [branch, setBranch] = useState(0);

  useEffect(() => {
    if (branches.length !== 0) {
      setBranch(branches[0].id);
    }
  }, [branches]);

  useEffect(() => {
    if (companies.length !== 0) {
      setCompany(companies[0].id);
    }
  }, [companies]);

  const handleLoginSubmit = e => {
    handleSubmit(login)(e);
  };

  return (
    <Modal isOpen={props.showModal} toggle={handleClose} backdrop="static" id="login-page" autoFocus={false}>
      <Form onSubmit={handleLoginSubmit}>
        <ModalHeader id="login-title" data-cy="loginTitle" toggle={handleClose}>
          <Translate contentKey="login.title">Sign in</Translate>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12">
              {loginError ? (
                <Alert color="danger" data-cy="loginError">
                  <Translate contentKey="login.messages.error.authentication">
                    <strong>Failed to sign in!</strong> Please check your credentials and try again.
                  </Translate>
                </Alert>
              ) : null}
            </Col>
            <Col md="12">
              <ValidatedField
                name="username"
                label={translate('global.form.username.label')}
                placeholder={translate('global.form.username.placeholder')}
                required
                autoFocus
                data-cy="username"
                validate={{ required: 'Username cannot be empty!' }}
                register={register}
                error={errors.username}
                isTouched={touchedFields.username}
              />
              <ValidatedField
                name="password"
                type="password"
                label={translate('login.form.password')}
                placeholder={translate('login.form.password.placeholder')}
                required
                data-cy="password"
                validate={{ required: 'Password cannot be empty!' }}
                register={register}
                error={errors.password}
                isTouched={touchedFields.password}
              />
              {props.showCompanyAndBranch && (
                <>
                  <ValidatedField
                    name="companyId"
                    type="select"
                    label={translate('login.form.company')}
                    value={company}
                    onChange={e => {
                      setCompany(Number(e.target.value));
                      onChangeCompany(e);
                    }}
                  >
                    {companies.map(item => (
                      <option value={item.id} key={item.id}>
                        {item.id} - {item.description}
                      </option>
                    ))}
                  </ValidatedField>
                  <ValidatedField
                    name="branchId"
                    type="select"
                    label={translate('login.form.branch')}
                    value={branch}
                    onChange={e => setBranch(Number(e.target.value))}
                  >
                    {branches.map(item => (
                      <option value={item.id} key={item.id}>
                        {item.id} - {item.description}
                      </option>
                    ))}
                  </ValidatedField>

                  <ValidatedField
                    name="rememberMe"
                    type="checkbox"
                    check
                    label={translate('login.form.rememberme')}
                    value={true}
                    register={register}
                  />
                </>
              )}
            </Col>
          </Row>
          <div className="mt-1">&nbsp;</div>
          <Alert color="warning">
            <Link to="/account/reset/request" data-cy="forgetYourPasswordSelector">
              <Translate contentKey="login.password.forgot">Did you forget your password?</Translate>
            </Link>
          </Alert>
          <Alert color="warning">
            <span>
              <Translate contentKey="global.messages.info.register.noaccount">You don&apos;t have an account yet?</Translate>
            </span>{' '}
            <Link to="/account/register">
              <Translate contentKey="global.messages.info.register.link">Register a new account</Translate>
            </Link>
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleClose} tabIndex={1}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>{' '}
          <Button color="primary" type="submit" data-cy="submit">
            <Translate contentKey="login.form.button">Sign in</Translate>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default LoginModal;
