import dayjs from 'dayjs';

export interface IDeployment {
  id?: string;
  tripName?: string | null;
  recordStatus?: string | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  deliveryDateFrom?: string | null;
  deliveryDateTil?: string | null;
  nrbsFrom?: number | null;
  nrbsTil?: number | null;
}

export const defaultValue: Readonly<IDeployment> = {};
