import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IForeignReceiptImport } from 'app/shared/model/foreign-receipt-import.model';
import { getEntities, reset } from './foreign-receipt-import.reducer';

export const ForeignReceiptImport = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );
  const [sorting, setSorting] = useState(false);

  const foreignReceiptImportList = useAppSelector(state => state.foreignReceiptImport.entities);
  const loading = useAppSelector(state => state.foreignReceiptImport.loading);
  const totalItems = useAppSelector(state => state.foreignReceiptImport.totalItems);
  const links = useAppSelector(state => state.foreignReceiptImport.links);
  const entity = useAppSelector(state => state.foreignReceiptImport.entity);
  const updateSuccess = useAppSelector(state => state.foreignReceiptImport.updateSuccess);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const resetAll = () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  useEffect(() => {
    resetAll();
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      resetAll();
    }
  }, [updateSuccess]);

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage]);

  const handleLoadMore = () => {
    if ((window as any).pageYOffset > 0) {
      setPaginationState({
        ...paginationState,
        activePage: paginationState.activePage + 1,
      });
    }
  };

  useEffect(() => {
    if (sorting) {
      getAllEntities();
      setSorting(false);
    }
  }, [sorting]);

  const sort = p => () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
    setSorting(true);
  };

  const handleSyncList = () => {
    resetAll();
  };

  return (
    <div>
      <h2 id="foreign-receipt-import-heading" data-cy="ForeignReceiptImportHeading">
        <Translate contentKey="integrawebApp.foreignReceiptImport.home.title">Foreign Receipt Imports</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="integrawebApp.foreignReceiptImport.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link
            to="/foreign-receipt-import/new"
            className="btn btn-primary jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="integrawebApp.foreignReceiptImport.home.createLabel">Create new Foreign Receipt Import</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        <InfiniteScroll
          dataLength={foreignReceiptImportList ? foreignReceiptImportList.length : 0}
          next={handleLoadMore}
          hasMore={paginationState.activePage - 1 < links.next}
          loader={<div className="loader">Loading ...</div>}
        >
          {foreignReceiptImportList && foreignReceiptImportList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand" onClick={sort('id')}>
                    <Translate contentKey="integrawebApp.foreignReceiptImport.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('name')}>
                    <Translate contentKey="integrawebApp.foreignReceiptImport.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('createDate')}>
                    <Translate contentKey="integrawebApp.foreignReceiptImport.createDate">Create Date</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('type')}>
                    <Translate contentKey="integrawebApp.foreignReceiptImport.type">Type</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('processType')}>
                    <Translate contentKey="integrawebApp.foreignReceiptImport.processType">Process Type</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('branch')}>
                    <Translate contentKey="integrawebApp.foreignReceiptImport.branch">Branch</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('warehouseId')}>
                    <Translate contentKey="integrawebApp.foreignReceiptImport.warehouseId">Warehouse Id</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('programmId')}>
                    <Translate contentKey="integrawebApp.foreignReceiptImport.programmId">Programm Id</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('release')}>
                    <Translate contentKey="integrawebApp.foreignReceiptImport.release">Release</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('client')}>
                    <Translate contentKey="integrawebApp.foreignReceiptImport.client">Client</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('inOut')}>
                    <Translate contentKey="integrawebApp.foreignReceiptImport.inOut">In Out</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('specialTreatment')}>
                    <Translate contentKey="integrawebApp.foreignReceiptImport.specialTreatment">Special Treatment</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('processingDate')}>
                    <Translate contentKey="integrawebApp.foreignReceiptImport.processingDate">Processing Date</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('errorCount')}>
                    <Translate contentKey="integrawebApp.foreignReceiptImport.errorCount">Error Count</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('path')}>
                    <Translate contentKey="integrawebApp.foreignReceiptImport.path">Path</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('fileName')}>
                    <Translate contentKey="integrawebApp.foreignReceiptImport.fileName">File Name</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('ltzteditdat')}>
                    <Translate contentKey="integrawebApp.foreignReceiptImport.ltzteditdat">Ltzteditdat</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('ltzteditid')}>
                    <Translate contentKey="integrawebApp.foreignReceiptImport.ltzteditid">Ltzteditid</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {foreignReceiptImportList.map((foreignReceiptImport, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>
                      <Button tag={Link} to={`/foreign-receipt-import/${foreignReceiptImport.id}`} color="link" size="sm">
                        {foreignReceiptImport.id}
                      </Button>
                    </td>
                    <td>{foreignReceiptImport.name}</td>
                    <td>
                      {foreignReceiptImport.createDate ? (
                        <TextFormat type="date" value={foreignReceiptImport.createDate} format={APP_LOCAL_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>{foreignReceiptImport.type}</td>
                    <td>{foreignReceiptImport.processType}</td>
                    <td>{foreignReceiptImport.branch}</td>
                    <td>{foreignReceiptImport.warehouseId}</td>
                    <td>{foreignReceiptImport.programmId}</td>
                    <td>{foreignReceiptImport.release}</td>
                    <td>{foreignReceiptImport.client}</td>
                    <td>{foreignReceiptImport.inOut}</td>
                    <td>{foreignReceiptImport.specialTreatment}</td>
                    <td>
                      {foreignReceiptImport.processingDate ? (
                        <TextFormat type="date" value={foreignReceiptImport.processingDate} format={APP_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>{foreignReceiptImport.errorCount}</td>
                    <td>{foreignReceiptImport.path}</td>
                    <td>{foreignReceiptImport.fileName}</td>
                    <td>
                      {foreignReceiptImport.ltzteditdat ? (
                        <TextFormat type="date" value={foreignReceiptImport.ltzteditdat} format={APP_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>{foreignReceiptImport.ltzteditid}</td>
                    <td className="text-end">
                      <div className="btn-group flex-btn-group-container">
                        <Button
                          tag={Link}
                          to={`/foreign-receipt-import/${foreignReceiptImport.id}`}
                          color="info"
                          size="sm"
                          data-cy="entityDetailsButton"
                        >
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button
                          tag={Link}
                          to={`/foreign-receipt-import/${foreignReceiptImport.id}/edit`}
                          color="primary"
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button
                          tag={Link}
                          to={`/foreign-receipt-import/${foreignReceiptImport.id}/delete`}
                          color="danger"
                          size="sm"
                          data-cy="entityDeleteButton"
                        >
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="integrawebApp.foreignReceiptImport.home.notFound">No Foreign Receipt Imports found</Translate>
              </div>
            )
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default ForeignReceiptImport;
