import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './deployment-administration.reducer';

export const DeploymentAdministrationDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const deploymentAdministrationEntity = useAppSelector(state => state.deploymentAdministration.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="deploymentAdministrationDetailsHeading">
          <Translate contentKey="integrawebApp.deploymentAdministration.detail.title">DeploymentAdministration</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{deploymentAdministrationEntity.id}</dd>
          <dt>
            <span id="deliveryDate">
              <Translate contentKey="integrawebApp.deploymentAdministration.deliveryDate">Delivery Date</Translate>
            </span>
          </dt>
          <dd>
            {deploymentAdministrationEntity.deliveryDate ? (
              <TextFormat value={deploymentAdministrationEntity.deliveryDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="tourNr">
              <Translate contentKey="integrawebApp.deploymentAdministration.tourNr">Tour Nr</Translate>
            </span>
          </dt>
          <dd>{deploymentAdministrationEntity.tourNr}</dd>
          <dt>
            <span id="rideNr">
              <Translate contentKey="integrawebApp.deploymentAdministration.rideNr">Ride Nr</Translate>
            </span>
          </dt>
          <dd>{deploymentAdministrationEntity.rideNr}</dd>
          <dt>
            <span id="carrierNr">
              <Translate contentKey="integrawebApp.deploymentAdministration.carrierNr">Carrier Nr</Translate>
            </span>
          </dt>
          <dd>{deploymentAdministrationEntity.carrierNr}</dd>
          <dt>
            <span id="departureTime">
              <Translate contentKey="integrawebApp.deploymentAdministration.departureTime">Departure Time</Translate>
            </span>
          </dt>
          <dd>
            {deploymentAdministrationEntity.departureTime ? (
              <TextFormat value={deploymentAdministrationEntity.departureTime} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="deploymentNr">
              <Translate contentKey="integrawebApp.deploymentAdministration.deploymentNr">Deployment Nr</Translate>
            </span>
          </dt>
          <dd>{deploymentAdministrationEntity.deploymentNr}</dd>
          <dt>
            <span id="warehouseId">
              <Translate contentKey="integrawebApp.deploymentAdministration.warehouseId">Warehouse Id</Translate>
            </span>
          </dt>
          <dd>{deploymentAdministrationEntity.warehouseId}</dd>
          <dt>
            <span id="storageLocation">
              <Translate contentKey="integrawebApp.deploymentAdministration.storageLocation">Storage Location</Translate>
            </span>
          </dt>
          <dd>{deploymentAdministrationEntity.storageLocation}</dd>
          <dt>
            <span id="comment">
              <Translate contentKey="integrawebApp.deploymentAdministration.comment">Comment</Translate>
            </span>
          </dt>
          <dd>{deploymentAdministrationEntity.comment}</dd>
          <dt>
            <span id="statusComparison">
              <Translate contentKey="integrawebApp.deploymentAdministration.statusComparison">Status Comparison</Translate>
            </span>
          </dt>
          <dd>{deploymentAdministrationEntity.statusComparison}</dd>
          <dt>
            <span id="statusExternal">
              <Translate contentKey="integrawebApp.deploymentAdministration.statusExternal">Status External</Translate>
            </span>
          </dt>
          <dd>{deploymentAdministrationEntity.statusExternal}</dd>
          <dt>
            <span id="dateExternal">
              <Translate contentKey="integrawebApp.deploymentAdministration.dateExternal">Date External</Translate>
            </span>
          </dt>
          <dd>
            {deploymentAdministrationEntity.dateExternal ? (
              <TextFormat value={deploymentAdministrationEntity.dateExternal} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="integrawebApp.deploymentAdministration.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {deploymentAdministrationEntity.createdDate ? (
              <TextFormat value={deploymentAdministrationEntity.createdDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="integrawebApp.deploymentAdministration.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{deploymentAdministrationEntity.createdBy}</dd>
          <dt>
            <span id="lastModifiedDate">
              <Translate contentKey="integrawebApp.deploymentAdministration.lastModifiedDate">Last Modified Date</Translate>
            </span>
          </dt>
          <dd>
            {deploymentAdministrationEntity.lastModifiedDate ? (
              <TextFormat value={deploymentAdministrationEntity.lastModifiedDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="lastModifiedBy">
              <Translate contentKey="integrawebApp.deploymentAdministration.lastModifiedBy">Last Modified By</Translate>
            </span>
          </dt>
          <dd>{deploymentAdministrationEntity.lastModifiedBy}</dd>
        </dl>
        <Button tag={Link} to="/deployment-administration" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/deployment-administration/${deploymentAdministrationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default DeploymentAdministrationDetail;
