import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IReceipt } from 'app/shared/model/receipt.model';
import { getEntity, updateEntity, createEntity, reset } from './receipt.reducer';

export const ReceiptUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const receiptEntity = useAppSelector(state => state.receipt.entity);
  const loading = useAppSelector(state => state.receipt.loading);
  const updating = useAppSelector(state => state.receipt.updating);
  const updateSuccess = useAppSelector(state => state.receipt.updateSuccess);

  const handleClose = () => {
    navigate('/receipt');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.receiptDate = convertDateTimeToServer(values.receiptDate);
    values.printDate = convertDateTimeToServer(values.printDate);
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.lastModifiedDate = convertDateTimeToServer(values.lastModifiedDate);

    const entity = {
      ...receiptEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          receiptDate: displayDefaultDateTime(),
          printDate: displayDefaultDateTime(),
          createdDate: displayDefaultDateTime(),
          lastModifiedDate: displayDefaultDateTime(),
        }
      : {
          ...receiptEntity,
          receiptDate: convertDateTimeFromServer(receiptEntity.receiptDate),
          printDate: convertDateTimeFromServer(receiptEntity.printDate),
          createdDate: convertDateTimeFromServer(receiptEntity.createdDate),
          lastModifiedDate: convertDateTimeFromServer(receiptEntity.lastModifiedDate),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="integrawebApp.receipt.home.createOrEditLabel" data-cy="ReceiptCreateUpdateHeading">
            <Translate contentKey="integrawebApp.receipt.home.createOrEditLabel">Create or edit a Receipt</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="receipt-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('integrawebApp.receipt.receiptHeadId')}
                id="receipt-receiptHeadId"
                name="receiptHeadId"
                data-cy="receiptHeadId"
                type="text"
                validate={{
                  maxLength: { value: 38, message: translate('entity.validation.maxlength', { max: 38 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.receiptTypeNr')}
                id="receipt-receiptTypeNr"
                name="receiptTypeNr"
                data-cy="receiptTypeNr"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.detectionType')}
                id="receipt-detectionType"
                name="detectionType"
                data-cy="detectionType"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.receiptNr')}
                id="receipt-receiptNr"
                name="receiptNr"
                data-cy="receiptNr"
                type="text"
                validate={{
                  maxLength: { value: 12, message: translate('entity.validation.maxlength', { max: 12 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.customerId')}
                id="receipt-customerId"
                name="customerId"
                data-cy="customerId"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.supplierId')}
                id="receipt-supplierId"
                name="supplierId"
                data-cy="supplierId"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.receiptDate')}
                id="receipt-receiptDate"
                name="receiptDate"
                data-cy="receiptDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.printDate')}
                id="receipt-printDate"
                name="printDate"
                data-cy="printDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.printedBy')}
                id="receipt-printedBy"
                name="printedBy"
                data-cy="printedBy"
                type="text"
                validate={{
                  maxLength: { value: 4, message: translate('entity.validation.maxlength', { max: 4 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.logNr')}
                id="receipt-logNr"
                name="logNr"
                data-cy="logNr"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.logPos')}
                id="receipt-logPos"
                name="logPos"
                data-cy="logPos"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.receiptArtNr')}
                id="receipt-receiptArtNr"
                name="receiptArtNr"
                data-cy="receiptArtNr"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.valid')}
                id="receipt-valid"
                name="valid"
                data-cy="valid"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.validTill')}
                id="receipt-validTill"
                name="validTill"
                data-cy="validTill"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.locked')}
                id="receipt-locked"
                name="locked"
                data-cy="locked"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.bookingDate')}
                id="receipt-bookingDate"
                name="bookingDate"
                data-cy="bookingDate"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.operationId')}
                id="receipt-operationId"
                name="operationId"
                data-cy="operationId"
                type="text"
                validate={{
                  maxLength: { value: 38, message: translate('entity.validation.maxlength', { max: 38 }) },
                }}
              />
              <ValidatedField label={translate('integrawebApp.receipt.nrbs')} id="receipt-nrbs" name="nrbs" data-cy="nrbs" type="text" />
              <ValidatedField
                label={translate('integrawebApp.receipt.currentversion')}
                id="receipt-currentversion"
                name="currentversion"
                data-cy="currentversion"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.versionprinted')}
                id="receipt-versionprinted"
                name="versionprinted"
                data-cy="versionprinted"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.headChanges')}
                id="receipt-headChanges"
                name="headChanges"
                data-cy="headChanges"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.posChanges')}
                id="receipt-posChanges"
                name="posChanges"
                data-cy="posChanges"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.foreignReceiptNr')}
                id="receipt-foreignReceiptNr"
                name="foreignReceiptNr"
                data-cy="foreignReceiptNr"
                type="text"
                validate={{
                  maxLength: { value: 30, message: translate('entity.validation.maxlength', { max: 30 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.foreignReceiptDate')}
                id="receipt-foreignReceiptDate"
                name="foreignReceiptDate"
                data-cy="foreignReceiptDate"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.barcodenr')}
                id="receipt-barcodenr"
                name="barcodenr"
                data-cy="barcodenr"
                type="text"
                validate={{
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.shippingWeight')}
                id="receipt-shippingWeight"
                name="shippingWeight"
                data-cy="shippingWeight"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.packageNumbers')}
                id="receipt-packageNumbers"
                name="packageNumbers"
                data-cy="packageNumbers"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.archived')}
                id="receipt-archived"
                name="archived"
                data-cy="archived"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.storageArea')}
                id="receipt-storageArea"
                name="storageArea"
                data-cy="storageArea"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.order')}
                id="receipt-order"
                name="order"
                data-cy="order"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.receiptControlMan')}
                id="receipt-receiptControlMan"
                name="receiptControlMan"
                data-cy="receiptControlMan"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.finished')}
                id="receipt-finished"
                name="finished"
                data-cy="finished"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.statusWeighing')}
                id="receipt-statusWeighing"
                name="statusWeighing"
                data-cy="statusWeighing"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.fullSerialNumber')}
                id="receipt-fullSerialNumber"
                name="fullSerialNumber"
                data-cy="fullSerialNumber"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.storageFullyBooked')}
                id="receipt-storageFullyBooked"
                name="storageFullyBooked"
                data-cy="storageFullyBooked"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.autoPosLocked')}
                id="receipt-autoPosLocked"
                name="autoPosLocked"
                data-cy="autoPosLocked"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.transNr')}
                id="receipt-transNr"
                name="transNr"
                data-cy="transNr"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.transNrAlt')}
                id="receipt-transNrAlt"
                name="transNrAlt"
                data-cy="transNrAlt"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.retrievalNr')}
                id="receipt-retrievalNr"
                name="retrievalNr"
                data-cy="retrievalNr"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.retrievalNrAlt')}
                id="receipt-retrievalNrAlt"
                name="retrievalNrAlt"
                data-cy="retrievalNrAlt"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.billingBlock')}
                id="receipt-billingBlock"
                name="billingBlock"
                data-cy="billingBlock"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.createdDate')}
                id="receipt-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.createdBy')}
                id="receipt-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
                validate={{
                  maxLength: { value: 4, message: translate('entity.validation.maxlength', { max: 4 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.lastModifiedDate')}
                id="receipt-lastModifiedDate"
                name="lastModifiedDate"
                data-cy="lastModifiedDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.receipt.lastModifiedBy')}
                id="receipt-lastModifiedBy"
                name="lastModifiedBy"
                data-cy="lastModifiedBy"
                type="text"
                validate={{
                  maxLength: { value: 4, message: translate('entity.validation.maxlength', { max: 4 }) },
                }}
              />
              <ValidatedField label={translate('integrawebApp.receipt.nrwe')} id="receipt-nrwe" name="nrwe" data-cy="nrwe" type="text" />
              <ValidatedField
                label={translate('integrawebApp.receipt.kassennr')}
                id="receipt-kassennr"
                name="kassennr"
                data-cy="kassennr"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/receipt" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ReceiptUpdate;
