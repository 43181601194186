import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import NVEInhaltWarenausgang from './nve-inhalt-warenausgang';
import NVEInhaltWarenausgangDetail from './nve-inhalt-warenausgang-detail';
import NVEInhaltWarenausgangUpdate from './nve-inhalt-warenausgang-update';
import NVEInhaltWarenausgangDeleteDialog from './nve-inhalt-warenausgang-delete-dialog';

const NVEInhaltWarenausgangRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<NVEInhaltWarenausgang />} />
    <Route path="new" element={<NVEInhaltWarenausgangUpdate />} />
    <Route path=":id">
      <Route index element={<NVEInhaltWarenausgangDetail />} />
      <Route path="edit" element={<NVEInhaltWarenausgangUpdate />} />
      <Route path="delete" element={<NVEInhaltWarenausgangDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default NVEInhaltWarenausgangRoutes;
