import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './foreign-receipt-import.reducer';

export const ForeignReceiptImportDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const foreignReceiptImportEntity = useAppSelector(state => state.foreignReceiptImport.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="foreignReceiptImportDetailsHeading">
          <Translate contentKey="integrawebApp.foreignReceiptImport.detail.title">ForeignReceiptImport</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptImportEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="integrawebApp.foreignReceiptImport.name">Name</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptImportEntity.name}</dd>
          <dt>
            <span id="createDate">
              <Translate contentKey="integrawebApp.foreignReceiptImport.createDate">Create Date</Translate>
            </span>
          </dt>
          <dd>
            {foreignReceiptImportEntity.createDate ? (
              <TextFormat value={foreignReceiptImportEntity.createDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="type">
              <Translate contentKey="integrawebApp.foreignReceiptImport.type">Type</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptImportEntity.type}</dd>
          <dt>
            <span id="processType">
              <Translate contentKey="integrawebApp.foreignReceiptImport.processType">Process Type</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptImportEntity.processType}</dd>
          <dt>
            <span id="branch">
              <Translate contentKey="integrawebApp.foreignReceiptImport.branch">Branch</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptImportEntity.branch}</dd>
          <dt>
            <span id="warehouseId">
              <Translate contentKey="integrawebApp.foreignReceiptImport.warehouseId">Warehouse Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptImportEntity.warehouseId}</dd>
          <dt>
            <span id="programmId">
              <Translate contentKey="integrawebApp.foreignReceiptImport.programmId">Programm Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptImportEntity.programmId}</dd>
          <dt>
            <span id="release">
              <Translate contentKey="integrawebApp.foreignReceiptImport.release">Release</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptImportEntity.release}</dd>
          <dt>
            <span id="client">
              <Translate contentKey="integrawebApp.foreignReceiptImport.client">Client</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptImportEntity.client}</dd>
          <dt>
            <span id="inOut">
              <Translate contentKey="integrawebApp.foreignReceiptImport.inOut">In Out</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptImportEntity.inOut}</dd>
          <dt>
            <span id="specialTreatment">
              <Translate contentKey="integrawebApp.foreignReceiptImport.specialTreatment">Special Treatment</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptImportEntity.specialTreatment}</dd>
          <dt>
            <span id="processingDate">
              <Translate contentKey="integrawebApp.foreignReceiptImport.processingDate">Processing Date</Translate>
            </span>
          </dt>
          <dd>
            {foreignReceiptImportEntity.processingDate ? (
              <TextFormat value={foreignReceiptImportEntity.processingDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="errorCount">
              <Translate contentKey="integrawebApp.foreignReceiptImport.errorCount">Error Count</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptImportEntity.errorCount}</dd>
          <dt>
            <span id="path">
              <Translate contentKey="integrawebApp.foreignReceiptImport.path">Path</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptImportEntity.path}</dd>
          <dt>
            <span id="fileName">
              <Translate contentKey="integrawebApp.foreignReceiptImport.fileName">File Name</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptImportEntity.fileName}</dd>
          <dt>
            <span id="ltzteditdat">
              <Translate contentKey="integrawebApp.foreignReceiptImport.ltzteditdat">Ltzteditdat</Translate>
            </span>
          </dt>
          <dd>
            {foreignReceiptImportEntity.ltzteditdat ? (
              <TextFormat value={foreignReceiptImportEntity.ltzteditdat} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="ltzteditid">
              <Translate contentKey="integrawebApp.foreignReceiptImport.ltzteditid">Ltzteditid</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptImportEntity.ltzteditid}</dd>
        </dl>
        <Button tag={Link} to="/foreign-receipt-import" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/foreign-receipt-import/${foreignReceiptImportEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ForeignReceiptImportDetail;
