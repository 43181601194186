import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import NVE from './nve';
import NVEDetail from './nve-detail';
import NVEUpdate from './nve-update';
import NVEDeleteDialog from './nve-delete-dialog';

const NVERoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<NVE />} />
    <Route path="new" element={<NVEUpdate />} />
    <Route path=":id">
      <Route index element={<NVEDetail />} />
      <Route path="edit" element={<NVEUpdate />} />
      <Route path="delete" element={<NVEDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default NVERoutes;
