import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { INVEInhaltWarenausgang } from 'app/shared/model/nve-inhalt-warenausgang.model';
import { getEntity, updateEntity, createEntity, reset } from './nve-inhalt-warenausgang.reducer';

export const NVEInhaltWarenausgangUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const nVEInhaltWarenausgangEntity = useAppSelector(state => state.nVEInhaltWarenausgang.entity);
  const loading = useAppSelector(state => state.nVEInhaltWarenausgang.loading);
  const updating = useAppSelector(state => state.nVEInhaltWarenausgang.updating);
  const updateSuccess = useAppSelector(state => state.nVEInhaltWarenausgang.updateSuccess);

  const handleClose = () => {
    navigate('/nve-inhalt-warenausgang');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.controlDate = convertDateTimeToServer(values.controlDate);
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.lastModifiedDate = convertDateTimeToServer(values.lastModifiedDate);

    const entity = {
      ...nVEInhaltWarenausgangEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          controlDate: displayDefaultDateTime(),
          createdDate: displayDefaultDateTime(),
          lastModifiedDate: displayDefaultDateTime(),
        }
      : {
          ...nVEInhaltWarenausgangEntity,
          controlDate: convertDateTimeFromServer(nVEInhaltWarenausgangEntity.controlDate),
          createdDate: convertDateTimeFromServer(nVEInhaltWarenausgangEntity.createdDate),
          lastModifiedDate: convertDateTimeFromServer(nVEInhaltWarenausgangEntity.lastModifiedDate),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="integrawebApp.nVEInhaltWarenausgang.home.createOrEditLabel" data-cy="NVEInhaltWarenausgangCreateUpdateHeading">
            <Translate contentKey="integrawebApp.nVEInhaltWarenausgang.home.createOrEditLabel">
              Create or edit a NVEInhaltWarenausgang
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="nve-inhalt-warenausgang-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWarenausgang.quantityId')}
                id="nve-inhalt-warenausgang-quantityId"
                name="quantityId"
                data-cy="quantityId"
                type="text"
                validate={{
                  maxLength: { value: 38, message: translate('entity.validation.maxlength', { max: 38 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWarenausgang.bookIdNr')}
                id="nve-inhalt-warenausgang-bookIdNr"
                name="bookIdNr"
                data-cy="bookIdNr"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWarenausgang.weight')}
                id="nve-inhalt-warenausgang-weight"
                name="weight"
                data-cy="weight"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWarenausgang.employeeId')}
                id="nve-inhalt-warenausgang-employeeId"
                name="employeeId"
                data-cy="employeeId"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWarenausgang.quantity')}
                id="nve-inhalt-warenausgang-quantity"
                name="quantity"
                data-cy="quantity"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWarenausgang.employeeontrolId')}
                id="nve-inhalt-warenausgang-employeeontrolId"
                name="employeeontrolId"
                data-cy="employeeontrolId"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWarenausgang.quantityControl')}
                id="nve-inhalt-warenausgang-quantityControl"
                name="quantityControl"
                data-cy="quantityControl"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWarenausgang.controlDate')}
                id="nve-inhalt-warenausgang-controlDate"
                name="controlDate"
                data-cy="controlDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWarenausgang.createdDate')}
                id="nve-inhalt-warenausgang-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWarenausgang.createdBy')}
                id="nve-inhalt-warenausgang-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
                validate={{
                  maxLength: { value: 4, message: translate('entity.validation.maxlength', { max: 4 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWarenausgang.lastModifiedDate')}
                id="nve-inhalt-warenausgang-lastModifiedDate"
                name="lastModifiedDate"
                data-cy="lastModifiedDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWarenausgang.lastModifiedBy')}
                id="nve-inhalt-warenausgang-lastModifiedBy"
                name="lastModifiedBy"
                data-cy="lastModifiedBy"
                type="text"
                validate={{
                  maxLength: { value: 4, message: translate('entity.validation.maxlength', { max: 4 }) },
                }}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/nve-inhalt-warenausgang" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default NVEInhaltWarenausgangUpdate;
