import dayjs from 'dayjs';

export interface INVE {
  id?: string;
  transportUnit?: string | null;
  nveNr?: number | null;
  nveOwn?: string | null;
  nveSupplier?: string | null;
  supplierId?: string | null;
  supplierAddress?: number | null;
  weight?: number | null;
  timeFrom?: string | null;
  timeEnd?: string | null;
  status?: string | null;
  note?: string | null;
  receiptDid?: string | null;
  loadingNr?: number | null;
  lockNr?: number | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  labelingDate?: string | null;
  labelingTime?: string | null;
  idleTime?: string | null;
  quantity?: number | null;
  packageType?: number | null;
  quantityPackingUnit?: number | null;
  positionNve?: string | null;
  palletType?: string | null;
  storageId?: string | null;
  warehouseId?: string | null;
  timeMinute?: number | null;
  dutyReferenceNr?: string | null;
  mixedPallet?: string | null;
}

export const defaultValue: Readonly<INVE> = {};
