import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './vehicle.reducer';

export const VehicleDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const vehicleEntity = useAppSelector(state => state.vehicle.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="vehicleDetailsHeading">
          <Translate contentKey="integrawebApp.vehicle.detail.title">Vehicle</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.id}</dd>
          <dt>
            <span id="vehicleName">
              <Translate contentKey="integrawebApp.vehicle.vehicleName">Vehicle Name</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.vehicleName}</dd>
          <dt>
            <span id="licensePlate">
              <Translate contentKey="integrawebApp.vehicle.licensePlate">License Plate</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.licensePlate}</dd>
          <dt>
            <span id="vehicleType">
              <Translate contentKey="integrawebApp.vehicle.vehicleType">Vehicle Type</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.vehicleType}</dd>
          <dt>
            <span id="costCenter">
              <Translate contentKey="integrawebApp.vehicle.costCenter">Cost Center</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.costCenter}</dd>
          <dt>
            <span id="registrationDate">
              <Translate contentKey="integrawebApp.vehicle.registrationDate">Registration Date</Translate>
            </span>
          </dt>
          <dd>
            {vehicleEntity.registrationDate ? (
              <TextFormat value={vehicleEntity.registrationDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="payloadTons">
              <Translate contentKey="integrawebApp.vehicle.payloadTons">Payload Tons</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.payloadTons}</dd>
          <dt>
            <span id="loadingVolume">
              <Translate contentKey="integrawebApp.vehicle.loadingVolume">Loading Volume</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.loadingVolume}</dd>
          <dt>
            <span id="fixedCosts">
              <Translate contentKey="integrawebApp.vehicle.fixedCosts">Fixed Costs</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.fixedCosts}</dd>
          <dt>
            <span id="variableCosts">
              <Translate contentKey="integrawebApp.vehicle.variableCosts">Variable Costs</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.variableCosts}</dd>
          <dt>
            <span id="trailerSurcharge">
              <Translate contentKey="integrawebApp.vehicle.trailerSurcharge">Trailer Surcharge</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.trailerSurcharge}</dd>
          <dt>
            <span id="performanceKW">
              <Translate contentKey="integrawebApp.vehicle.performanceKW">Performance KW</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.performanceKW}</dd>
          <dt>
            <span id="trailerSurchargeMinute">
              <Translate contentKey="integrawebApp.vehicle.trailerSurchargeMinute">Trailer Surcharge Minute</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.trailerSurchargeMinute}</dd>
          <dt>
            <span id="correctionFactor">
              <Translate contentKey="integrawebApp.vehicle.correctionFactor">Correction Factor</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.correctionFactor}</dd>
          <dt>
            <span id="correctionSetupTime">
              <Translate contentKey="integrawebApp.vehicle.correctionSetupTime">Correction Setup Time</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.correctionSetupTime}</dd>
          <dt>
            <span id="cargoAreaSM">
              <Translate contentKey="integrawebApp.vehicle.cargoAreaSM">Cargo Area SM</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.cargoAreaSM}</dd>
          <dt>
            <span id="correctionSpecifiedTime">
              <Translate contentKey="integrawebApp.vehicle.correctionSpecifiedTime">Correction Specified Time</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.correctionSpecifiedTime}</dd>
          <dt>
            <span id="maxPitches">
              <Translate contentKey="integrawebApp.vehicle.maxPitches">Max Pitches</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.maxPitches}</dd>
          <dt>
            <span id="rearLoading">
              <Translate contentKey="integrawebApp.vehicle.rearLoading">Rear Loading</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.rearLoading}</dd>
          <dt>
            <span id="freightRate">
              <Translate contentKey="integrawebApp.vehicle.freightRate">Freight Rate</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.freightRate}</dd>
          <dt>
            <span id="recordStatus">
              <Translate contentKey="integrawebApp.vehicle.recordStatus">Record Status</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.recordStatus}</dd>
          <dt>
            <span id="carrierId">
              <Translate contentKey="integrawebApp.vehicle.carrierId">Carrier Id</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.carrierId}</dd>
          <dt>
            <span id="customerId">
              <Translate contentKey="integrawebApp.vehicle.customerId">Customer Id</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.customerId}</dd>
          <dt>
            <span id="supplierId">
              <Translate contentKey="integrawebApp.vehicle.supplierId">Supplier Id</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.supplierId}</dd>
          <dt>
            <span id="vehicleCertification">
              <Translate contentKey="integrawebApp.vehicle.vehicleCertification">Vehicle Certification</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.vehicleCertification}</dd>
          <dt>
            <span id="setId">
              <Translate contentKey="integrawebApp.vehicle.setId">Set Id</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.setId}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="integrawebApp.vehicle.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {vehicleEntity.createdDate ? <TextFormat value={vehicleEntity.createdDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="integrawebApp.vehicle.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.createdBy}</dd>
          <dt>
            <span id="lastModifiedDate">
              <Translate contentKey="integrawebApp.vehicle.lastModifiedDate">Last Modified Date</Translate>
            </span>
          </dt>
          <dd>
            {vehicleEntity.lastModifiedDate ? (
              <TextFormat value={vehicleEntity.lastModifiedDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="lastModifiedBy">
              <Translate contentKey="integrawebApp.vehicle.lastModifiedBy">Last Modified By</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.lastModifiedBy}</dd>
          <dt>
            <span id="noMDEdevice">
              <Translate contentKey="integrawebApp.vehicle.noMDEdevice">No MD Edevice</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.noMDEdevice}</dd>
          <dt>
            <span id="lockedDateFrom">
              <Translate contentKey="integrawebApp.vehicle.lockedDateFrom">Locked Date From</Translate>
            </span>
          </dt>
          <dd>
            {vehicleEntity.lockedDateFrom ? <TextFormat value={vehicleEntity.lockedDateFrom} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="lockedDateTill">
              <Translate contentKey="integrawebApp.vehicle.lockedDateTill">Locked Date Till</Translate>
            </span>
          </dt>
          <dd>
            {vehicleEntity.lockedDateTill ? <TextFormat value={vehicleEntity.lockedDateTill} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="siteWages">
              <Translate contentKey="integrawebApp.vehicle.siteWages">Site Wages</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.siteWages}</dd>
          <dt>
            <span id="carTypeWages">
              <Translate contentKey="integrawebApp.vehicle.carTypeWages">Car Type Wages</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.carTypeWages}</dd>
          <dt>
            <span id="tareWeightTons">
              <Translate contentKey="integrawebApp.vehicle.tareWeightTons">Tare Weight Tons</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.tareWeightTons}</dd>
          <dt>
            <span id="tankFillingKG">
              <Translate contentKey="integrawebApp.vehicle.tankFillingKG">Tank Filling KG</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.tankFillingKG}</dd>
          <dt>
            <span id="nextTUVDate">
              <Translate contentKey="integrawebApp.vehicle.nextTUVDate">Next TUV Date</Translate>
            </span>
          </dt>
          <dd>
            {vehicleEntity.nextTUVDate ? <TextFormat value={vehicleEntity.nextTUVDate} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="nextASUDate">
              <Translate contentKey="integrawebApp.vehicle.nextASUDate">Next ASU Date</Translate>
            </span>
          </dt>
          <dd>
            {vehicleEntity.nextASUDate ? <TextFormat value={vehicleEntity.nextASUDate} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="certificationTill">
              <Translate contentKey="integrawebApp.vehicle.certificationTill">Certification Till</Translate>
            </span>
          </dt>
          <dd>
            {vehicleEntity.certificationTill ? (
              <TextFormat value={vehicleEntity.certificationTill} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="leasingStart">
              <Translate contentKey="integrawebApp.vehicle.leasingStart">Leasing Start</Translate>
            </span>
          </dt>
          <dd>
            {vehicleEntity.leasingStart ? (
              <TextFormat value={vehicleEntity.leasingStart} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="leasingEnd">
              <Translate contentKey="integrawebApp.vehicle.leasingEnd">Leasing End</Translate>
            </span>
          </dt>
          <dd>
            {vehicleEntity.leasingEnd ? <TextFormat value={vehicleEntity.leasingEnd} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="nextInspectionDate">
              <Translate contentKey="integrawebApp.vehicle.nextInspectionDate">Next Inspection Date</Translate>
            </span>
          </dt>
          <dd>
            {vehicleEntity.nextInspectionDate ? (
              <TextFormat value={vehicleEntity.nextInspectionDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="cardId">
              <Translate contentKey="integrawebApp.vehicle.cardId">Card Id</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.cardId}</dd>
          <dt>
            <span id="emissionRate">
              <Translate contentKey="integrawebApp.vehicle.emissionRate">Emission Rate</Translate>
            </span>
          </dt>
          <dd>{vehicleEntity.emissionRate}</dd>
        </dl>
        <Button tag={Link} to="/vehicle" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/vehicle/${vehicleEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default VehicleDetail;
