import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { INVE } from 'app/shared/model/nve.model';
import { getEntity, updateEntity, createEntity, reset } from './nve.reducer';

export const NVEUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const nVEEntity = useAppSelector(state => state.nVE.entity);
  const loading = useAppSelector(state => state.nVE.loading);
  const updating = useAppSelector(state => state.nVE.updating);
  const updateSuccess = useAppSelector(state => state.nVE.updateSuccess);

  const handleClose = () => {
    navigate('/nve');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.timeFrom = convertDateTimeToServer(values.timeFrom);
    values.timeEnd = convertDateTimeToServer(values.timeEnd);
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.lastModifiedDate = convertDateTimeToServer(values.lastModifiedDate);
    values.labelingDate = convertDateTimeToServer(values.labelingDate);
    values.labelingTime = convertDateTimeToServer(values.labelingTime);
    values.idleTime = convertDateTimeToServer(values.idleTime);

    const entity = {
      ...nVEEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          timeFrom: displayDefaultDateTime(),
          timeEnd: displayDefaultDateTime(),
          createdDate: displayDefaultDateTime(),
          lastModifiedDate: displayDefaultDateTime(),
          labelingDate: displayDefaultDateTime(),
          labelingTime: displayDefaultDateTime(),
          idleTime: displayDefaultDateTime(),
        }
      : {
          ...nVEEntity,
          timeFrom: convertDateTimeFromServer(nVEEntity.timeFrom),
          timeEnd: convertDateTimeFromServer(nVEEntity.timeEnd),
          createdDate: convertDateTimeFromServer(nVEEntity.createdDate),
          lastModifiedDate: convertDateTimeFromServer(nVEEntity.lastModifiedDate),
          labelingDate: convertDateTimeFromServer(nVEEntity.labelingDate),
          labelingTime: convertDateTimeFromServer(nVEEntity.labelingTime),
          idleTime: convertDateTimeFromServer(nVEEntity.idleTime),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="integrawebApp.nVE.home.createOrEditLabel" data-cy="NVECreateUpdateHeading">
            <Translate contentKey="integrawebApp.nVE.home.createOrEditLabel">Create or edit a NVE</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="nve-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('integrawebApp.nVE.transportUnit')}
                id="nve-transportUnit"
                name="transportUnit"
                data-cy="transportUnit"
                type="text"
                validate={{
                  maxLength: { value: 4, message: translate('entity.validation.maxlength', { max: 4 }) },
                }}
              />
              <ValidatedField label={translate('integrawebApp.nVE.nveNr')} id="nve-nveNr" name="nveNr" data-cy="nveNr" type="text" />
              <ValidatedField
                label={translate('integrawebApp.nVE.nveOwn')}
                id="nve-nveOwn"
                name="nveOwn"
                data-cy="nveOwn"
                type="text"
                validate={{
                  maxLength: { value: 24, message: translate('entity.validation.maxlength', { max: 24 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.nVE.nveSupplier')}
                id="nve-nveSupplier"
                name="nveSupplier"
                data-cy="nveSupplier"
                type="text"
                validate={{
                  maxLength: { value: 24, message: translate('entity.validation.maxlength', { max: 24 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.nVE.supplierId')}
                id="nve-supplierId"
                name="supplierId"
                data-cy="supplierId"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.nVE.supplierAddress')}
                id="nve-supplierAddress"
                name="supplierAddress"
                data-cy="supplierAddress"
                type="text"
              />
              <ValidatedField label={translate('integrawebApp.nVE.weight')} id="nve-weight" name="weight" data-cy="weight" type="text" />
              <ValidatedField
                label={translate('integrawebApp.nVE.timeFrom')}
                id="nve-timeFrom"
                name="timeFrom"
                data-cy="timeFrom"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.nVE.timeEnd')}
                id="nve-timeEnd"
                name="timeEnd"
                data-cy="timeEnd"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.nVE.status')}
                id="nve-status"
                name="status"
                data-cy="status"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField label={translate('integrawebApp.nVE.note')} id="nve-note" name="note" data-cy="note" type="textarea" />
              <ValidatedField
                label={translate('integrawebApp.nVE.receiptDid')}
                id="nve-receiptDid"
                name="receiptDid"
                data-cy="receiptDid"
                type="text"
                validate={{
                  maxLength: { value: 38, message: translate('entity.validation.maxlength', { max: 38 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.nVE.loadingNr')}
                id="nve-loadingNr"
                name="loadingNr"
                data-cy="loadingNr"
                type="text"
              />
              <ValidatedField label={translate('integrawebApp.nVE.lockNr')} id="nve-lockNr" name="lockNr" data-cy="lockNr" type="text" />
              <ValidatedField
                label={translate('integrawebApp.nVE.createdDate')}
                id="nve-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.nVE.createdBy')}
                id="nve-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
                validate={{
                  maxLength: { value: 4, message: translate('entity.validation.maxlength', { max: 4 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.nVE.lastModifiedDate')}
                id="nve-lastModifiedDate"
                name="lastModifiedDate"
                data-cy="lastModifiedDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.nVE.lastModifiedBy')}
                id="nve-lastModifiedBy"
                name="lastModifiedBy"
                data-cy="lastModifiedBy"
                type="text"
                validate={{
                  maxLength: { value: 4, message: translate('entity.validation.maxlength', { max: 4 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.nVE.labelingDate')}
                id="nve-labelingDate"
                name="labelingDate"
                data-cy="labelingDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.nVE.labelingTime')}
                id="nve-labelingTime"
                name="labelingTime"
                data-cy="labelingTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.nVE.idleTime')}
                id="nve-idleTime"
                name="idleTime"
                data-cy="idleTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.nVE.quantity')}
                id="nve-quantity"
                name="quantity"
                data-cy="quantity"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.nVE.packageType')}
                id="nve-packageType"
                name="packageType"
                data-cy="packageType"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.nVE.quantityPackingUnit')}
                id="nve-quantityPackingUnit"
                name="quantityPackingUnit"
                data-cy="quantityPackingUnit"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.nVE.positionNve')}
                id="nve-positionNve"
                name="positionNve"
                data-cy="positionNve"
                type="text"
                validate={{
                  maxLength: { value: 18, message: translate('entity.validation.maxlength', { max: 18 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.nVE.palletType')}
                id="nve-palletType"
                name="palletType"
                data-cy="palletType"
                type="text"
                validate={{
                  maxLength: { value: 2, message: translate('entity.validation.maxlength', { max: 2 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.nVE.storageId')}
                id="nve-storageId"
                name="storageId"
                data-cy="storageId"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.nVE.warehouseId')}
                id="nve-warehouseId"
                name="warehouseId"
                data-cy="warehouseId"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.nVE.timeMinute')}
                id="nve-timeMinute"
                name="timeMinute"
                data-cy="timeMinute"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.nVE.dutyReferenceNr')}
                id="nve-dutyReferenceNr"
                name="dutyReferenceNr"
                data-cy="dutyReferenceNr"
                type="text"
                validate={{
                  maxLength: { value: 25, message: translate('entity.validation.maxlength', { max: 25 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.nVE.mixedPallet')}
                id="nve-mixedPallet"
                name="mixedPallet"
                data-cy="mixedPallet"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/nve" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default NVEUpdate;
