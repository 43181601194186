import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { byteSize, Translate, TextFormat, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { INVE } from 'app/shared/model/nve.model';
import { getEntities, reset } from './nve.reducer';

export const NVE = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );
  const [sorting, setSorting] = useState(false);

  const nVEList = useAppSelector(state => state.nVE.entities);
  const loading = useAppSelector(state => state.nVE.loading);
  const totalItems = useAppSelector(state => state.nVE.totalItems);
  const links = useAppSelector(state => state.nVE.links);
  const entity = useAppSelector(state => state.nVE.entity);
  const updateSuccess = useAppSelector(state => state.nVE.updateSuccess);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const resetAll = () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  useEffect(() => {
    resetAll();
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      resetAll();
    }
  }, [updateSuccess]);

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage]);

  const handleLoadMore = () => {
    if ((window as any).pageYOffset > 0) {
      setPaginationState({
        ...paginationState,
        activePage: paginationState.activePage + 1,
      });
    }
  };

  useEffect(() => {
    if (sorting) {
      getAllEntities();
      setSorting(false);
    }
  }, [sorting]);

  const sort = p => () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
    setSorting(true);
  };

  const handleSyncList = () => {
    resetAll();
  };

  return (
    <div>
      <h2 id="nve-heading" data-cy="NVEHeading">
        <Translate contentKey="integrawebApp.nVE.home.title">NVES</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="integrawebApp.nVE.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/nve/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="integrawebApp.nVE.home.createLabel">Create new NVE</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        <InfiniteScroll
          dataLength={nVEList ? nVEList.length : 0}
          next={handleLoadMore}
          hasMore={paginationState.activePage - 1 < links.next}
          loader={<div className="loader">Loading ...</div>}
        >
          {nVEList && nVEList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand" onClick={sort('id')}>
                    <Translate contentKey="integrawebApp.nVE.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('transportUnit')}>
                    <Translate contentKey="integrawebApp.nVE.transportUnit">Transport Unit</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('nveNr')}>
                    <Translate contentKey="integrawebApp.nVE.nveNr">Nve Nr</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('nveOwn')}>
                    <Translate contentKey="integrawebApp.nVE.nveOwn">Nve Own</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('nveSupplier')}>
                    <Translate contentKey="integrawebApp.nVE.nveSupplier">Nve Supplier</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('supplierId')}>
                    <Translate contentKey="integrawebApp.nVE.supplierId">Supplier Id</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('supplierAddress')}>
                    <Translate contentKey="integrawebApp.nVE.supplierAddress">Supplier Address</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('weight')}>
                    <Translate contentKey="integrawebApp.nVE.weight">Weight</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('timeFrom')}>
                    <Translate contentKey="integrawebApp.nVE.timeFrom">Time From</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('timeEnd')}>
                    <Translate contentKey="integrawebApp.nVE.timeEnd">Time End</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('status')}>
                    <Translate contentKey="integrawebApp.nVE.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('note')}>
                    <Translate contentKey="integrawebApp.nVE.note">Note</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('receiptDid')}>
                    <Translate contentKey="integrawebApp.nVE.receiptDid">Receipt Did</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('loadingNr')}>
                    <Translate contentKey="integrawebApp.nVE.loadingNr">Loading Nr</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('lockNr')}>
                    <Translate contentKey="integrawebApp.nVE.lockNr">Lock Nr</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('createdDate')}>
                    <Translate contentKey="integrawebApp.nVE.createdDate">Created Date</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('createdBy')}>
                    <Translate contentKey="integrawebApp.nVE.createdBy">Created By</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('lastModifiedDate')}>
                    <Translate contentKey="integrawebApp.nVE.lastModifiedDate">Last Modified Date</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('lastModifiedBy')}>
                    <Translate contentKey="integrawebApp.nVE.lastModifiedBy">Last Modified By</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('labelingDate')}>
                    <Translate contentKey="integrawebApp.nVE.labelingDate">Labeling Date</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('labelingTime')}>
                    <Translate contentKey="integrawebApp.nVE.labelingTime">Labeling Time</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('idleTime')}>
                    <Translate contentKey="integrawebApp.nVE.idleTime">Idle Time</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('quantity')}>
                    <Translate contentKey="integrawebApp.nVE.quantity">Quantity</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('packageType')}>
                    <Translate contentKey="integrawebApp.nVE.packageType">Package Type</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('quantityPackingUnit')}>
                    <Translate contentKey="integrawebApp.nVE.quantityPackingUnit">Quantity Packing Unit</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('positionNve')}>
                    <Translate contentKey="integrawebApp.nVE.positionNve">Position Nve</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('palletType')}>
                    <Translate contentKey="integrawebApp.nVE.palletType">Pallet Type</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('storageId')}>
                    <Translate contentKey="integrawebApp.nVE.storageId">Storage Id</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('warehouseId')}>
                    <Translate contentKey="integrawebApp.nVE.warehouseId">Warehouse Id</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('timeMinute')}>
                    <Translate contentKey="integrawebApp.nVE.timeMinute">Time Minute</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('dutyReferenceNr')}>
                    <Translate contentKey="integrawebApp.nVE.dutyReferenceNr">Duty Reference Nr</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('mixedPallet')}>
                    <Translate contentKey="integrawebApp.nVE.mixedPallet">Mixed Pallet</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {nVEList.map((nVE, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>
                      <Button tag={Link} to={`/nve/${nVE.id}`} color="link" size="sm">
                        {nVE.id}
                      </Button>
                    </td>
                    <td>{nVE.transportUnit}</td>
                    <td>{nVE.nveNr}</td>
                    <td>{nVE.nveOwn}</td>
                    <td>{nVE.nveSupplier}</td>
                    <td>{nVE.supplierId}</td>
                    <td>{nVE.supplierAddress}</td>
                    <td>{nVE.weight}</td>
                    <td>{nVE.timeFrom ? <TextFormat type="date" value={nVE.timeFrom} format={APP_DATE_FORMAT} /> : null}</td>
                    <td>{nVE.timeEnd ? <TextFormat type="date" value={nVE.timeEnd} format={APP_DATE_FORMAT} /> : null}</td>
                    <td>{nVE.status}</td>
                    <td>{nVE.note}</td>
                    <td>{nVE.receiptDid}</td>
                    <td>{nVE.loadingNr}</td>
                    <td>{nVE.lockNr}</td>
                    <td>{nVE.createdDate ? <TextFormat type="date" value={nVE.createdDate} format={APP_DATE_FORMAT} /> : null}</td>
                    <td>{nVE.createdBy}</td>
                    <td>
                      {nVE.lastModifiedDate ? <TextFormat type="date" value={nVE.lastModifiedDate} format={APP_DATE_FORMAT} /> : null}
                    </td>
                    <td>{nVE.lastModifiedBy}</td>
                    <td>{nVE.labelingDate ? <TextFormat type="date" value={nVE.labelingDate} format={APP_DATE_FORMAT} /> : null}</td>
                    <td>{nVE.labelingTime ? <TextFormat type="date" value={nVE.labelingTime} format={APP_DATE_FORMAT} /> : null}</td>
                    <td>{nVE.idleTime ? <TextFormat type="date" value={nVE.idleTime} format={APP_DATE_FORMAT} /> : null}</td>
                    <td>{nVE.quantity}</td>
                    <td>{nVE.packageType}</td>
                    <td>{nVE.quantityPackingUnit}</td>
                    <td>{nVE.positionNve}</td>
                    <td>{nVE.palletType}</td>
                    <td>{nVE.storageId}</td>
                    <td>{nVE.warehouseId}</td>
                    <td>{nVE.timeMinute}</td>
                    <td>{nVE.dutyReferenceNr}</td>
                    <td>{nVE.mixedPallet}</td>
                    <td className="text-end">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`/nve/${nVE.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`/nve/${nVE.id}/edit`} color="primary" size="sm" data-cy="entityEditButton">
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`/nve/${nVE.id}/delete`} color="danger" size="sm" data-cy="entityDeleteButton">
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="integrawebApp.nVE.home.notFound">No NVES found</Translate>
              </div>
            )
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default NVE;
