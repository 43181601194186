import dayjs from 'dayjs';

export interface IForeignReceiptPosition {
  id?: any;
  positionType?: number | null;
  articleId?: string | null;
  ean?: string | null;
  externalPositionNo?: string | null;
  blockPrinting?: string | null;
  positionTreatment?: string | null;
  amount?: number | null;
  saleAmount?: number | null;
  price?: number | null;
  calcDiscount?: string | null;
  value?: number | null;
  taxes?: number | null;
  foreignCurrencyAmount?: number | null;
  costOfSales?: number | null;
  quantPackingUnit?: number | null;
  packageType?: number | null;
  partialDeliveryAllowed?: string | null;
  orderNo?: string | null;
  customerArticleId?: string | null;
  customerSalesPrice?: number | null;
  taxType?: number | null;
  pricingType?: string | null;
  warehouseId?: string | null;
  storageId?: string | null;
  batchId?: string | null;
  expireDate?: string | null;
  costUnit?: string | null;
  costCenter?: string | null;
  projectId?: string | null;
  taxKey?: number | null;
  dutyFree?: string | null;
  textId?: number | null;
  valid?: string | null;
  taxValue?: number | null;
  receiptPosDidSource?: string | null;
  serviceDate?: string | null;
  externalReceipt?: string | null;
  weight?: number | null;
  weightingWeight?: number | null;
  calculationWeight?: number | null;
  deliveryDate?: string | null;
  deliveryDateCW?: string | null;
  deliveryTime?: string | null;
  isFixedDate?: string | null;
  confirmedDate?: string | null;
  originalDate?: string | null;
  goodsConsumptionAcc?: string | null;
  stockAcc?: string | null;
  positionDid?: string | null;
  restCancel?: string | null;
  rollOutType?: string | null;
  machineId?: string | null;
  positionDidCreated?: string | null;
  ersteditdat?: string | null;
  ltzteditdat?: string | null;
  receiptControl?: string | null;
  quantityUnit?: string | null;
  productionNo?: string | null;
  productionSeqNo?: string | null;
  oldCallNo?: number | null;
  callNo?: number | null;
  employeeId?: string | null;
  customerThirdPartyVendor?: string | null;
  requestArticle?: string | null;
  customerReference?: string | null;
  internalQuoteId?: string | null;
}

export const defaultValue: Readonly<IForeignReceiptPosition> = {};
