import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { loadMoreDataWhenScrolled, parseHeaderForLinks } from 'react-jhipster';
import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, ICompany } from 'app/shared/model/company.model';

interface CustomEntityState<T> extends EntityState<T> {
  customEntities: [];
}

const initialState: CustomEntityState<ICompany> = {
  loading: false,
  errorMessage: null,
  customEntities: [],
  entities: [],
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/companies';
const apiCustomUrl = 'api/custom/companies';

// Actions

// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export const getCustomEntities = createAsyncThunk('company/fetch_custom_entity_list', async (bearer: string = '') => {
  const requestUrl = `${apiCustomUrl}?cacheBuster=${new Date().getTime()}`;
  return axios.get<ICompany[]>(requestUrl, { headers: { Authorization: bearer } });
});

export const getEntities = createAsyncThunk('company/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<ICompany[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'company/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ICompany>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const CompanySlice = createEntitySlice({
  name: 'company',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        const links = parseHeaderForLinks(headers.link);

        return {
          ...state,
          loading: false,
          links,
          entities: loadMoreDataWhenScrolled(state.entities, data, links),
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isPending(getCustomEntities, getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isFulfilled(getCustomEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          customEntities: action.payload.data,
        };
      });
  },
});

export const { reset } = CompanySlice.actions;

// Reducer
export default CompanySlice.reducer;
