import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { INVEInhaltWareneingang } from 'app/shared/model/nve-inhalt-wareneingang.model';
import { getEntity, updateEntity, createEntity, reset } from './nve-inhalt-wareneingang.reducer';

export const NVEInhaltWareneingangUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const nVEInhaltWareneingangEntity = useAppSelector(state => state.nVEInhaltWareneingang.entity);
  const loading = useAppSelector(state => state.nVEInhaltWareneingang.loading);
  const updating = useAppSelector(state => state.nVEInhaltWareneingang.updating);
  const updateSuccess = useAppSelector(state => state.nVEInhaltWareneingang.updateSuccess);

  const handleClose = () => {
    navigate('/nve-inhalt-wareneingang');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.controlDate = convertDateTimeToServer(values.controlDate);
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.lastModifiedDate = convertDateTimeToServer(values.lastModifiedDate);

    const entity = {
      ...nVEInhaltWareneingangEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          controlDate: displayDefaultDateTime(),
          createdDate: displayDefaultDateTime(),
          lastModifiedDate: displayDefaultDateTime(),
        }
      : {
          ...nVEInhaltWareneingangEntity,
          controlDate: convertDateTimeFromServer(nVEInhaltWareneingangEntity.controlDate),
          createdDate: convertDateTimeFromServer(nVEInhaltWareneingangEntity.createdDate),
          lastModifiedDate: convertDateTimeFromServer(nVEInhaltWareneingangEntity.lastModifiedDate),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="integrawebApp.nVEInhaltWareneingang.home.createOrEditLabel" data-cy="NVEInhaltWareneingangCreateUpdateHeading">
            <Translate contentKey="integrawebApp.nVEInhaltWareneingang.home.createOrEditLabel">
              Create or edit a NVEInhaltWareneingang
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="nve-inhalt-wareneingang-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWareneingang.articleId')}
                id="nve-inhalt-wareneingang-articleId"
                name="articleId"
                data-cy="articleId"
                type="text"
                validate={{
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWareneingang.quantityId')}
                id="nve-inhalt-wareneingang-quantityId"
                name="quantityId"
                data-cy="quantityId"
                type="text"
                validate={{
                  maxLength: { value: 38, message: translate('entity.validation.maxlength', { max: 38 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWareneingang.bookIdNr')}
                id="nve-inhalt-wareneingang-bookIdNr"
                name="bookIdNr"
                data-cy="bookIdNr"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWareneingang.ean')}
                id="nve-inhalt-wareneingang-ean"
                name="ean"
                data-cy="ean"
                type="text"
                validate={{
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWareneingang.batchId')}
                id="nve-inhalt-wareneingang-batchId"
                name="batchId"
                data-cy="batchId"
                type="text"
                validate={{
                  maxLength: { value: 25, message: translate('entity.validation.maxlength', { max: 25 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWareneingang.fabricationDate')}
                id="nve-inhalt-wareneingang-fabricationDate"
                name="fabricationDate"
                data-cy="fabricationDate"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWareneingang.expireDate')}
                id="nve-inhalt-wareneingang-expireDate"
                name="expireDate"
                data-cy="expireDate"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWareneingang.quantity')}
                id="nve-inhalt-wareneingang-quantity"
                name="quantity"
                data-cy="quantity"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWareneingang.quantityForeign')}
                id="nve-inhalt-wareneingang-quantityForeign"
                name="quantityForeign"
                data-cy="quantityForeign"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWareneingang.weight')}
                id="nve-inhalt-wareneingang-weight"
                name="weight"
                data-cy="weight"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWareneingang.employeeontrolId')}
                id="nve-inhalt-wareneingang-employeeontrolId"
                name="employeeontrolId"
                data-cy="employeeontrolId"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWareneingang.quantityControl')}
                id="nve-inhalt-wareneingang-quantityControl"
                name="quantityControl"
                data-cy="quantityControl"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWareneingang.controlDate')}
                id="nve-inhalt-wareneingang-controlDate"
                name="controlDate"
                data-cy="controlDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWareneingang.createdDate')}
                id="nve-inhalt-wareneingang-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWareneingang.createdBy')}
                id="nve-inhalt-wareneingang-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
                validate={{
                  maxLength: { value: 4, message: translate('entity.validation.maxlength', { max: 4 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWareneingang.lastModifiedDate')}
                id="nve-inhalt-wareneingang-lastModifiedDate"
                name="lastModifiedDate"
                data-cy="lastModifiedDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.nVEInhaltWareneingang.lastModifiedBy')}
                id="nve-inhalt-wareneingang-lastModifiedBy"
                name="lastModifiedBy"
                data-cy="lastModifiedBy"
                type="text"
                validate={{
                  maxLength: { value: 4, message: translate('entity.validation.maxlength', { max: 4 }) },
                }}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/nve-inhalt-wareneingang" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default NVEInhaltWareneingangUpdate;
