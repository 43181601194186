import dayjs from 'dayjs';

export interface IDataIdentifierSSC {
  id?: string;
  description?: string | null;
  shortDesc?: string | null;
  shortDescInt?: string | null;
  type?: string | null;
  length?: number | null;
  special?: number | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
}

export const defaultValue: Readonly<IDataIdentifierSSC> = {};
