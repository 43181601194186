import React, { useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Document, Page } from 'react-pdf';
import _ from 'lodash';
import { SizeMe } from 'react-sizeme';

const PDFViewer = () => {
  const { file }: any = useParams();
  const [pages, setPages] = useState(0);
  const onLoadSuccess = pdf => {
    setPages(pdf.numPages);
  };

  const url = useMemo(() => atob(file), [file]);

  return (
    <Document onLoadSuccess={onLoadSuccess} file={{ url }}>
      <Pages pages={pages} />
    </Document>
  );
};

const Pages = props => {
  return (
    <SizeMe>
      {({ size }) => (
        <>
          {_.range(props.pages).map((item, index: number) => {
            return <Page width={size.width} key={`pdf-page-${index + 1}`} pageNumber={index + 1} />;
          })}
        </>
      )}
    </SizeMe>
  );
};

export default PDFViewer;
