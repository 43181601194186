import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './foreign-receipt-position.reducer';

export const ForeignReceiptPositionDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const foreignReceiptPositionEntity = useAppSelector(state => state.foreignReceiptPosition.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="foreignReceiptPositionDetailsHeading">
          <Translate contentKey="integrawebApp.foreignReceiptPosition.detail.title">ForeignReceiptPosition</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.id}</dd>
          <dt>
            <span id="positionType">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.positionType">Position Type</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.positionType}</dd>
          <dt>
            <span id="articleId">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.articleId">Article Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.articleId}</dd>
          <dt>
            <span id="ean">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.ean">Ean</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.ean}</dd>
          <dt>
            <span id="externalPositionNo">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.externalPositionNo">External Position No</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.externalPositionNo}</dd>
          <dt>
            <span id="blockPrinting">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.blockPrinting">Block Printing</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.blockPrinting}</dd>
          <dt>
            <span id="positionTreatment">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.positionTreatment">Position Treatment</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.positionTreatment}</dd>
          <dt>
            <span id="amount">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.amount">Amount</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.amount}</dd>
          <dt>
            <span id="saleAmount">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.saleAmount">Sale Amount</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.saleAmount}</dd>
          <dt>
            <span id="price">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.price">Price</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.price}</dd>
          <dt>
            <span id="calcDiscount">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.calcDiscount">Calc Discount</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.calcDiscount}</dd>
          <dt>
            <span id="value">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.value">Value</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.value}</dd>
          <dt>
            <span id="taxes">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.taxes">Taxes</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.taxes}</dd>
          <dt>
            <span id="foreignCurrencyAmount">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.foreignCurrencyAmount">Foreign Currency Amount</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.foreignCurrencyAmount}</dd>
          <dt>
            <span id="costOfSales">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.costOfSales">Cost Of Sales</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.costOfSales}</dd>
          <dt>
            <span id="quantPackingUnit">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.quantPackingUnit">Quant Packing Unit</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.quantPackingUnit}</dd>
          <dt>
            <span id="packageType">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.packageType">Package Type</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.packageType}</dd>
          <dt>
            <span id="partialDeliveryAllowed">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.partialDeliveryAllowed">Partial Delivery Allowed</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.partialDeliveryAllowed}</dd>
          <dt>
            <span id="orderNo">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.orderNo">Order No</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.orderNo}</dd>
          <dt>
            <span id="customerArticleId">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.customerArticleId">Customer Article Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.customerArticleId}</dd>
          <dt>
            <span id="customerSalesPrice">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.customerSalesPrice">Customer Sales Price</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.customerSalesPrice}</dd>
          <dt>
            <span id="taxType">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.taxType">Tax Type</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.taxType}</dd>
          <dt>
            <span id="pricingType">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.pricingType">Pricing Type</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.pricingType}</dd>
          <dt>
            <span id="warehouseId">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.warehouseId">Warehouse Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.warehouseId}</dd>
          <dt>
            <span id="storageId">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.storageId">Storage Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.storageId}</dd>
          <dt>
            <span id="batchId">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.batchId">Batch Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.batchId}</dd>
          <dt>
            <span id="expireDate">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.expireDate">Expire Date</Translate>
            </span>
          </dt>
          <dd>
            {foreignReceiptPositionEntity.expireDate ? (
              <TextFormat value={foreignReceiptPositionEntity.expireDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="costUnit">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.costUnit">Cost Unit</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.costUnit}</dd>
          <dt>
            <span id="costCenter">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.costCenter">Cost Center</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.costCenter}</dd>
          <dt>
            <span id="projectId">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.projectId">Project Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.projectId}</dd>
          <dt>
            <span id="taxKey">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.taxKey">Tax Key</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.taxKey}</dd>
          <dt>
            <span id="dutyFree">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.dutyFree">Duty Free</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.dutyFree}</dd>
          <dt>
            <span id="textId">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.textId">Text Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.textId}</dd>
          <dt>
            <span id="valid">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.valid">Valid</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.valid}</dd>
          <dt>
            <span id="taxValue">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.taxValue">Tax Value</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.taxValue}</dd>
          <dt>
            <span id="receiptPosDidSource">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.receiptPosDidSource">Receipt Pos Did Source</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.receiptPosDidSource}</dd>
          <dt>
            <span id="serviceDate">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.serviceDate">Service Date</Translate>
            </span>
          </dt>
          <dd>
            {foreignReceiptPositionEntity.serviceDate ? (
              <TextFormat value={foreignReceiptPositionEntity.serviceDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="externalReceipt">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.externalReceipt">External Receipt</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.externalReceipt}</dd>
          <dt>
            <span id="weight">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.weight">Weight</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.weight}</dd>
          <dt>
            <span id="weightingWeight">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.weightingWeight">Weighting Weight</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.weightingWeight}</dd>
          <dt>
            <span id="calculationWeight">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.calculationWeight">Calculation Weight</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.calculationWeight}</dd>
          <dt>
            <span id="deliveryDate">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.deliveryDate">Delivery Date</Translate>
            </span>
          </dt>
          <dd>
            {foreignReceiptPositionEntity.deliveryDate ? (
              <TextFormat value={foreignReceiptPositionEntity.deliveryDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="deliveryDateCW">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.deliveryDateCW">Delivery Date CW</Translate>
            </span>
          </dt>
          <dd>
            {foreignReceiptPositionEntity.deliveryDateCW ? (
              <TextFormat value={foreignReceiptPositionEntity.deliveryDateCW} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="deliveryTime">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.deliveryTime">Delivery Time</Translate>
            </span>
          </dt>
          <dd>
            {foreignReceiptPositionEntity.deliveryTime ? (
              <TextFormat value={foreignReceiptPositionEntity.deliveryTime} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="isFixedDate">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.isFixedDate">Is Fixed Date</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.isFixedDate}</dd>
          <dt>
            <span id="confirmedDate">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.confirmedDate">Confirmed Date</Translate>
            </span>
          </dt>
          <dd>
            {foreignReceiptPositionEntity.confirmedDate ? (
              <TextFormat value={foreignReceiptPositionEntity.confirmedDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="originalDate">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.originalDate">Original Date</Translate>
            </span>
          </dt>
          <dd>
            {foreignReceiptPositionEntity.originalDate ? (
              <TextFormat value={foreignReceiptPositionEntity.originalDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="goodsConsumptionAcc">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.goodsConsumptionAcc">Goods Consumption Acc</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.goodsConsumptionAcc}</dd>
          <dt>
            <span id="stockAcc">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.stockAcc">Stock Acc</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.stockAcc}</dd>
          <dt>
            <span id="positionDid">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.positionDid">Position Did</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.positionDid}</dd>
          <dt>
            <span id="restCancel">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.restCancel">Rest Cancel</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.restCancel}</dd>
          <dt>
            <span id="rollOutType">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.rollOutType">Roll Out Type</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.rollOutType}</dd>
          <dt>
            <span id="machineId">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.machineId">Machine Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.machineId}</dd>
          <dt>
            <span id="positionDidCreated">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.positionDidCreated">Position Did Created</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.positionDidCreated}</dd>
          <dt>
            <span id="ersteditdat">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.ersteditdat">Ersteditdat</Translate>
            </span>
          </dt>
          <dd>
            {foreignReceiptPositionEntity.ersteditdat ? (
              <TextFormat value={foreignReceiptPositionEntity.ersteditdat} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="ltzteditdat">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.ltzteditdat">Ltzteditdat</Translate>
            </span>
          </dt>
          <dd>
            {foreignReceiptPositionEntity.ltzteditdat ? (
              <TextFormat value={foreignReceiptPositionEntity.ltzteditdat} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="receiptControl">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.receiptControl">Receipt Control</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.receiptControl}</dd>
          <dt>
            <span id="quantityUnit">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.quantityUnit">Quantity Unit</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.quantityUnit}</dd>
          <dt>
            <span id="productionNo">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.productionNo">Production No</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.productionNo}</dd>
          <dt>
            <span id="productionSeqNo">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.productionSeqNo">Production Seq No</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.productionSeqNo}</dd>
          <dt>
            <span id="oldCallNo">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.oldCallNo">Old Call No</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.oldCallNo}</dd>
          <dt>
            <span id="callNo">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.callNo">Call No</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.callNo}</dd>
          <dt>
            <span id="employeeId">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.employeeId">Employee Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.employeeId}</dd>
          <dt>
            <span id="customerThirdPartyVendor">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.customerThirdPartyVendor">Customer Third Party Vendor</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.customerThirdPartyVendor}</dd>
          <dt>
            <span id="requestArticle">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.requestArticle">Request Article</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.requestArticle}</dd>
          <dt>
            <span id="customerReference">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.customerReference">Customer Reference</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.customerReference}</dd>
          <dt>
            <span id="internalQuoteId">
              <Translate contentKey="integrawebApp.foreignReceiptPosition.internalQuoteId">Internal Quote Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptPositionEntity.internalQuoteId}</dd>
        </dl>
        <Button tag={Link} to="/foreign-receipt-position" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/foreign-receipt-position/${foreignReceiptPositionEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ForeignReceiptPositionDetail;
