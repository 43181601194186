import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './transport-order.reducer';

export const TransportOrderDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const transportOrderEntity = useAppSelector(state => state.transportOrder.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="transportOrderDetailsHeading">
          <Translate contentKey="integrawebApp.transportOrder.detail.title">TransportOrder</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.id}</dd>
          <dt>
            <span id="rideNr">
              <Translate contentKey="integrawebApp.transportOrder.rideNr">Ride Nr</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.rideNr}</dd>
          <dt>
            <span id="tourOrder">
              <Translate contentKey="integrawebApp.transportOrder.tourOrder">Tour Order</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.tourOrder}</dd>
          <dt>
            <span id="orderWeight">
              <Translate contentKey="integrawebApp.transportOrder.orderWeight">Order Weight</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.orderWeight}</dd>
          <dt>
            <span id="orderVolume">
              <Translate contentKey="integrawebApp.transportOrder.orderVolume">Order Volume</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.orderVolume}</dd>
          <dt>
            <span id="amountStat">
              <Translate contentKey="integrawebApp.transportOrder.amountStat">Amount Stat</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.amountStat}</dd>
          <dt>
            <span id="serviceUnit">
              <Translate contentKey="integrawebApp.transportOrder.serviceUnit">Service Unit</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.serviceUnit}</dd>
          <dt>
            <span id="customersLoadingTime">
              <Translate contentKey="integrawebApp.transportOrder.customersLoadingTime">Customers Loading Time</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.customersLoadingTime}</dd>
          <dt>
            <span id="customerCollectionTime">
              <Translate contentKey="integrawebApp.transportOrder.customerCollectionTime">Customer Collection Time</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.customerCollectionTime}</dd>
          <dt>
            <span id="customerSetupTime">
              <Translate contentKey="integrawebApp.transportOrder.customerSetupTime">Customer Setup Time</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.customerSetupTime}</dd>
          <dt>
            <span id="vehicleType">
              <Translate contentKey="integrawebApp.transportOrder.vehicleType">Vehicle Type</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.vehicleType}</dd>
          <dt>
            <span id="vehiclePos">
              <Translate contentKey="integrawebApp.transportOrder.vehiclePos">Vehicle Pos</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.vehiclePos}</dd>
          <dt>
            <span id="carrierStatus">
              <Translate contentKey="integrawebApp.transportOrder.carrierStatus">Carrier Status</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.carrierStatus}</dd>
          <dt>
            <span id="carriageRatePlanned">
              <Translate contentKey="integrawebApp.transportOrder.carriageRatePlanned">Carriage Rate Planned</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.carriageRatePlanned}</dd>
          <dt>
            <span id="carriageRateActual">
              <Translate contentKey="integrawebApp.transportOrder.carriageRateActual">Carriage Rate Actual</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.carriageRateActual}</dd>
          <dt>
            <span id="carriageFeePlanned">
              <Translate contentKey="integrawebApp.transportOrder.carriageFeePlanned">Carriage Fee Planned</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.carriageFeePlanned}</dd>
          <dt>
            <span id="carriageFeeActual">
              <Translate contentKey="integrawebApp.transportOrder.carriageFeeActual">Carriage Fee Actual</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.carriageFeeActual}</dd>
          <dt>
            <span id="customerActualTime">
              <Translate contentKey="integrawebApp.transportOrder.customerActualTime">Customer Actual Time</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.customerActualTime}</dd>
          <dt>
            <span id="amountTransportUnit">
              <Translate contentKey="integrawebApp.transportOrder.amountTransportUnit">Amount Transport Unit</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.amountTransportUnit}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="integrawebApp.transportOrder.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {transportOrderEntity.createdDate ? (
              <TextFormat value={transportOrderEntity.createdDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="integrawebApp.transportOrder.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.createdBy}</dd>
          <dt>
            <span id="lastModifiedDate">
              <Translate contentKey="integrawebApp.transportOrder.lastModifiedDate">Last Modified Date</Translate>
            </span>
          </dt>
          <dd>
            {transportOrderEntity.lastModifiedDate ? (
              <TextFormat value={transportOrderEntity.lastModifiedDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="lastModifiedBy">
              <Translate contentKey="integrawebApp.transportOrder.lastModifiedBy">Last Modified By</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.lastModifiedBy}</dd>
          <dt>
            <span id="amountBarrel">
              <Translate contentKey="integrawebApp.transportOrder.amountBarrel">Amount Barrel</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.amountBarrel}</dd>
          <dt>
            <span id="amountCrate">
              <Translate contentKey="integrawebApp.transportOrder.amountCrate">Amount Crate</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.amountCrate}</dd>
          <dt>
            <span id="amountBox">
              <Translate contentKey="integrawebApp.transportOrder.amountBox">Amount Box</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.amountBox}</dd>
          <dt>
            <span id="amountOthers">
              <Translate contentKey="integrawebApp.transportOrder.amountOthers">Amount Others</Translate>
            </span>
          </dt>
          <dd>{transportOrderEntity.amountOthers}</dd>
        </dl>
        <Button tag={Link} to="/transport-order" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/transport-order/${transportOrderEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default TransportOrderDetail;
