import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getIwsRestStatus } from '../administration.reducer';

export const IwsRestStatus = () => {
  const dispatch = useAppDispatch();

  const restStatus = dispatch(getIwsRestStatus());

  useEffect(() => {
    dispatch(getIwsRestStatus());
  }, []);

  console.warn(restStatus);

  return (
    <div>
      <h3>IWS REST Status</h3>
      <pre>{JSON.stringify(restStatus, null, 2)}</pre>
    </div>
  );
};

export default IwsRestStatus;
