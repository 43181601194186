import dayjs from 'dayjs';

export interface IReceipt {
  id?: string;
  receiptHeadId?: string | null;
  receiptTypeNr?: number | null;
  detectionType?: string | null;
  receiptNr?: string | null;
  customerId?: string | null;
  supplierId?: string | null;
  receiptDate?: string | null;
  printDate?: string | null;
  printedBy?: string | null;
  logNr?: number | null;
  logPos?: number | null;
  receiptArtNr?: number | null;
  valid?: string | null;
  validTill?: string | null;
  locked?: string | null;
  bookingDate?: string | null;
  operationId?: string | null;
  nrbs?: number | null;
  currentversion?: number | null;
  versionprinted?: number | null;
  headChanges?: string | null;
  posChanges?: string | null;
  foreignReceiptNr?: string | null;
  foreignReceiptDate?: string | null;
  barcodenr?: string | null;
  shippingWeight?: number | null;
  packageNumbers?: number | null;
  archived?: string | null;
  storageArea?: string | null;
  order?: number | null;
  receiptControlMan?: number | null;
  finished?: string | null;
  statusWeighing?: string | null;
  fullSerialNumber?: string | null;
  storageFullyBooked?: string | null;
  autoPosLocked?: number | null;
  transNr?: number | null;
  transNrAlt?: number | null;
  retrievalNr?: number | null;
  retrievalNrAlt?: number | null;
  billingBlock?: string | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  nrwe?: number | null;
  kassennr?: string | null;
}

export const defaultValue: Readonly<IReceipt> = {};
