import dayjs from 'dayjs';

export interface IBranchMaster {
  id?: number;
  description?: string | null;
  text?: string | null;
  taxNo?: string | null;
  branchNo?: string | null;
  stateId?: number | null;
  costCenterId?: string | null;
  headquarterOrBranch?: string | null;
  branchAdressId?: string | null;
  customsOfficeAddressId?: string | null;
  customsOfficeNo?: string | null;
  customsNo?: string | null;
  exportCustomsOfficeNo?: string | null;
  recordStatus?: string | null;
  recordId?: string | null;
  differentPostalAddressLabel?: number | null;
  differentPostalAddressBranchId?: number | null;
  differentPostalAddressId?: string | null;
  differentPostalAddressType?: number | null;
  salesTaxIdNo?: string | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
}

export const defaultValue: Readonly<IBranchMaster> = {};
