import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import TransportOrder from './transport-order';
import TransportOrderDetail from './transport-order-detail';
import TransportOrderUpdate from './transport-order-update';
import TransportOrderDeleteDialog from './transport-order-delete-dialog';

const TransportOrderRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<TransportOrder />} />
    <Route path="new" element={<TransportOrderUpdate />} />
    <Route path=":id">
      <Route index element={<TransportOrderDetail />} />
      <Route path="edit" element={<TransportOrderUpdate />} />
      <Route path="delete" element={<TransportOrderDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TransportOrderRoutes;
