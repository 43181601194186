import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import DataIdentifierSSC from './data-identifier-ssc';
import DataIdentifierSSCDetail from './data-identifier-ssc-detail';
import DataIdentifierSSCUpdate from './data-identifier-ssc-update';
import DataIdentifierSSCDeleteDialog from './data-identifier-ssc-delete-dialog';

const DataIdentifierSSCRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<DataIdentifierSSC />} />
    <Route path="new" element={<DataIdentifierSSCUpdate />} />
    <Route path=":id">
      <Route index element={<DataIdentifierSSCDetail />} />
      <Route path="edit" element={<DataIdentifierSSCUpdate />} />
      <Route path="delete" element={<DataIdentifierSSCDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DataIdentifierSSCRoutes;
