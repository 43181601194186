import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ArtikeltexteDID from './artikeltexte-did';
import ArtikeltexteDIDDetail from './artikeltexte-did-detail';
import ArtikeltexteDIDUpdate from './artikeltexte-did-update';
import ArtikeltexteDIDDeleteDialog from './artikeltexte-did-delete-dialog';

const ArtikeltexteDIDRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ArtikeltexteDID />} />
    <Route path="new" element={<ArtikeltexteDIDUpdate />} />
    <Route path=":id">
      <Route index element={<ArtikeltexteDIDDetail />} />
      <Route path="edit" element={<ArtikeltexteDIDUpdate />} />
      <Route path="delete" element={<ArtikeltexteDIDDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ArtikeltexteDIDRoutes;
