import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IReceipt } from 'app/shared/model/receipt.model';
import { getEntities, reset } from './receipt.reducer';

export const Receipt = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );
  const [sorting, setSorting] = useState(false);

  const receiptList = useAppSelector(state => state.receipt.entities);
  const loading = useAppSelector(state => state.receipt.loading);
  const totalItems = useAppSelector(state => state.receipt.totalItems);
  const links = useAppSelector(state => state.receipt.links);
  const entity = useAppSelector(state => state.receipt.entity);
  const updateSuccess = useAppSelector(state => state.receipt.updateSuccess);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const resetAll = () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  useEffect(() => {
    resetAll();
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      resetAll();
    }
  }, [updateSuccess]);

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage]);

  const handleLoadMore = () => {
    if ((window as any).pageYOffset > 0) {
      setPaginationState({
        ...paginationState,
        activePage: paginationState.activePage + 1,
      });
    }
  };

  useEffect(() => {
    if (sorting) {
      getAllEntities();
      setSorting(false);
    }
  }, [sorting]);

  const sort = p => () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
    setSorting(true);
  };

  const handleSyncList = () => {
    resetAll();
  };

  return (
    <div>
      <h2 id="receipt-heading" data-cy="ReceiptHeading">
        <Translate contentKey="integrawebApp.receipt.home.title">Receipts</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="integrawebApp.receipt.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/receipt/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="integrawebApp.receipt.home.createLabel">Create new Receipt</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        <InfiniteScroll
          dataLength={receiptList ? receiptList.length : 0}
          next={handleLoadMore}
          hasMore={paginationState.activePage - 1 < links.next}
          loader={<div className="loader">Loading ...</div>}
        >
          {receiptList && receiptList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand" onClick={sort('id')}>
                    <Translate contentKey="integrawebApp.receipt.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('receiptHeadId')}>
                    <Translate contentKey="integrawebApp.receipt.receiptHeadId">Receipt Head Id</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('receiptTypeNr')}>
                    <Translate contentKey="integrawebApp.receipt.receiptTypeNr">Receipt Type Nr</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('detectionType')}>
                    <Translate contentKey="integrawebApp.receipt.detectionType">Detection Type</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('receiptNr')}>
                    <Translate contentKey="integrawebApp.receipt.receiptNr">Receipt Nr</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('customerId')}>
                    <Translate contentKey="integrawebApp.receipt.customerId">Customer Id</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('supplierId')}>
                    <Translate contentKey="integrawebApp.receipt.supplierId">Supplier Id</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('receiptDate')}>
                    <Translate contentKey="integrawebApp.receipt.receiptDate">Receipt Date</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('printDate')}>
                    <Translate contentKey="integrawebApp.receipt.printDate">Print Date</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('printedBy')}>
                    <Translate contentKey="integrawebApp.receipt.printedBy">Printed By</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('logNr')}>
                    <Translate contentKey="integrawebApp.receipt.logNr">Log Nr</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('logPos')}>
                    <Translate contentKey="integrawebApp.receipt.logPos">Log Pos</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('receiptArtNr')}>
                    <Translate contentKey="integrawebApp.receipt.receiptArtNr">Receipt Art Nr</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('valid')}>
                    <Translate contentKey="integrawebApp.receipt.valid">Valid</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('validTill')}>
                    <Translate contentKey="integrawebApp.receipt.validTill">Valid Till</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('locked')}>
                    <Translate contentKey="integrawebApp.receipt.locked">Locked</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('bookingDate')}>
                    <Translate contentKey="integrawebApp.receipt.bookingDate">Booking Date</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('operationId')}>
                    <Translate contentKey="integrawebApp.receipt.operationId">Operation Id</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('nrbs')}>
                    <Translate contentKey="integrawebApp.receipt.nrbs">Nrbs</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('currentversion')}>
                    <Translate contentKey="integrawebApp.receipt.currentversion">Currentversion</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('versionprinted')}>
                    <Translate contentKey="integrawebApp.receipt.versionprinted">Versionprinted</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('headChanges')}>
                    <Translate contentKey="integrawebApp.receipt.headChanges">Head Changes</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('posChanges')}>
                    <Translate contentKey="integrawebApp.receipt.posChanges">Pos Changes</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('foreignReceiptNr')}>
                    <Translate contentKey="integrawebApp.receipt.foreignReceiptNr">Foreign Receipt Nr</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('foreignReceiptDate')}>
                    <Translate contentKey="integrawebApp.receipt.foreignReceiptDate">Foreign Receipt Date</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('barcodenr')}>
                    <Translate contentKey="integrawebApp.receipt.barcodenr">Barcodenr</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('shippingWeight')}>
                    <Translate contentKey="integrawebApp.receipt.shippingWeight">Shipping Weight</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('packageNumbers')}>
                    <Translate contentKey="integrawebApp.receipt.packageNumbers">Package Numbers</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('archived')}>
                    <Translate contentKey="integrawebApp.receipt.archived">Archived</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('storageArea')}>
                    <Translate contentKey="integrawebApp.receipt.storageArea">Storage Area</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('order')}>
                    <Translate contentKey="integrawebApp.receipt.order">Order</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('receiptControlMan')}>
                    <Translate contentKey="integrawebApp.receipt.receiptControlMan">Receipt Control Man</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('finished')}>
                    <Translate contentKey="integrawebApp.receipt.finished">Finished</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('statusWeighing')}>
                    <Translate contentKey="integrawebApp.receipt.statusWeighing">Status Weighing</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('fullSerialNumber')}>
                    <Translate contentKey="integrawebApp.receipt.fullSerialNumber">Full Serial Number</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('storageFullyBooked')}>
                    <Translate contentKey="integrawebApp.receipt.storageFullyBooked">Storage Fully Booked</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('autoPosLocked')}>
                    <Translate contentKey="integrawebApp.receipt.autoPosLocked">Auto Pos Locked</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('transNr')}>
                    <Translate contentKey="integrawebApp.receipt.transNr">Trans Nr</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('transNrAlt')}>
                    <Translate contentKey="integrawebApp.receipt.transNrAlt">Trans Nr Alt</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('retrievalNr')}>
                    <Translate contentKey="integrawebApp.receipt.retrievalNr">Retrieval Nr</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('retrievalNrAlt')}>
                    <Translate contentKey="integrawebApp.receipt.retrievalNrAlt">Retrieval Nr Alt</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('billingBlock')}>
                    <Translate contentKey="integrawebApp.receipt.billingBlock">Billing Block</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('createdDate')}>
                    <Translate contentKey="integrawebApp.receipt.createdDate">Created Date</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('createdBy')}>
                    <Translate contentKey="integrawebApp.receipt.createdBy">Created By</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('lastModifiedDate')}>
                    <Translate contentKey="integrawebApp.receipt.lastModifiedDate">Last Modified Date</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('lastModifiedBy')}>
                    <Translate contentKey="integrawebApp.receipt.lastModifiedBy">Last Modified By</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('nrwe')}>
                    <Translate contentKey="integrawebApp.receipt.nrwe">Nrwe</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('kassennr')}>
                    <Translate contentKey="integrawebApp.receipt.kassennr">Kassennr</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {receiptList.map((receipt, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>
                      <Button tag={Link} to={`/receipt/${receipt.id}`} color="link" size="sm">
                        {receipt.id}
                      </Button>
                    </td>
                    <td>{receipt.receiptHeadId}</td>
                    <td>{receipt.receiptTypeNr}</td>
                    <td>{receipt.detectionType}</td>
                    <td>{receipt.receiptNr}</td>
                    <td>{receipt.customerId}</td>
                    <td>{receipt.supplierId}</td>
                    <td>{receipt.receiptDate ? <TextFormat type="date" value={receipt.receiptDate} format={APP_DATE_FORMAT} /> : null}</td>
                    <td>{receipt.printDate ? <TextFormat type="date" value={receipt.printDate} format={APP_DATE_FORMAT} /> : null}</td>
                    <td>{receipt.printedBy}</td>
                    <td>{receipt.logNr}</td>
                    <td>{receipt.logPos}</td>
                    <td>{receipt.receiptArtNr}</td>
                    <td>{receipt.valid}</td>
                    <td>
                      {receipt.validTill ? <TextFormat type="date" value={receipt.validTill} format={APP_LOCAL_DATE_FORMAT} /> : null}
                    </td>
                    <td>{receipt.locked}</td>
                    <td>
                      {receipt.bookingDate ? <TextFormat type="date" value={receipt.bookingDate} format={APP_LOCAL_DATE_FORMAT} /> : null}
                    </td>
                    <td>{receipt.operationId}</td>
                    <td>{receipt.nrbs}</td>
                    <td>{receipt.currentversion}</td>
                    <td>{receipt.versionprinted}</td>
                    <td>{receipt.headChanges}</td>
                    <td>{receipt.posChanges}</td>
                    <td>{receipt.foreignReceiptNr}</td>
                    <td>
                      {receipt.foreignReceiptDate ? (
                        <TextFormat type="date" value={receipt.foreignReceiptDate} format={APP_LOCAL_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>{receipt.barcodenr}</td>
                    <td>{receipt.shippingWeight}</td>
                    <td>{receipt.packageNumbers}</td>
                    <td>{receipt.archived}</td>
                    <td>{receipt.storageArea}</td>
                    <td>{receipt.order}</td>
                    <td>{receipt.receiptControlMan}</td>
                    <td>{receipt.finished}</td>
                    <td>{receipt.statusWeighing}</td>
                    <td>{receipt.fullSerialNumber}</td>
                    <td>{receipt.storageFullyBooked}</td>
                    <td>{receipt.autoPosLocked}</td>
                    <td>{receipt.transNr}</td>
                    <td>{receipt.transNrAlt}</td>
                    <td>{receipt.retrievalNr}</td>
                    <td>{receipt.retrievalNrAlt}</td>
                    <td>{receipt.billingBlock}</td>
                    <td>{receipt.createdDate ? <TextFormat type="date" value={receipt.createdDate} format={APP_DATE_FORMAT} /> : null}</td>
                    <td>{receipt.createdBy}</td>
                    <td>
                      {receipt.lastModifiedDate ? (
                        <TextFormat type="date" value={receipt.lastModifiedDate} format={APP_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>{receipt.lastModifiedBy}</td>
                    <td>{receipt.nrwe}</td>
                    <td>{receipt.kassennr}</td>
                    <td className="text-end">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`/receipt/${receipt.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`/receipt/${receipt.id}/edit`} color="primary" size="sm" data-cy="entityEditButton">
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`/receipt/${receipt.id}/delete`} color="danger" size="sm" data-cy="entityDeleteButton">
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="integrawebApp.receipt.home.notFound">No Receipts found</Translate>
              </div>
            )
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Receipt;
