import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './nve-inhalt-wareneingang.reducer';

export const NVEInhaltWareneingangDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const nVEInhaltWareneingangEntity = useAppSelector(state => state.nVEInhaltWareneingang.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="nVEInhaltWareneingangDetailsHeading">
          <Translate contentKey="integrawebApp.nVEInhaltWareneingang.detail.title">NVEInhaltWareneingang</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{nVEInhaltWareneingangEntity.id}</dd>
          <dt>
            <span id="articleId">
              <Translate contentKey="integrawebApp.nVEInhaltWareneingang.articleId">Article Id</Translate>
            </span>
          </dt>
          <dd>{nVEInhaltWareneingangEntity.articleId}</dd>
          <dt>
            <span id="quantityId">
              <Translate contentKey="integrawebApp.nVEInhaltWareneingang.quantityId">Quantity Id</Translate>
            </span>
          </dt>
          <dd>{nVEInhaltWareneingangEntity.quantityId}</dd>
          <dt>
            <span id="bookIdNr">
              <Translate contentKey="integrawebApp.nVEInhaltWareneingang.bookIdNr">Book Id Nr</Translate>
            </span>
          </dt>
          <dd>{nVEInhaltWareneingangEntity.bookIdNr}</dd>
          <dt>
            <span id="ean">
              <Translate contentKey="integrawebApp.nVEInhaltWareneingang.ean">Ean</Translate>
            </span>
          </dt>
          <dd>{nVEInhaltWareneingangEntity.ean}</dd>
          <dt>
            <span id="batchId">
              <Translate contentKey="integrawebApp.nVEInhaltWareneingang.batchId">Batch Id</Translate>
            </span>
          </dt>
          <dd>{nVEInhaltWareneingangEntity.batchId}</dd>
          <dt>
            <span id="fabricationDate">
              <Translate contentKey="integrawebApp.nVEInhaltWareneingang.fabricationDate">Fabrication Date</Translate>
            </span>
          </dt>
          <dd>
            {nVEInhaltWareneingangEntity.fabricationDate ? (
              <TextFormat value={nVEInhaltWareneingangEntity.fabricationDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="expireDate">
              <Translate contentKey="integrawebApp.nVEInhaltWareneingang.expireDate">Expire Date</Translate>
            </span>
          </dt>
          <dd>
            {nVEInhaltWareneingangEntity.expireDate ? (
              <TextFormat value={nVEInhaltWareneingangEntity.expireDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="quantity">
              <Translate contentKey="integrawebApp.nVEInhaltWareneingang.quantity">Quantity</Translate>
            </span>
          </dt>
          <dd>{nVEInhaltWareneingangEntity.quantity}</dd>
          <dt>
            <span id="quantityForeign">
              <Translate contentKey="integrawebApp.nVEInhaltWareneingang.quantityForeign">Quantity Foreign</Translate>
            </span>
          </dt>
          <dd>{nVEInhaltWareneingangEntity.quantityForeign}</dd>
          <dt>
            <span id="weight">
              <Translate contentKey="integrawebApp.nVEInhaltWareneingang.weight">Weight</Translate>
            </span>
          </dt>
          <dd>{nVEInhaltWareneingangEntity.weight}</dd>
          <dt>
            <span id="employeeontrolId">
              <Translate contentKey="integrawebApp.nVEInhaltWareneingang.employeeontrolId">Employeeontrol Id</Translate>
            </span>
          </dt>
          <dd>{nVEInhaltWareneingangEntity.employeeontrolId}</dd>
          <dt>
            <span id="quantityControl">
              <Translate contentKey="integrawebApp.nVEInhaltWareneingang.quantityControl">Quantity Control</Translate>
            </span>
          </dt>
          <dd>{nVEInhaltWareneingangEntity.quantityControl}</dd>
          <dt>
            <span id="controlDate">
              <Translate contentKey="integrawebApp.nVEInhaltWareneingang.controlDate">Control Date</Translate>
            </span>
          </dt>
          <dd>
            {nVEInhaltWareneingangEntity.controlDate ? (
              <TextFormat value={nVEInhaltWareneingangEntity.controlDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="integrawebApp.nVEInhaltWareneingang.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {nVEInhaltWareneingangEntity.createdDate ? (
              <TextFormat value={nVEInhaltWareneingangEntity.createdDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="integrawebApp.nVEInhaltWareneingang.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{nVEInhaltWareneingangEntity.createdBy}</dd>
          <dt>
            <span id="lastModifiedDate">
              <Translate contentKey="integrawebApp.nVEInhaltWareneingang.lastModifiedDate">Last Modified Date</Translate>
            </span>
          </dt>
          <dd>
            {nVEInhaltWareneingangEntity.lastModifiedDate ? (
              <TextFormat value={nVEInhaltWareneingangEntity.lastModifiedDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="lastModifiedBy">
              <Translate contentKey="integrawebApp.nVEInhaltWareneingang.lastModifiedBy">Last Modified By</Translate>
            </span>
          </dt>
          <dd>{nVEInhaltWareneingangEntity.lastModifiedBy}</dd>
        </dl>
        <Button tag={Link} to="/nve-inhalt-wareneingang" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/nve-inhalt-wareneingang/${nVEInhaltWareneingangEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default NVEInhaltWareneingangDetail;
