import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './foreign-receipt.reducer';

export const ForeignReceiptDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const foreignReceiptEntity = useAppSelector(state => state.foreignReceipt.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="foreignReceiptDetailsHeading">
          <Translate contentKey="integrawebApp.foreignReceipt.detail.title">ForeignReceipt</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.id}</dd>
          <dt>
            <span id="processType">
              <Translate contentKey="integrawebApp.foreignReceipt.processType">Process Type</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.processType}</dd>
          <dt>
            <span id="receiptKind">
              <Translate contentKey="integrawebApp.foreignReceipt.receiptKind">Receipt Kind</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.receiptKind}</dd>
          <dt>
            <span id="receiptDate">
              <Translate contentKey="integrawebApp.foreignReceipt.receiptDate">Receipt Date</Translate>
            </span>
          </dt>
          <dd>
            {foreignReceiptEntity.receiptDate ? (
              <TextFormat value={foreignReceiptEntity.receiptDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="receiptNo">
              <Translate contentKey="integrawebApp.foreignReceipt.receiptNo">Receipt No</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.receiptNo}</dd>
          <dt>
            <span id="customerId">
              <Translate contentKey="integrawebApp.foreignReceipt.customerId">Customer Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.customerId}</dd>
          <dt>
            <span id="addressId">
              <Translate contentKey="integrawebApp.foreignReceipt.addressId">Address Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.addressId}</dd>
          <dt>
            <span id="contactPersonId">
              <Translate contentKey="integrawebApp.foreignReceipt.contactPersonId">Contact Person Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.contactPersonId}</dd>
          <dt>
            <span id="diffInvoiceRecipient">
              <Translate contentKey="integrawebApp.foreignReceipt.diffInvoiceRecipient">Diff Invoice Recipient</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.diffInvoiceRecipient}</dd>
          <dt>
            <span id="supplierId">
              <Translate contentKey="integrawebApp.foreignReceipt.supplierId">Supplier Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.supplierId}</dd>
          <dt>
            <span id="supplierAddress">
              <Translate contentKey="integrawebApp.foreignReceipt.supplierAddress">Supplier Address</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.supplierAddress}</dd>
          <dt>
            <span id="diffDebtor">
              <Translate contentKey="integrawebApp.foreignReceipt.diffDebtor">Diff Debtor</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.diffDebtor}</dd>
          <dt>
            <span id="diffCreditor">
              <Translate contentKey="integrawebApp.foreignReceipt.diffCreditor">Diff Creditor</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.diffCreditor}</dd>
          <dt>
            <span id="warehouseId">
              <Translate contentKey="integrawebApp.foreignReceipt.warehouseId">Warehouse Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.warehouseId}</dd>
          <dt>
            <span id="proofType">
              <Translate contentKey="integrawebApp.foreignReceipt.proofType">Proof Type</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.proofType}</dd>
          <dt>
            <span id="blocked">
              <Translate contentKey="integrawebApp.foreignReceipt.blocked">Blocked</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.blocked}</dd>
          <dt>
            <span id="barcode">
              <Translate contentKey="integrawebApp.foreignReceipt.barcode">Barcode</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.barcode}</dd>
          <dt>
            <span id="externalReceipt">
              <Translate contentKey="integrawebApp.foreignReceipt.externalReceipt">External Receipt</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.externalReceipt}</dd>
          <dt>
            <span id="receivingWarehouse">
              <Translate contentKey="integrawebApp.foreignReceipt.receivingWarehouse">Receiving Warehouse</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.receivingWarehouse}</dd>
          <dt>
            <span id="externalReceiptDate">
              <Translate contentKey="integrawebApp.foreignReceipt.externalReceiptDate">External Receipt Date</Translate>
            </span>
          </dt>
          <dd>
            {foreignReceiptEntity.externalReceiptDate ? (
              <TextFormat value={foreignReceiptEntity.externalReceiptDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="costCenter">
              <Translate contentKey="integrawebApp.foreignReceipt.costCenter">Cost Center</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.costCenter}</dd>
          <dt>
            <span id="costCenterDischarge">
              <Translate contentKey="integrawebApp.foreignReceipt.costCenterDischarge">Cost Center Discharge</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.costCenterDischarge}</dd>
          <dt>
            <span id="costUnit">
              <Translate contentKey="integrawebApp.foreignReceipt.costUnit">Cost Unit</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.costUnit}</dd>
          <dt>
            <span id="projectId">
              <Translate contentKey="integrawebApp.foreignReceipt.projectId">Project Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.projectId}</dd>
          <dt>
            <span id="glAccount">
              <Translate contentKey="integrawebApp.foreignReceipt.glAccount">Gl Account</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.glAccount}</dd>
          <dt>
            <span id="paymentType">
              <Translate contentKey="integrawebApp.foreignReceipt.paymentType">Payment Type</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.paymentType}</dd>
          <dt>
            <span id="priceGroup">
              <Translate contentKey="integrawebApp.foreignReceipt.priceGroup">Price Group</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.priceGroup}</dd>
          <dt>
            <span id="discountGroup">
              <Translate contentKey="integrawebApp.foreignReceipt.discountGroup">Discount Group</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.discountGroup}</dd>
          <dt>
            <span id="deliveryCondition">
              <Translate contentKey="integrawebApp.foreignReceipt.deliveryCondition">Delivery Condition</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.deliveryCondition}</dd>
          <dt>
            <span id="paymentCondition">
              <Translate contentKey="integrawebApp.foreignReceipt.paymentCondition">Payment Condition</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.paymentCondition}</dd>
          <dt>
            <span id="currency">
              <Translate contentKey="integrawebApp.foreignReceipt.currency">Currency</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.currency}</dd>
          <dt>
            <span id="exchangeRate">
              <Translate contentKey="integrawebApp.foreignReceipt.exchangeRate">Exchange Rate</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.exchangeRate}</dd>
          <dt>
            <span id="netrGross">
              <Translate contentKey="integrawebApp.foreignReceipt.netrGross">Netr Gross</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.netrGross}</dd>
          <dt>
            <span id="shippingMethod">
              <Translate contentKey="integrawebApp.foreignReceipt.shippingMethod">Shipping Method</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.shippingMethod}</dd>
          <dt>
            <span id="desiredDate">
              <Translate contentKey="integrawebApp.foreignReceipt.desiredDate">Desired Date</Translate>
            </span>
          </dt>
          <dd>
            {foreignReceiptEntity.desiredDate ? (
              <TextFormat value={foreignReceiptEntity.desiredDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="deliveryTimeDays">
              <Translate contentKey="integrawebApp.foreignReceipt.deliveryTimeDays">Delivery Time Days</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.deliveryTimeDays}</dd>
          <dt>
            <span id="deliverDate">
              <Translate contentKey="integrawebApp.foreignReceipt.deliverDate">Deliver Date</Translate>
            </span>
          </dt>
          <dd>
            {foreignReceiptEntity.deliverDate ? (
              <TextFormat value={foreignReceiptEntity.deliverDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="creditOrDelivery">
              <Translate contentKey="integrawebApp.foreignReceipt.creditOrDelivery">Credit Or Delivery</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.creditOrDelivery}</dd>
          <dt>
            <span id="hasShortageSurcharge">
              <Translate contentKey="integrawebApp.foreignReceipt.hasShortageSurcharge">Has Shortage Surcharge</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.hasShortageSurcharge}</dd>
          <dt>
            <span id="tourId">
              <Translate contentKey="integrawebApp.foreignReceipt.tourId">Tour Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.tourId}</dd>
          <dt>
            <span id="rideId">
              <Translate contentKey="integrawebApp.foreignReceipt.rideId">Ride Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.rideId}</dd>
          <dt>
            <span id="externalNo">
              <Translate contentKey="integrawebApp.foreignReceipt.externalNo">External No</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.externalNo}</dd>
          <dt>
            <span id="externalDate">
              <Translate contentKey="integrawebApp.foreignReceipt.externalDate">External Date</Translate>
            </span>
          </dt>
          <dd>
            {foreignReceiptEntity.externalDate ? (
              <TextFormat value={foreignReceiptEntity.externalDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="partialDeliveryAllowed">
              <Translate contentKey="integrawebApp.foreignReceipt.partialDeliveryAllowed">Partial Delivery Allowed</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.partialDeliveryAllowed}</dd>
          <dt>
            <span id="offerValidUntil">
              <Translate contentKey="integrawebApp.foreignReceipt.offerValidUntil">Offer Valid Until</Translate>
            </span>
          </dt>
          <dd>
            {foreignReceiptEntity.offerValidUntil ? (
              <TextFormat value={foreignReceiptEntity.offerValidUntil} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="completionRate">
              <Translate contentKey="integrawebApp.foreignReceipt.completionRate">Completion Rate</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.completionRate}</dd>
          <dt>
            <span id="dutyFree">
              <Translate contentKey="integrawebApp.foreignReceipt.dutyFree">Duty Free</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.dutyFree}</dd>
          <dt>
            <span id="textId">
              <Translate contentKey="integrawebApp.foreignReceipt.textId">Text Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.textId}</dd>
          <dt>
            <span id="employeeId">
              <Translate contentKey="integrawebApp.foreignReceipt.employeeId">Employee Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.employeeId}</dd>
          <dt>
            <span id="processingDate">
              <Translate contentKey="integrawebApp.foreignReceipt.processingDate">Processing Date</Translate>
            </span>
          </dt>
          <dd>
            {foreignReceiptEntity.processingDate ? (
              <TextFormat value={foreignReceiptEntity.processingDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="errorText">
              <Translate contentKey="integrawebApp.foreignReceipt.errorText">Error Text</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.errorText}</dd>
          <dt>
            <span id="receiptDid">
              <Translate contentKey="integrawebApp.foreignReceipt.receiptDid">Receipt Did</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.receiptDid}</dd>
          <dt>
            <span id="billingNo">
              <Translate contentKey="integrawebApp.foreignReceipt.billingNo">Billing No</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.billingNo}</dd>
          <dt>
            <span id="receiptDidSource">
              <Translate contentKey="integrawebApp.foreignReceipt.receiptDidSource">Receipt Did Source</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.receiptDidSource}</dd>
          <dt>
            <span id="keyWord">
              <Translate contentKey="integrawebApp.foreignReceipt.keyWord">Key Word</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.keyWord}</dd>
          <dt>
            <span id="valid">
              <Translate contentKey="integrawebApp.foreignReceipt.valid">Valid</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.valid}</dd>
          <dt>
            <span id="bookingDate">
              <Translate contentKey="integrawebApp.foreignReceipt.bookingDate">Booking Date</Translate>
            </span>
          </dt>
          <dd>
            {foreignReceiptEntity.bookingDate ? (
              <TextFormat value={foreignReceiptEntity.bookingDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="branch">
              <Translate contentKey="integrawebApp.foreignReceipt.branch">Branch</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.branch}</dd>
          <dt>
            <span id="desiredTime">
              <Translate contentKey="integrawebApp.foreignReceipt.desiredTime">Desired Time</Translate>
            </span>
          </dt>
          <dd>
            {foreignReceiptEntity.desiredTime ? (
              <TextFormat value={foreignReceiptEntity.desiredTime} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="deliveryTime">
              <Translate contentKey="integrawebApp.foreignReceipt.deliveryTime">Delivery Time</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.deliveryTime}</dd>
          <dt>
            <span id="referenceId">
              <Translate contentKey="integrawebApp.foreignReceipt.referenceId">Reference Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.referenceId}</dd>
          <dt>
            <span id="countryCode">
              <Translate contentKey="integrawebApp.foreignReceipt.countryCode">Country Code</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.countryCode}</dd>
          <dt>
            <span id="bankCode">
              <Translate contentKey="integrawebApp.foreignReceipt.bankCode">Bank Code</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.bankCode}</dd>
          <dt>
            <span id="bankAccount">
              <Translate contentKey="integrawebApp.foreignReceipt.bankAccount">Bank Account</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.bankAccount}</dd>
          <dt>
            <span id="deliveryBy">
              <Translate contentKey="integrawebApp.foreignReceipt.deliveryBy">Delivery By</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.deliveryBy}</dd>
          <dt>
            <span id="deliveryTo">
              <Translate contentKey="integrawebApp.foreignReceipt.deliveryTo">Delivery To</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.deliveryTo}</dd>
          <dt>
            <span id="ltzteditdat">
              <Translate contentKey="integrawebApp.foreignReceipt.ltzteditdat">Ltzteditdat</Translate>
            </span>
          </dt>
          <dd>
            {foreignReceiptEntity.ltzteditdat ? (
              <TextFormat value={foreignReceiptEntity.ltzteditdat} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="ersteditdat">
              <Translate contentKey="integrawebApp.foreignReceipt.ersteditdat">Ersteditdat</Translate>
            </span>
          </dt>
          <dd>
            {foreignReceiptEntity.ersteditdat ? (
              <TextFormat value={foreignReceiptEntity.ersteditdat} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="deliveryOption">
              <Translate contentKey="integrawebApp.foreignReceipt.deliveryOption">Delivery Option</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.deliveryOption}</dd>
          <dt>
            <span id="vatId">
              <Translate contentKey="integrawebApp.foreignReceipt.vatId">Vat Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.vatId}</dd>
          <dt>
            <span id="taxCountry">
              <Translate contentKey="integrawebApp.foreignReceipt.taxCountry">Tax Country</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.taxCountry}</dd>
          <dt>
            <span id="transferNo">
              <Translate contentKey="integrawebApp.foreignReceipt.transferNo">Transfer No</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.transferNo}</dd>
          <dt>
            <span id="transferNoAlt">
              <Translate contentKey="integrawebApp.foreignReceipt.transferNoAlt">Transfer No Alt</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.transferNoAlt}</dd>
          <dt>
            <span id="campaignId">
              <Translate contentKey="integrawebApp.foreignReceipt.campaignId">Campaign Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.campaignId}</dd>
          <dt>
            <span id="foreignCurrency">
              <Translate contentKey="integrawebApp.foreignReceipt.foreignCurrency">Foreign Currency</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.foreignCurrency}</dd>
          <dt>
            <span id="foreignCurrencyRate">
              <Translate contentKey="integrawebApp.foreignReceipt.foreignCurrencyRate">Foreign Currency Rate</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.foreignCurrencyRate}</dd>
          <dt>
            <span id="customsReceiptId">
              <Translate contentKey="integrawebApp.foreignReceipt.customsReceiptId">Customs Receipt Id</Translate>
            </span>
          </dt>
          <dd>{foreignReceiptEntity.customsReceiptId}</dd>
        </dl>
        <Button tag={Link} to="/foreign-receipt" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/foreign-receipt/${foreignReceiptEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ForeignReceiptDetail;
