import dayjs from 'dayjs';

export interface IArtikeltexteDID {
  id?: string;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
}

export const defaultValue: Readonly<IArtikeltexteDID> = {};
