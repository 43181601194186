import dayjs from 'dayjs';

export interface IVehicle {
  id?: string;
  vehicleName?: string | null;
  licensePlate?: string | null;
  vehicleType?: string | null;
  costCenter?: string | null;
  registrationDate?: string | null;
  payloadTons?: number | null;
  loadingVolume?: number | null;
  fixedCosts?: number | null;
  variableCosts?: number | null;
  trailerSurcharge?: number | null;
  performanceKW?: number | null;
  trailerSurchargeMinute?: number | null;
  correctionFactor?: number | null;
  correctionSetupTime?: number | null;
  cargoAreaSM?: number | null;
  correctionSpecifiedTime?: number | null;
  maxPitches?: number | null;
  rearLoading?: string | null;
  freightRate?: string | null;
  recordStatus?: string | null;
  carrierId?: string | null;
  customerId?: string | null;
  supplierId?: string | null;
  vehicleCertification?: string | null;
  setId?: string | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  noMDEdevice?: string | null;
  lockedDateFrom?: string | null;
  lockedDateTill?: string | null;
  siteWages?: string | null;
  carTypeWages?: string | null;
  tareWeightTons?: number | null;
  tankFillingKG?: number | null;
  nextTUVDate?: string | null;
  nextASUDate?: string | null;
  certificationTill?: string | null;
  leasingStart?: string | null;
  leasingEnd?: string | null;
  nextInspectionDate?: string | null;
  cardId?: string | null;
  emissionRate?: string | null;
}

export const defaultValue: Readonly<IVehicle> = {};
