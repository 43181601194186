import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IForeignReceiptImport } from 'app/shared/model/foreign-receipt-import.model';
import { getEntity, updateEntity, createEntity, reset } from './foreign-receipt-import.reducer';

export const ForeignReceiptImportUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const foreignReceiptImportEntity = useAppSelector(state => state.foreignReceiptImport.entity);
  const loading = useAppSelector(state => state.foreignReceiptImport.loading);
  const updating = useAppSelector(state => state.foreignReceiptImport.updating);
  const updateSuccess = useAppSelector(state => state.foreignReceiptImport.updateSuccess);

  const handleClose = () => {
    navigate('/foreign-receipt-import');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.processingDate = convertDateTimeToServer(values.processingDate);
    values.ltzteditdat = convertDateTimeToServer(values.ltzteditdat);

    const entity = {
      ...foreignReceiptImportEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          processingDate: displayDefaultDateTime(),
          ltzteditdat: displayDefaultDateTime(),
        }
      : {
          ...foreignReceiptImportEntity,
          processingDate: convertDateTimeFromServer(foreignReceiptImportEntity.processingDate),
          ltzteditdat: convertDateTimeFromServer(foreignReceiptImportEntity.ltzteditdat),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="integrawebApp.foreignReceiptImport.home.createOrEditLabel" data-cy="ForeignReceiptImportCreateUpdateHeading">
            <Translate contentKey="integrawebApp.foreignReceiptImport.home.createOrEditLabel">
              Create or edit a ForeignReceiptImport
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="foreign-receipt-import-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptImport.name')}
                id="foreign-receipt-import-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  maxLength: { value: 60, message: translate('entity.validation.maxlength', { max: 60 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptImport.createDate')}
                id="foreign-receipt-import-createDate"
                name="createDate"
                data-cy="createDate"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptImport.type')}
                id="foreign-receipt-import-type"
                name="type"
                data-cy="type"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptImport.processType')}
                id="foreign-receipt-import-processType"
                name="processType"
                data-cy="processType"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptImport.branch')}
                id="foreign-receipt-import-branch"
                name="branch"
                data-cy="branch"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptImport.warehouseId')}
                id="foreign-receipt-import-warehouseId"
                name="warehouseId"
                data-cy="warehouseId"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptImport.programmId')}
                id="foreign-receipt-import-programmId"
                name="programmId"
                data-cy="programmId"
                type="text"
                validate={{
                  maxLength: { value: 3, message: translate('entity.validation.maxlength', { max: 3 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptImport.release')}
                id="foreign-receipt-import-release"
                name="release"
                data-cy="release"
                type="text"
                validate={{
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptImport.client')}
                id="foreign-receipt-import-client"
                name="client"
                data-cy="client"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptImport.inOut')}
                id="foreign-receipt-import-inOut"
                name="inOut"
                data-cy="inOut"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptImport.specialTreatment')}
                id="foreign-receipt-import-specialTreatment"
                name="specialTreatment"
                data-cy="specialTreatment"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptImport.processingDate')}
                id="foreign-receipt-import-processingDate"
                name="processingDate"
                data-cy="processingDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptImport.errorCount')}
                id="foreign-receipt-import-errorCount"
                name="errorCount"
                data-cy="errorCount"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptImport.path')}
                id="foreign-receipt-import-path"
                name="path"
                data-cy="path"
                type="text"
                validate={{
                  maxLength: { value: 200, message: translate('entity.validation.maxlength', { max: 200 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptImport.fileName')}
                id="foreign-receipt-import-fileName"
                name="fileName"
                data-cy="fileName"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptImport.ltzteditdat')}
                id="foreign-receipt-import-ltzteditdat"
                name="ltzteditdat"
                data-cy="ltzteditdat"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceiptImport.ltzteditid')}
                id="foreign-receipt-import-ltzteditid"
                name="ltzteditid"
                data-cy="ltzteditid"
                type="text"
                validate={{
                  maxLength: { value: 4, message: translate('entity.validation.maxlength', { max: 4 }) },
                }}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/foreign-receipt-import" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ForeignReceiptImportUpdate;
