import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './receipt.reducer';

export const ReceiptDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const receiptEntity = useAppSelector(state => state.receipt.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="receiptDetailsHeading">
          <Translate contentKey="integrawebApp.receipt.detail.title">Receipt</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.id}</dd>
          <dt>
            <span id="receiptHeadId">
              <Translate contentKey="integrawebApp.receipt.receiptHeadId">Receipt Head Id</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.receiptHeadId}</dd>
          <dt>
            <span id="receiptTypeNr">
              <Translate contentKey="integrawebApp.receipt.receiptTypeNr">Receipt Type Nr</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.receiptTypeNr}</dd>
          <dt>
            <span id="detectionType">
              <Translate contentKey="integrawebApp.receipt.detectionType">Detection Type</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.detectionType}</dd>
          <dt>
            <span id="receiptNr">
              <Translate contentKey="integrawebApp.receipt.receiptNr">Receipt Nr</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.receiptNr}</dd>
          <dt>
            <span id="customerId">
              <Translate contentKey="integrawebApp.receipt.customerId">Customer Id</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.customerId}</dd>
          <dt>
            <span id="supplierId">
              <Translate contentKey="integrawebApp.receipt.supplierId">Supplier Id</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.supplierId}</dd>
          <dt>
            <span id="receiptDate">
              <Translate contentKey="integrawebApp.receipt.receiptDate">Receipt Date</Translate>
            </span>
          </dt>
          <dd>
            {receiptEntity.receiptDate ? <TextFormat value={receiptEntity.receiptDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="printDate">
              <Translate contentKey="integrawebApp.receipt.printDate">Print Date</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.printDate ? <TextFormat value={receiptEntity.printDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="printedBy">
              <Translate contentKey="integrawebApp.receipt.printedBy">Printed By</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.printedBy}</dd>
          <dt>
            <span id="logNr">
              <Translate contentKey="integrawebApp.receipt.logNr">Log Nr</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.logNr}</dd>
          <dt>
            <span id="logPos">
              <Translate contentKey="integrawebApp.receipt.logPos">Log Pos</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.logPos}</dd>
          <dt>
            <span id="receiptArtNr">
              <Translate contentKey="integrawebApp.receipt.receiptArtNr">Receipt Art Nr</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.receiptArtNr}</dd>
          <dt>
            <span id="valid">
              <Translate contentKey="integrawebApp.receipt.valid">Valid</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.valid}</dd>
          <dt>
            <span id="validTill">
              <Translate contentKey="integrawebApp.receipt.validTill">Valid Till</Translate>
            </span>
          </dt>
          <dd>
            {receiptEntity.validTill ? <TextFormat value={receiptEntity.validTill} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="locked">
              <Translate contentKey="integrawebApp.receipt.locked">Locked</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.locked}</dd>
          <dt>
            <span id="bookingDate">
              <Translate contentKey="integrawebApp.receipt.bookingDate">Booking Date</Translate>
            </span>
          </dt>
          <dd>
            {receiptEntity.bookingDate ? <TextFormat value={receiptEntity.bookingDate} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="operationId">
              <Translate contentKey="integrawebApp.receipt.operationId">Operation Id</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.operationId}</dd>
          <dt>
            <span id="nrbs">
              <Translate contentKey="integrawebApp.receipt.nrbs">Nrbs</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.nrbs}</dd>
          <dt>
            <span id="currentversion">
              <Translate contentKey="integrawebApp.receipt.currentversion">Currentversion</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.currentversion}</dd>
          <dt>
            <span id="versionprinted">
              <Translate contentKey="integrawebApp.receipt.versionprinted">Versionprinted</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.versionprinted}</dd>
          <dt>
            <span id="headChanges">
              <Translate contentKey="integrawebApp.receipt.headChanges">Head Changes</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.headChanges}</dd>
          <dt>
            <span id="posChanges">
              <Translate contentKey="integrawebApp.receipt.posChanges">Pos Changes</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.posChanges}</dd>
          <dt>
            <span id="foreignReceiptNr">
              <Translate contentKey="integrawebApp.receipt.foreignReceiptNr">Foreign Receipt Nr</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.foreignReceiptNr}</dd>
          <dt>
            <span id="foreignReceiptDate">
              <Translate contentKey="integrawebApp.receipt.foreignReceiptDate">Foreign Receipt Date</Translate>
            </span>
          </dt>
          <dd>
            {receiptEntity.foreignReceiptDate ? (
              <TextFormat value={receiptEntity.foreignReceiptDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="barcodenr">
              <Translate contentKey="integrawebApp.receipt.barcodenr">Barcodenr</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.barcodenr}</dd>
          <dt>
            <span id="shippingWeight">
              <Translate contentKey="integrawebApp.receipt.shippingWeight">Shipping Weight</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.shippingWeight}</dd>
          <dt>
            <span id="packageNumbers">
              <Translate contentKey="integrawebApp.receipt.packageNumbers">Package Numbers</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.packageNumbers}</dd>
          <dt>
            <span id="archived">
              <Translate contentKey="integrawebApp.receipt.archived">Archived</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.archived}</dd>
          <dt>
            <span id="storageArea">
              <Translate contentKey="integrawebApp.receipt.storageArea">Storage Area</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.storageArea}</dd>
          <dt>
            <span id="order">
              <Translate contentKey="integrawebApp.receipt.order">Order</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.order}</dd>
          <dt>
            <span id="receiptControlMan">
              <Translate contentKey="integrawebApp.receipt.receiptControlMan">Receipt Control Man</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.receiptControlMan}</dd>
          <dt>
            <span id="finished">
              <Translate contentKey="integrawebApp.receipt.finished">Finished</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.finished}</dd>
          <dt>
            <span id="statusWeighing">
              <Translate contentKey="integrawebApp.receipt.statusWeighing">Status Weighing</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.statusWeighing}</dd>
          <dt>
            <span id="fullSerialNumber">
              <Translate contentKey="integrawebApp.receipt.fullSerialNumber">Full Serial Number</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.fullSerialNumber}</dd>
          <dt>
            <span id="storageFullyBooked">
              <Translate contentKey="integrawebApp.receipt.storageFullyBooked">Storage Fully Booked</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.storageFullyBooked}</dd>
          <dt>
            <span id="autoPosLocked">
              <Translate contentKey="integrawebApp.receipt.autoPosLocked">Auto Pos Locked</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.autoPosLocked}</dd>
          <dt>
            <span id="transNr">
              <Translate contentKey="integrawebApp.receipt.transNr">Trans Nr</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.transNr}</dd>
          <dt>
            <span id="transNrAlt">
              <Translate contentKey="integrawebApp.receipt.transNrAlt">Trans Nr Alt</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.transNrAlt}</dd>
          <dt>
            <span id="retrievalNr">
              <Translate contentKey="integrawebApp.receipt.retrievalNr">Retrieval Nr</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.retrievalNr}</dd>
          <dt>
            <span id="retrievalNrAlt">
              <Translate contentKey="integrawebApp.receipt.retrievalNrAlt">Retrieval Nr Alt</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.retrievalNrAlt}</dd>
          <dt>
            <span id="billingBlock">
              <Translate contentKey="integrawebApp.receipt.billingBlock">Billing Block</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.billingBlock}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="integrawebApp.receipt.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {receiptEntity.createdDate ? <TextFormat value={receiptEntity.createdDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="integrawebApp.receipt.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.createdBy}</dd>
          <dt>
            <span id="lastModifiedDate">
              <Translate contentKey="integrawebApp.receipt.lastModifiedDate">Last Modified Date</Translate>
            </span>
          </dt>
          <dd>
            {receiptEntity.lastModifiedDate ? (
              <TextFormat value={receiptEntity.lastModifiedDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="lastModifiedBy">
              <Translate contentKey="integrawebApp.receipt.lastModifiedBy">Last Modified By</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.lastModifiedBy}</dd>
          <dt>
            <span id="nrwe">
              <Translate contentKey="integrawebApp.receipt.nrwe">Nrwe</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.nrwe}</dd>
          <dt>
            <span id="kassennr">
              <Translate contentKey="integrawebApp.receipt.kassennr">Kassennr</Translate>
            </span>
          </dt>
          <dd>{receiptEntity.kassennr}</dd>
        </dl>
        <Button tag={Link} to="/receipt" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/receipt/${receiptEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ReceiptDetail;
