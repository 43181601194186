import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IForeignReceipt } from 'app/shared/model/foreign-receipt.model';
import { getEntity, updateEntity, createEntity, reset } from './foreign-receipt.reducer';

export const ForeignReceiptUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const foreignReceiptEntity = useAppSelector(state => state.foreignReceipt.entity);
  const loading = useAppSelector(state => state.foreignReceipt.loading);
  const updating = useAppSelector(state => state.foreignReceipt.updating);
  const updateSuccess = useAppSelector(state => state.foreignReceipt.updateSuccess);

  const handleClose = () => {
    navigate('/foreign-receipt');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.processingDate = convertDateTimeToServer(values.processingDate);
    values.desiredTime = convertDateTimeToServer(values.desiredTime);
    values.ltzteditdat = convertDateTimeToServer(values.ltzteditdat);
    values.ersteditdat = convertDateTimeToServer(values.ersteditdat);

    const entity = {
      ...foreignReceiptEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          processingDate: displayDefaultDateTime(),
          desiredTime: displayDefaultDateTime(),
          ltzteditdat: displayDefaultDateTime(),
          ersteditdat: displayDefaultDateTime(),
        }
      : {
          ...foreignReceiptEntity,
          processingDate: convertDateTimeFromServer(foreignReceiptEntity.processingDate),
          desiredTime: convertDateTimeFromServer(foreignReceiptEntity.desiredTime),
          ltzteditdat: convertDateTimeFromServer(foreignReceiptEntity.ltzteditdat),
          ersteditdat: convertDateTimeFromServer(foreignReceiptEntity.ersteditdat),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="integrawebApp.foreignReceipt.home.createOrEditLabel" data-cy="ForeignReceiptCreateUpdateHeading">
            <Translate contentKey="integrawebApp.foreignReceipt.home.createOrEditLabel">Create or edit a ForeignReceipt</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="foreign-receipt-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.processType')}
                id="foreign-receipt-processType"
                name="processType"
                data-cy="processType"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.receiptKind')}
                id="foreign-receipt-receiptKind"
                name="receiptKind"
                data-cy="receiptKind"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.receiptDate')}
                id="foreign-receipt-receiptDate"
                name="receiptDate"
                data-cy="receiptDate"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.receiptNo')}
                id="foreign-receipt-receiptNo"
                name="receiptNo"
                data-cy="receiptNo"
                type="text"
                validate={{
                  maxLength: { value: 12, message: translate('entity.validation.maxlength', { max: 12 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.customerId')}
                id="foreign-receipt-customerId"
                name="customerId"
                data-cy="customerId"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.addressId')}
                id="foreign-receipt-addressId"
                name="addressId"
                data-cy="addressId"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.contactPersonId')}
                id="foreign-receipt-contactPersonId"
                name="contactPersonId"
                data-cy="contactPersonId"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.diffInvoiceRecipient')}
                id="foreign-receipt-diffInvoiceRecipient"
                name="diffInvoiceRecipient"
                data-cy="diffInvoiceRecipient"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.supplierId')}
                id="foreign-receipt-supplierId"
                name="supplierId"
                data-cy="supplierId"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.supplierAddress')}
                id="foreign-receipt-supplierAddress"
                name="supplierAddress"
                data-cy="supplierAddress"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.diffDebtor')}
                id="foreign-receipt-diffDebtor"
                name="diffDebtor"
                data-cy="diffDebtor"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.diffCreditor')}
                id="foreign-receipt-diffCreditor"
                name="diffCreditor"
                data-cy="diffCreditor"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.warehouseId')}
                id="foreign-receipt-warehouseId"
                name="warehouseId"
                data-cy="warehouseId"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.proofType')}
                id="foreign-receipt-proofType"
                name="proofType"
                data-cy="proofType"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.blocked')}
                id="foreign-receipt-blocked"
                name="blocked"
                data-cy="blocked"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.barcode')}
                id="foreign-receipt-barcode"
                name="barcode"
                data-cy="barcode"
                type="text"
                validate={{
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.externalReceipt')}
                id="foreign-receipt-externalReceipt"
                name="externalReceipt"
                data-cy="externalReceipt"
                type="text"
                validate={{
                  maxLength: { value: 30, message: translate('entity.validation.maxlength', { max: 30 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.receivingWarehouse')}
                id="foreign-receipt-receivingWarehouse"
                name="receivingWarehouse"
                data-cy="receivingWarehouse"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.externalReceiptDate')}
                id="foreign-receipt-externalReceiptDate"
                name="externalReceiptDate"
                data-cy="externalReceiptDate"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.costCenter')}
                id="foreign-receipt-costCenter"
                name="costCenter"
                data-cy="costCenter"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.costCenterDischarge')}
                id="foreign-receipt-costCenterDischarge"
                name="costCenterDischarge"
                data-cy="costCenterDischarge"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.costUnit')}
                id="foreign-receipt-costUnit"
                name="costUnit"
                data-cy="costUnit"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.projectId')}
                id="foreign-receipt-projectId"
                name="projectId"
                data-cy="projectId"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.glAccount')}
                id="foreign-receipt-glAccount"
                name="glAccount"
                data-cy="glAccount"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.paymentType')}
                id="foreign-receipt-paymentType"
                name="paymentType"
                data-cy="paymentType"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.priceGroup')}
                id="foreign-receipt-priceGroup"
                name="priceGroup"
                data-cy="priceGroup"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.discountGroup')}
                id="foreign-receipt-discountGroup"
                name="discountGroup"
                data-cy="discountGroup"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.deliveryCondition')}
                id="foreign-receipt-deliveryCondition"
                name="deliveryCondition"
                data-cy="deliveryCondition"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.paymentCondition')}
                id="foreign-receipt-paymentCondition"
                name="paymentCondition"
                data-cy="paymentCondition"
                type="text"
                validate={{
                  maxLength: { value: 5, message: translate('entity.validation.maxlength', { max: 5 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.currency')}
                id="foreign-receipt-currency"
                name="currency"
                data-cy="currency"
                type="text"
                validate={{
                  maxLength: { value: 3, message: translate('entity.validation.maxlength', { max: 3 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.exchangeRate')}
                id="foreign-receipt-exchangeRate"
                name="exchangeRate"
                data-cy="exchangeRate"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.netrGross')}
                id="foreign-receipt-netrGross"
                name="netrGross"
                data-cy="netrGross"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.shippingMethod')}
                id="foreign-receipt-shippingMethod"
                name="shippingMethod"
                data-cy="shippingMethod"
                type="text"
                validate={{
                  maxLength: { value: 5, message: translate('entity.validation.maxlength', { max: 5 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.desiredDate')}
                id="foreign-receipt-desiredDate"
                name="desiredDate"
                data-cy="desiredDate"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.deliveryTimeDays')}
                id="foreign-receipt-deliveryTimeDays"
                name="deliveryTimeDays"
                data-cy="deliveryTimeDays"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.deliverDate')}
                id="foreign-receipt-deliverDate"
                name="deliverDate"
                data-cy="deliverDate"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.creditOrDelivery')}
                id="foreign-receipt-creditOrDelivery"
                name="creditOrDelivery"
                data-cy="creditOrDelivery"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.hasShortageSurcharge')}
                id="foreign-receipt-hasShortageSurcharge"
                name="hasShortageSurcharge"
                data-cy="hasShortageSurcharge"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.tourId')}
                id="foreign-receipt-tourId"
                name="tourId"
                data-cy="tourId"
                type="text"
                validate={{
                  maxLength: { value: 5, message: translate('entity.validation.maxlength', { max: 5 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.rideId')}
                id="foreign-receipt-rideId"
                name="rideId"
                data-cy="rideId"
                type="text"
                validate={{
                  maxLength: { value: 2, message: translate('entity.validation.maxlength', { max: 2 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.externalNo')}
                id="foreign-receipt-externalNo"
                name="externalNo"
                data-cy="externalNo"
                type="text"
                validate={{
                  maxLength: { value: 30, message: translate('entity.validation.maxlength', { max: 30 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.externalDate')}
                id="foreign-receipt-externalDate"
                name="externalDate"
                data-cy="externalDate"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.partialDeliveryAllowed')}
                id="foreign-receipt-partialDeliveryAllowed"
                name="partialDeliveryAllowed"
                data-cy="partialDeliveryAllowed"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.offerValidUntil')}
                id="foreign-receipt-offerValidUntil"
                name="offerValidUntil"
                data-cy="offerValidUntil"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.completionRate')}
                id="foreign-receipt-completionRate"
                name="completionRate"
                data-cy="completionRate"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.dutyFree')}
                id="foreign-receipt-dutyFree"
                name="dutyFree"
                data-cy="dutyFree"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.textId')}
                id="foreign-receipt-textId"
                name="textId"
                data-cy="textId"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.employeeId')}
                id="foreign-receipt-employeeId"
                name="employeeId"
                data-cy="employeeId"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.processingDate')}
                id="foreign-receipt-processingDate"
                name="processingDate"
                data-cy="processingDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.errorText')}
                id="foreign-receipt-errorText"
                name="errorText"
                data-cy="errorText"
                type="textarea"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.receiptDid')}
                id="foreign-receipt-receiptDid"
                name="receiptDid"
                data-cy="receiptDid"
                type="text"
                validate={{
                  maxLength: { value: 38, message: translate('entity.validation.maxlength', { max: 38 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.billingNo')}
                id="foreign-receipt-billingNo"
                name="billingNo"
                data-cy="billingNo"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.receiptDidSource')}
                id="foreign-receipt-receiptDidSource"
                name="receiptDidSource"
                data-cy="receiptDidSource"
                type="text"
                validate={{
                  maxLength: { value: 38, message: translate('entity.validation.maxlength', { max: 38 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.keyWord')}
                id="foreign-receipt-keyWord"
                name="keyWord"
                data-cy="keyWord"
                type="text"
                validate={{
                  maxLength: { value: 40, message: translate('entity.validation.maxlength', { max: 40 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.valid')}
                id="foreign-receipt-valid"
                name="valid"
                data-cy="valid"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.bookingDate')}
                id="foreign-receipt-bookingDate"
                name="bookingDate"
                data-cy="bookingDate"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.branch')}
                id="foreign-receipt-branch"
                name="branch"
                data-cy="branch"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.desiredTime')}
                id="foreign-receipt-desiredTime"
                name="desiredTime"
                data-cy="desiredTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.deliveryTime')}
                id="foreign-receipt-deliveryTime"
                name="deliveryTime"
                data-cy="deliveryTime"
                type="text"
                validate={{
                  maxLength: { value: 30, message: translate('entity.validation.maxlength', { max: 30 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.referenceId')}
                id="foreign-receipt-referenceId"
                name="referenceId"
                data-cy="referenceId"
                type="text"
                validate={{
                  maxLength: { value: 27, message: translate('entity.validation.maxlength', { max: 27 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.countryCode')}
                id="foreign-receipt-countryCode"
                name="countryCode"
                data-cy="countryCode"
                type="text"
                validate={{
                  maxLength: { value: 3, message: translate('entity.validation.maxlength', { max: 3 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.bankCode')}
                id="foreign-receipt-bankCode"
                name="bankCode"
                data-cy="bankCode"
                type="text"
                validate={{
                  maxLength: { value: 11, message: translate('entity.validation.maxlength', { max: 11 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.bankAccount')}
                id="foreign-receipt-bankAccount"
                name="bankAccount"
                data-cy="bankAccount"
                type="text"
                validate={{
                  maxLength: { value: 40, message: translate('entity.validation.maxlength', { max: 40 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.deliveryBy')}
                id="foreign-receipt-deliveryBy"
                name="deliveryBy"
                data-cy="deliveryBy"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.deliveryTo')}
                id="foreign-receipt-deliveryTo"
                name="deliveryTo"
                data-cy="deliveryTo"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.ltzteditdat')}
                id="foreign-receipt-ltzteditdat"
                name="ltzteditdat"
                data-cy="ltzteditdat"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.ersteditdat')}
                id="foreign-receipt-ersteditdat"
                name="ersteditdat"
                data-cy="ersteditdat"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.deliveryOption')}
                id="foreign-receipt-deliveryOption"
                name="deliveryOption"
                data-cy="deliveryOption"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.vatId')}
                id="foreign-receipt-vatId"
                name="vatId"
                data-cy="vatId"
                type="text"
                validate={{
                  maxLength: { value: 17, message: translate('entity.validation.maxlength', { max: 17 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.taxCountry')}
                id="foreign-receipt-taxCountry"
                name="taxCountry"
                data-cy="taxCountry"
                type="text"
                validate={{
                  maxLength: { value: 3, message: translate('entity.validation.maxlength', { max: 3 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.transferNo')}
                id="foreign-receipt-transferNo"
                name="transferNo"
                data-cy="transferNo"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.transferNoAlt')}
                id="foreign-receipt-transferNoAlt"
                name="transferNoAlt"
                data-cy="transferNoAlt"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.campaignId')}
                id="foreign-receipt-campaignId"
                name="campaignId"
                data-cy="campaignId"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.foreignCurrency')}
                id="foreign-receipt-foreignCurrency"
                name="foreignCurrency"
                data-cy="foreignCurrency"
                type="text"
                validate={{
                  maxLength: { value: 3, message: translate('entity.validation.maxlength', { max: 3 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.foreignCurrencyRate')}
                id="foreign-receipt-foreignCurrencyRate"
                name="foreignCurrencyRate"
                data-cy="foreignCurrencyRate"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.foreignReceipt.customsReceiptId')}
                id="foreign-receipt-customsReceiptId"
                name="customsReceiptId"
                data-cy="customsReceiptId"
                type="text"
                validate={{
                  maxLength: { value: 30, message: translate('entity.validation.maxlength', { max: 30 }) },
                }}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/foreign-receipt" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ForeignReceiptUpdate;
