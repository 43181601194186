import dayjs from 'dayjs';

export interface INVEInhaltWareneingang {
  id?: any;
  articleId?: string | null;
  quantityId?: string | null;
  bookIdNr?: number | null;
  ean?: string | null;
  batchId?: string | null;
  fabricationDate?: string | null;
  expireDate?: string | null;
  quantity?: number | null;
  quantityForeign?: number | null;
  weight?: number | null;
  employeeontrolId?: string | null;
  quantityControl?: number | null;
  controlDate?: string | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
}

export const defaultValue: Readonly<INVEInhaltWareneingang> = {};
