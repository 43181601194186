import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import NVEInhaltWareneingang from './nve-inhalt-wareneingang';
import NVEInhaltWareneingangDetail from './nve-inhalt-wareneingang-detail';
import NVEInhaltWareneingangUpdate from './nve-inhalt-wareneingang-update';
import NVEInhaltWareneingangDeleteDialog from './nve-inhalt-wareneingang-delete-dialog';

const NVEInhaltWareneingangRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<NVEInhaltWareneingang />} />
    <Route path="new" element={<NVEInhaltWareneingangUpdate />} />
    <Route path=":id">
      <Route index element={<NVEInhaltWareneingangDetail />} />
      <Route path="edit" element={<NVEInhaltWareneingangUpdate />} />
      <Route path="delete" element={<NVEInhaltWareneingangDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default NVEInhaltWareneingangRoutes;
