import dayjs from 'dayjs';

export interface ICompany {
  id?: number;
  description?: string | null;
  globalLocationNo?: string | null;
  taxNo?: string | null;
  countryCodeId?: string | null;
  currencyId?: string | null;
  databaseType?: number | null;
  databaseConnect?: string | null;
  databaseParameter1?: string | null;
  taxId?: number | null;
  defaultBranchId?: number | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
}

export const defaultValue: Readonly<ICompany> = {};
