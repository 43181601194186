import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Receipt from './receipt';
import ReceiptDetail from './receipt-detail';
import ReceiptUpdate from './receipt-update';
import ReceiptDeleteDialog from './receipt-delete-dialog';

const ReceiptRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Receipt />} />
    <Route path="new" element={<ReceiptUpdate />} />
    <Route path=":id">
      <Route index element={<ReceiptDetail />} />
      <Route path="edit" element={<ReceiptUpdate />} />
      <Route path="delete" element={<ReceiptDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ReceiptRoutes;
