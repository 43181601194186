import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IVehicle } from 'app/shared/model/vehicle.model';
import { getEntity, updateEntity, createEntity, reset } from './vehicle.reducer';

export const VehicleUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const vehicleEntity = useAppSelector(state => state.vehicle.entity);
  const loading = useAppSelector(state => state.vehicle.loading);
  const updating = useAppSelector(state => state.vehicle.updating);
  const updateSuccess = useAppSelector(state => state.vehicle.updateSuccess);

  const handleClose = () => {
    navigate('/vehicle' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.lastModifiedDate = convertDateTimeToServer(values.lastModifiedDate);
    values.lockedDateFrom = convertDateTimeToServer(values.lockedDateFrom);
    values.lockedDateTill = convertDateTimeToServer(values.lockedDateTill);

    const entity = {
      ...vehicleEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdDate: displayDefaultDateTime(),
          lastModifiedDate: displayDefaultDateTime(),
          lockedDateFrom: displayDefaultDateTime(),
          lockedDateTill: displayDefaultDateTime(),
        }
      : {
          ...vehicleEntity,
          createdDate: convertDateTimeFromServer(vehicleEntity.createdDate),
          lastModifiedDate: convertDateTimeFromServer(vehicleEntity.lastModifiedDate),
          lockedDateFrom: convertDateTimeFromServer(vehicleEntity.lockedDateFrom),
          lockedDateTill: convertDateTimeFromServer(vehicleEntity.lockedDateTill),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="integrawebApp.vehicle.home.createOrEditLabel" data-cy="VehicleCreateUpdateHeading">
            <Translate contentKey="integrawebApp.vehicle.home.createOrEditLabel">Create or edit a Vehicle</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="vehicle-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('integrawebApp.vehicle.vehicleName')}
                id="vehicle-vehicleName"
                name="vehicleName"
                data-cy="vehicleName"
                type="text"
                validate={{
                  maxLength: { value: 40, message: translate('entity.validation.maxlength', { max: 40 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.licensePlate')}
                id="vehicle-licensePlate"
                name="licensePlate"
                data-cy="licensePlate"
                type="text"
                validate={{
                  maxLength: { value: 13, message: translate('entity.validation.maxlength', { max: 13 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.vehicleType')}
                id="vehicle-vehicleType"
                name="vehicleType"
                data-cy="vehicleType"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.costCenter')}
                id="vehicle-costCenter"
                name="costCenter"
                data-cy="costCenter"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.registrationDate')}
                id="vehicle-registrationDate"
                name="registrationDate"
                data-cy="registrationDate"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.payloadTons')}
                id="vehicle-payloadTons"
                name="payloadTons"
                data-cy="payloadTons"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.loadingVolume')}
                id="vehicle-loadingVolume"
                name="loadingVolume"
                data-cy="loadingVolume"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.fixedCosts')}
                id="vehicle-fixedCosts"
                name="fixedCosts"
                data-cy="fixedCosts"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.variableCosts')}
                id="vehicle-variableCosts"
                name="variableCosts"
                data-cy="variableCosts"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.trailerSurcharge')}
                id="vehicle-trailerSurcharge"
                name="trailerSurcharge"
                data-cy="trailerSurcharge"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.performanceKW')}
                id="vehicle-performanceKW"
                name="performanceKW"
                data-cy="performanceKW"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.trailerSurchargeMinute')}
                id="vehicle-trailerSurchargeMinute"
                name="trailerSurchargeMinute"
                data-cy="trailerSurchargeMinute"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.correctionFactor')}
                id="vehicle-correctionFactor"
                name="correctionFactor"
                data-cy="correctionFactor"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.correctionSetupTime')}
                id="vehicle-correctionSetupTime"
                name="correctionSetupTime"
                data-cy="correctionSetupTime"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.cargoAreaSM')}
                id="vehicle-cargoAreaSM"
                name="cargoAreaSM"
                data-cy="cargoAreaSM"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.correctionSpecifiedTime')}
                id="vehicle-correctionSpecifiedTime"
                name="correctionSpecifiedTime"
                data-cy="correctionSpecifiedTime"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.maxPitches')}
                id="vehicle-maxPitches"
                name="maxPitches"
                data-cy="maxPitches"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.rearLoading')}
                id="vehicle-rearLoading"
                name="rearLoading"
                data-cy="rearLoading"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.freightRate')}
                id="vehicle-freightRate"
                name="freightRate"
                data-cy="freightRate"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.recordStatus')}
                id="vehicle-recordStatus"
                name="recordStatus"
                data-cy="recordStatus"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.carrierId')}
                id="vehicle-carrierId"
                name="carrierId"
                data-cy="carrierId"
                type="text"
                validate={{
                  maxLength: { value: 5, message: translate('entity.validation.maxlength', { max: 5 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.customerId')}
                id="vehicle-customerId"
                name="customerId"
                data-cy="customerId"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.supplierId')}
                id="vehicle-supplierId"
                name="supplierId"
                data-cy="supplierId"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.vehicleCertification')}
                id="vehicle-vehicleCertification"
                name="vehicleCertification"
                data-cy="vehicleCertification"
                type="text"
                validate={{
                  maxLength: { value: 2, message: translate('entity.validation.maxlength', { max: 2 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.setId')}
                id="vehicle-setId"
                name="setId"
                data-cy="setId"
                type="text"
                validate={{
                  maxLength: { value: 38, message: translate('entity.validation.maxlength', { max: 38 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.createdDate')}
                id="vehicle-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.createdBy')}
                id="vehicle-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
                validate={{
                  maxLength: { value: 4, message: translate('entity.validation.maxlength', { max: 4 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.lastModifiedDate')}
                id="vehicle-lastModifiedDate"
                name="lastModifiedDate"
                data-cy="lastModifiedDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.lastModifiedBy')}
                id="vehicle-lastModifiedBy"
                name="lastModifiedBy"
                data-cy="lastModifiedBy"
                type="text"
                validate={{
                  maxLength: { value: 4, message: translate('entity.validation.maxlength', { max: 4 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.noMDEdevice')}
                id="vehicle-noMDEdevice"
                name="noMDEdevice"
                data-cy="noMDEdevice"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.lockedDateFrom')}
                id="vehicle-lockedDateFrom"
                name="lockedDateFrom"
                data-cy="lockedDateFrom"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.lockedDateTill')}
                id="vehicle-lockedDateTill"
                name="lockedDateTill"
                data-cy="lockedDateTill"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.siteWages')}
                id="vehicle-siteWages"
                name="siteWages"
                data-cy="siteWages"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.carTypeWages')}
                id="vehicle-carTypeWages"
                name="carTypeWages"
                data-cy="carTypeWages"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.tareWeightTons')}
                id="vehicle-tareWeightTons"
                name="tareWeightTons"
                data-cy="tareWeightTons"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.tankFillingKG')}
                id="vehicle-tankFillingKG"
                name="tankFillingKG"
                data-cy="tankFillingKG"
                type="text"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.nextTUVDate')}
                id="vehicle-nextTUVDate"
                name="nextTUVDate"
                data-cy="nextTUVDate"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.nextASUDate')}
                id="vehicle-nextASUDate"
                name="nextASUDate"
                data-cy="nextASUDate"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.certificationTill')}
                id="vehicle-certificationTill"
                name="certificationTill"
                data-cy="certificationTill"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.leasingStart')}
                id="vehicle-leasingStart"
                name="leasingStart"
                data-cy="leasingStart"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.leasingEnd')}
                id="vehicle-leasingEnd"
                name="leasingEnd"
                data-cy="leasingEnd"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.nextInspectionDate')}
                id="vehicle-nextInspectionDate"
                name="nextInspectionDate"
                data-cy="nextInspectionDate"
                type="date"
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.cardId')}
                id="vehicle-cardId"
                name="cardId"
                data-cy="cardId"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('integrawebApp.vehicle.emissionRate')}
                id="vehicle-emissionRate"
                name="emissionRate"
                data-cy="emissionRate"
                type="text"
                validate={{
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/vehicle" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default VehicleUpdate;
