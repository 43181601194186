import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './nve.reducer';

export const NVEDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const nVEEntity = useAppSelector(state => state.nVE.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="nVEDetailsHeading">
          <Translate contentKey="integrawebApp.nVE.detail.title">NVE</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.id}</dd>
          <dt>
            <span id="transportUnit">
              <Translate contentKey="integrawebApp.nVE.transportUnit">Transport Unit</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.transportUnit}</dd>
          <dt>
            <span id="nveNr">
              <Translate contentKey="integrawebApp.nVE.nveNr">Nve Nr</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.nveNr}</dd>
          <dt>
            <span id="nveOwn">
              <Translate contentKey="integrawebApp.nVE.nveOwn">Nve Own</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.nveOwn}</dd>
          <dt>
            <span id="nveSupplier">
              <Translate contentKey="integrawebApp.nVE.nveSupplier">Nve Supplier</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.nveSupplier}</dd>
          <dt>
            <span id="supplierId">
              <Translate contentKey="integrawebApp.nVE.supplierId">Supplier Id</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.supplierId}</dd>
          <dt>
            <span id="supplierAddress">
              <Translate contentKey="integrawebApp.nVE.supplierAddress">Supplier Address</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.supplierAddress}</dd>
          <dt>
            <span id="weight">
              <Translate contentKey="integrawebApp.nVE.weight">Weight</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.weight}</dd>
          <dt>
            <span id="timeFrom">
              <Translate contentKey="integrawebApp.nVE.timeFrom">Time From</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.timeFrom ? <TextFormat value={nVEEntity.timeFrom} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="timeEnd">
              <Translate contentKey="integrawebApp.nVE.timeEnd">Time End</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.timeEnd ? <TextFormat value={nVEEntity.timeEnd} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="integrawebApp.nVE.status">Status</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.status}</dd>
          <dt>
            <span id="note">
              <Translate contentKey="integrawebApp.nVE.note">Note</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.note}</dd>
          <dt>
            <span id="receiptDid">
              <Translate contentKey="integrawebApp.nVE.receiptDid">Receipt Did</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.receiptDid}</dd>
          <dt>
            <span id="loadingNr">
              <Translate contentKey="integrawebApp.nVE.loadingNr">Loading Nr</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.loadingNr}</dd>
          <dt>
            <span id="lockNr">
              <Translate contentKey="integrawebApp.nVE.lockNr">Lock Nr</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.lockNr}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="integrawebApp.nVE.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.createdDate ? <TextFormat value={nVEEntity.createdDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="integrawebApp.nVE.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.createdBy}</dd>
          <dt>
            <span id="lastModifiedDate">
              <Translate contentKey="integrawebApp.nVE.lastModifiedDate">Last Modified Date</Translate>
            </span>
          </dt>
          <dd>
            {nVEEntity.lastModifiedDate ? <TextFormat value={nVEEntity.lastModifiedDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="lastModifiedBy">
              <Translate contentKey="integrawebApp.nVE.lastModifiedBy">Last Modified By</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.lastModifiedBy}</dd>
          <dt>
            <span id="labelingDate">
              <Translate contentKey="integrawebApp.nVE.labelingDate">Labeling Date</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.labelingDate ? <TextFormat value={nVEEntity.labelingDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="labelingTime">
              <Translate contentKey="integrawebApp.nVE.labelingTime">Labeling Time</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.labelingTime ? <TextFormat value={nVEEntity.labelingTime} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="idleTime">
              <Translate contentKey="integrawebApp.nVE.idleTime">Idle Time</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.idleTime ? <TextFormat value={nVEEntity.idleTime} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="quantity">
              <Translate contentKey="integrawebApp.nVE.quantity">Quantity</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.quantity}</dd>
          <dt>
            <span id="packageType">
              <Translate contentKey="integrawebApp.nVE.packageType">Package Type</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.packageType}</dd>
          <dt>
            <span id="quantityPackingUnit">
              <Translate contentKey="integrawebApp.nVE.quantityPackingUnit">Quantity Packing Unit</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.quantityPackingUnit}</dd>
          <dt>
            <span id="positionNve">
              <Translate contentKey="integrawebApp.nVE.positionNve">Position Nve</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.positionNve}</dd>
          <dt>
            <span id="palletType">
              <Translate contentKey="integrawebApp.nVE.palletType">Pallet Type</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.palletType}</dd>
          <dt>
            <span id="storageId">
              <Translate contentKey="integrawebApp.nVE.storageId">Storage Id</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.storageId}</dd>
          <dt>
            <span id="warehouseId">
              <Translate contentKey="integrawebApp.nVE.warehouseId">Warehouse Id</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.warehouseId}</dd>
          <dt>
            <span id="timeMinute">
              <Translate contentKey="integrawebApp.nVE.timeMinute">Time Minute</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.timeMinute}</dd>
          <dt>
            <span id="dutyReferenceNr">
              <Translate contentKey="integrawebApp.nVE.dutyReferenceNr">Duty Reference Nr</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.dutyReferenceNr}</dd>
          <dt>
            <span id="mixedPallet">
              <Translate contentKey="integrawebApp.nVE.mixedPallet">Mixed Pallet</Translate>
            </span>
          </dt>
          <dd>{nVEEntity.mixedPallet}</dd>
        </dl>
        <Button tag={Link} to="/nve" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/nve/${nVEEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default NVEDetail;
