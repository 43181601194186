import dayjs from 'dayjs';

export interface INVEInhaltWarenausgang {
  id?: any;
  quantityId?: string | null;
  bookIdNr?: number | null;
  weight?: number | null;
  employeeId?: string | null;
  quantity?: number | null;
  employeeontrolId?: string | null;
  quantityControl?: number | null;
  controlDate?: string | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
}

export const defaultValue: Readonly<INVEInhaltWarenausgang> = {};
