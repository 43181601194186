import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './data-identifier-ssc.reducer';

export const DataIdentifierSSCDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const dataIdentifierSSCEntity = useAppSelector(state => state.dataIdentifierSSC.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="dataIdentifierSSCDetailsHeading">
          <Translate contentKey="integrawebApp.dataIdentifierSSC.detail.title">DataIdentifierSSC</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{dataIdentifierSSCEntity.id}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="integrawebApp.dataIdentifierSSC.description">Description</Translate>
            </span>
          </dt>
          <dd>{dataIdentifierSSCEntity.description}</dd>
          <dt>
            <span id="shortDesc">
              <Translate contentKey="integrawebApp.dataIdentifierSSC.shortDesc">Short Desc</Translate>
            </span>
          </dt>
          <dd>{dataIdentifierSSCEntity.shortDesc}</dd>
          <dt>
            <span id="shortDescInt">
              <Translate contentKey="integrawebApp.dataIdentifierSSC.shortDescInt">Short Desc Int</Translate>
            </span>
          </dt>
          <dd>{dataIdentifierSSCEntity.shortDescInt}</dd>
          <dt>
            <span id="type">
              <Translate contentKey="integrawebApp.dataIdentifierSSC.type">Type</Translate>
            </span>
          </dt>
          <dd>{dataIdentifierSSCEntity.type}</dd>
          <dt>
            <span id="length">
              <Translate contentKey="integrawebApp.dataIdentifierSSC.length">Length</Translate>
            </span>
          </dt>
          <dd>{dataIdentifierSSCEntity.length}</dd>
          <dt>
            <span id="special">
              <Translate contentKey="integrawebApp.dataIdentifierSSC.special">Special</Translate>
            </span>
          </dt>
          <dd>{dataIdentifierSSCEntity.special}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="integrawebApp.dataIdentifierSSC.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {dataIdentifierSSCEntity.createdDate ? (
              <TextFormat value={dataIdentifierSSCEntity.createdDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="integrawebApp.dataIdentifierSSC.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{dataIdentifierSSCEntity.createdBy}</dd>
          <dt>
            <span id="lastModifiedDate">
              <Translate contentKey="integrawebApp.dataIdentifierSSC.lastModifiedDate">Last Modified Date</Translate>
            </span>
          </dt>
          <dd>
            {dataIdentifierSSCEntity.lastModifiedDate ? (
              <TextFormat value={dataIdentifierSSCEntity.lastModifiedDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="lastModifiedBy">
              <Translate contentKey="integrawebApp.dataIdentifierSSC.lastModifiedBy">Last Modified By</Translate>
            </span>
          </dt>
          <dd>{dataIdentifierSSCEntity.lastModifiedBy}</dd>
        </dl>
        <Button tag={Link} to="/data-identifier-ssc" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/data-identifier-ssc/${dataIdentifierSSCEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default DataIdentifierSSCDetail;
