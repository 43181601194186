import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { login } from 'app/shared/reducers/authentication';
import LoginModal from './login-modal';
import { getCustomEntities as getCompanies } from 'app/entities/company/company.reducer';
import { getCustomEntities as getBranches } from 'app/entities/branch-master/branch-master.reducer';
import axios, { AxiosResponse } from 'axios';

export const Login = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const loginError = useAppSelector(state => state.authentication.loginError);
  const showModalLogin = useAppSelector(state => state.authentication.showModalLogin);
  const companies =  useAppSelector(state => state.company.customEntities);
  const branches =  useAppSelector(state => state.branchMaster.customEntities);

  const [showModal, setShowModal] = useState(showModalLogin);
  const navigate = useNavigate();
  const location = useLocation();

  const [bearer, setBearer] = useState('');

  useEffect(() => {
    setShowModal(true);
  }, []);

  useEffect(() => {
    if (bearer.length !== 0) {
      dispatch(getCompanies(bearer)).then((res) => {
        const response = res.payload as AxiosResponse;
        dispatch(getBranches({companyId: response?.data[0]?.id, bearer}));
      })
    }
  }, [bearer]);

  const handleLogin = (username: string, password: string, companyId: string, branchId: string, rememberMe = false) => {
    if (bearer === '') {
      axios.post('api/authenticate', { username, password, companyId: null, branchId: null, rememberMe }).then(res => {
        setBearer(res.headers.authorization);
      });
    } else {
      dispatch(login(username, password, companyId, branchId, rememberMe));
    }
  };

  const handleClose = () => {
    setShowModal(false);
    navigate('/');
  };

  const { from } = (location.state as any) || { from: { pathname: '/', search: location.search } };
  if (isAuthenticated) {
    return <Navigate to={from} replace />;
  }
  return <LoginModal
    showCompanyAndBranch={bearer.length !== 0}
    onChangeCompany={event => {
      dispatch(getBranches({companyId: event.target.value, bearer}))
    }}
    companies={companies}
    branches={branches}
    showModal={showModal} handleLogin={handleLogin} handleClose={handleClose} loginError={loginError} />;
};

export default Login;
