import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IForeignReceiptPosition } from 'app/shared/model/foreign-receipt-position.model';
import { getEntities, reset } from './foreign-receipt-position.reducer';

export const ForeignReceiptPosition = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );
  const [sorting, setSorting] = useState(false);

  const foreignReceiptPositionList = useAppSelector(state => state.foreignReceiptPosition.entities);
  const loading = useAppSelector(state => state.foreignReceiptPosition.loading);
  const totalItems = useAppSelector(state => state.foreignReceiptPosition.totalItems);
  const links = useAppSelector(state => state.foreignReceiptPosition.links);
  const entity = useAppSelector(state => state.foreignReceiptPosition.entity);
  const updateSuccess = useAppSelector(state => state.foreignReceiptPosition.updateSuccess);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const resetAll = () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  useEffect(() => {
    resetAll();
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      resetAll();
    }
  }, [updateSuccess]);

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage]);

  const handleLoadMore = () => {
    if ((window as any).pageYOffset > 0) {
      setPaginationState({
        ...paginationState,
        activePage: paginationState.activePage + 1,
      });
    }
  };

  useEffect(() => {
    if (sorting) {
      getAllEntities();
      setSorting(false);
    }
  }, [sorting]);

  const sort = p => () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
    setSorting(true);
  };

  const handleSyncList = () => {
    resetAll();
  };

  return (
    <div>
      <h2 id="foreign-receipt-position-heading" data-cy="ForeignReceiptPositionHeading">
        <Translate contentKey="integrawebApp.foreignReceiptPosition.home.title">Foreign Receipt Positions</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="integrawebApp.foreignReceiptPosition.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link
            to="/foreign-receipt-position/new"
            className="btn btn-primary jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="integrawebApp.foreignReceiptPosition.home.createLabel">Create new Foreign Receipt Position</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        <InfiniteScroll
          dataLength={foreignReceiptPositionList ? foreignReceiptPositionList.length : 0}
          next={handleLoadMore}
          hasMore={paginationState.activePage - 1 < links.next}
          loader={<div className="loader">Loading ...</div>}
        >
          {foreignReceiptPositionList && foreignReceiptPositionList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand" onClick={sort('id')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('positionType')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.positionType">Position Type</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('articleId')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.articleId">Article Id</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('ean')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.ean">Ean</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('externalPositionNo')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.externalPositionNo">External Position No</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('blockPrinting')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.blockPrinting">Block Printing</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('positionTreatment')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.positionTreatment">Position Treatment</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('amount')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.amount">Amount</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('saleAmount')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.saleAmount">Sale Amount</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('price')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.price">Price</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('calcDiscount')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.calcDiscount">Calc Discount</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('value')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.value">Value</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('taxes')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.taxes">Taxes</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('foreignCurrencyAmount')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.foreignCurrencyAmount">Foreign Currency Amount</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('costOfSales')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.costOfSales">Cost Of Sales</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('quantPackingUnit')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.quantPackingUnit">Quant Packing Unit</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('packageType')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.packageType">Package Type</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('partialDeliveryAllowed')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.partialDeliveryAllowed">Partial Delivery Allowed</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('orderNo')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.orderNo">Order No</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('customerArticleId')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.customerArticleId">Customer Article Id</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('customerSalesPrice')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.customerSalesPrice">Customer Sales Price</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('taxType')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.taxType">Tax Type</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('pricingType')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.pricingType">Pricing Type</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('warehouseId')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.warehouseId">Warehouse Id</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('storageId')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.storageId">Storage Id</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('batchId')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.batchId">Batch Id</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('expireDate')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.expireDate">Expire Date</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('costUnit')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.costUnit">Cost Unit</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('costCenter')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.costCenter">Cost Center</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('projectId')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.projectId">Project Id</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('taxKey')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.taxKey">Tax Key</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('dutyFree')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.dutyFree">Duty Free</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('textId')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.textId">Text Id</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('valid')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.valid">Valid</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('taxValue')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.taxValue">Tax Value</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('receiptPosDidSource')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.receiptPosDidSource">Receipt Pos Did Source</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('serviceDate')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.serviceDate">Service Date</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('externalReceipt')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.externalReceipt">External Receipt</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('weight')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.weight">Weight</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('weightingWeight')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.weightingWeight">Weighting Weight</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('calculationWeight')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.calculationWeight">Calculation Weight</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('deliveryDate')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.deliveryDate">Delivery Date</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('deliveryDateCW')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.deliveryDateCW">Delivery Date CW</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('deliveryTime')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.deliveryTime">Delivery Time</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('isFixedDate')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.isFixedDate">Is Fixed Date</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('confirmedDate')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.confirmedDate">Confirmed Date</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('originalDate')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.originalDate">Original Date</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('goodsConsumptionAcc')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.goodsConsumptionAcc">Goods Consumption Acc</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('stockAcc')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.stockAcc">Stock Acc</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('positionDid')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.positionDid">Position Did</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('restCancel')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.restCancel">Rest Cancel</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('rollOutType')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.rollOutType">Roll Out Type</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('machineId')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.machineId">Machine Id</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('positionDidCreated')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.positionDidCreated">Position Did Created</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('ersteditdat')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.ersteditdat">Ersteditdat</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('ltzteditdat')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.ltzteditdat">Ltzteditdat</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('receiptControl')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.receiptControl">Receipt Control</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('quantityUnit')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.quantityUnit">Quantity Unit</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('productionNo')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.productionNo">Production No</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('productionSeqNo')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.productionSeqNo">Production Seq No</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('oldCallNo')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.oldCallNo">Old Call No</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('callNo')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.callNo">Call No</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('employeeId')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.employeeId">Employee Id</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('customerThirdPartyVendor')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.customerThirdPartyVendor">
                      Customer Third Party Vendor
                    </Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('requestArticle')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.requestArticle">Request Article</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('customerReference')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.customerReference">Customer Reference</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('internalQuoteId')}>
                    <Translate contentKey="integrawebApp.foreignReceiptPosition.internalQuoteId">Internal Quote Id</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {foreignReceiptPositionList.map((foreignReceiptPosition, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>
                      <Button tag={Link} to={`/foreign-receipt-position/${foreignReceiptPosition.id}`} color="link" size="sm">
                        {foreignReceiptPosition.id}
                      </Button>
                    </td>
                    <td>{foreignReceiptPosition.positionType}</td>
                    <td>{foreignReceiptPosition.articleId}</td>
                    <td>{foreignReceiptPosition.ean}</td>
                    <td>{foreignReceiptPosition.externalPositionNo}</td>
                    <td>{foreignReceiptPosition.blockPrinting}</td>
                    <td>{foreignReceiptPosition.positionTreatment}</td>
                    <td>{foreignReceiptPosition.amount}</td>
                    <td>{foreignReceiptPosition.saleAmount}</td>
                    <td>{foreignReceiptPosition.price}</td>
                    <td>{foreignReceiptPosition.calcDiscount}</td>
                    <td>{foreignReceiptPosition.value}</td>
                    <td>{foreignReceiptPosition.taxes}</td>
                    <td>{foreignReceiptPosition.foreignCurrencyAmount}</td>
                    <td>{foreignReceiptPosition.costOfSales}</td>
                    <td>{foreignReceiptPosition.quantPackingUnit}</td>
                    <td>{foreignReceiptPosition.packageType}</td>
                    <td>{foreignReceiptPosition.partialDeliveryAllowed}</td>
                    <td>{foreignReceiptPosition.orderNo}</td>
                    <td>{foreignReceiptPosition.customerArticleId}</td>
                    <td>{foreignReceiptPosition.customerSalesPrice}</td>
                    <td>{foreignReceiptPosition.taxType}</td>
                    <td>{foreignReceiptPosition.pricingType}</td>
                    <td>{foreignReceiptPosition.warehouseId}</td>
                    <td>{foreignReceiptPosition.storageId}</td>
                    <td>{foreignReceiptPosition.batchId}</td>
                    <td>
                      {foreignReceiptPosition.expireDate ? (
                        <TextFormat type="date" value={foreignReceiptPosition.expireDate} format={APP_LOCAL_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>{foreignReceiptPosition.costUnit}</td>
                    <td>{foreignReceiptPosition.costCenter}</td>
                    <td>{foreignReceiptPosition.projectId}</td>
                    <td>{foreignReceiptPosition.taxKey}</td>
                    <td>{foreignReceiptPosition.dutyFree}</td>
                    <td>{foreignReceiptPosition.textId}</td>
                    <td>{foreignReceiptPosition.valid}</td>
                    <td>{foreignReceiptPosition.taxValue}</td>
                    <td>{foreignReceiptPosition.receiptPosDidSource}</td>
                    <td>
                      {foreignReceiptPosition.serviceDate ? (
                        <TextFormat type="date" value={foreignReceiptPosition.serviceDate} format={APP_LOCAL_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>{foreignReceiptPosition.externalReceipt}</td>
                    <td>{foreignReceiptPosition.weight}</td>
                    <td>{foreignReceiptPosition.weightingWeight}</td>
                    <td>{foreignReceiptPosition.calculationWeight}</td>
                    <td>
                      {foreignReceiptPosition.deliveryDate ? (
                        <TextFormat type="date" value={foreignReceiptPosition.deliveryDate} format={APP_LOCAL_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>
                      {foreignReceiptPosition.deliveryDateCW ? (
                        <TextFormat type="date" value={foreignReceiptPosition.deliveryDateCW} format={APP_LOCAL_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>
                      {foreignReceiptPosition.deliveryTime ? (
                        <TextFormat type="date" value={foreignReceiptPosition.deliveryTime} format={APP_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>{foreignReceiptPosition.isFixedDate}</td>
                    <td>
                      {foreignReceiptPosition.confirmedDate ? (
                        <TextFormat type="date" value={foreignReceiptPosition.confirmedDate} format={APP_LOCAL_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>
                      {foreignReceiptPosition.originalDate ? (
                        <TextFormat type="date" value={foreignReceiptPosition.originalDate} format={APP_LOCAL_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>{foreignReceiptPosition.goodsConsumptionAcc}</td>
                    <td>{foreignReceiptPosition.stockAcc}</td>
                    <td>{foreignReceiptPosition.positionDid}</td>
                    <td>{foreignReceiptPosition.restCancel}</td>
                    <td>{foreignReceiptPosition.rollOutType}</td>
                    <td>{foreignReceiptPosition.machineId}</td>
                    <td>{foreignReceiptPosition.positionDidCreated}</td>
                    <td>
                      {foreignReceiptPosition.ersteditdat ? (
                        <TextFormat type="date" value={foreignReceiptPosition.ersteditdat} format={APP_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>
                      {foreignReceiptPosition.ltzteditdat ? (
                        <TextFormat type="date" value={foreignReceiptPosition.ltzteditdat} format={APP_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>{foreignReceiptPosition.receiptControl}</td>
                    <td>{foreignReceiptPosition.quantityUnit}</td>
                    <td>{foreignReceiptPosition.productionNo}</td>
                    <td>{foreignReceiptPosition.productionSeqNo}</td>
                    <td>{foreignReceiptPosition.oldCallNo}</td>
                    <td>{foreignReceiptPosition.callNo}</td>
                    <td>{foreignReceiptPosition.employeeId}</td>
                    <td>{foreignReceiptPosition.customerThirdPartyVendor}</td>
                    <td>{foreignReceiptPosition.requestArticle}</td>
                    <td>{foreignReceiptPosition.customerReference}</td>
                    <td>{foreignReceiptPosition.internalQuoteId}</td>
                    <td className="text-end">
                      <div className="btn-group flex-btn-group-container">
                        <Button
                          tag={Link}
                          to={`/foreign-receipt-position/${foreignReceiptPosition.id}`}
                          color="info"
                          size="sm"
                          data-cy="entityDetailsButton"
                        >
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button
                          tag={Link}
                          to={`/foreign-receipt-position/${foreignReceiptPosition.id}/edit`}
                          color="primary"
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button
                          tag={Link}
                          to={`/foreign-receipt-position/${foreignReceiptPosition.id}/delete`}
                          color="danger"
                          size="sm"
                          data-cy="entityDeleteButton"
                        >
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="integrawebApp.foreignReceiptPosition.home.notFound">No Foreign Receipt Positions found</Translate>
              </div>
            )
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default ForeignReceiptPosition;
