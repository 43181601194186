import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { byteSize, Translate, TextFormat, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IForeignReceipt } from 'app/shared/model/foreign-receipt.model';
import { getEntities, reset } from './foreign-receipt.reducer';

export const ForeignReceipt = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );
  const [sorting, setSorting] = useState(false);

  const foreignReceiptList = useAppSelector(state => state.foreignReceipt.entities);
  const loading = useAppSelector(state => state.foreignReceipt.loading);
  const totalItems = useAppSelector(state => state.foreignReceipt.totalItems);
  const links = useAppSelector(state => state.foreignReceipt.links);
  const entity = useAppSelector(state => state.foreignReceipt.entity);
  const updateSuccess = useAppSelector(state => state.foreignReceipt.updateSuccess);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const resetAll = () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  useEffect(() => {
    resetAll();
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      resetAll();
    }
  }, [updateSuccess]);

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage]);

  const handleLoadMore = () => {
    if ((window as any).pageYOffset > 0) {
      setPaginationState({
        ...paginationState,
        activePage: paginationState.activePage + 1,
      });
    }
  };

  useEffect(() => {
    if (sorting) {
      getAllEntities();
      setSorting(false);
    }
  }, [sorting]);

  const sort = p => () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
    setSorting(true);
  };

  const handleSyncList = () => {
    resetAll();
  };

  return (
    <div>
      <h2 id="foreign-receipt-heading" data-cy="ForeignReceiptHeading">
        <Translate contentKey="integrawebApp.foreignReceipt.home.title">Foreign Receipts</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="integrawebApp.foreignReceipt.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/foreign-receipt/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="integrawebApp.foreignReceipt.home.createLabel">Create new Foreign Receipt</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        <InfiniteScroll
          dataLength={foreignReceiptList ? foreignReceiptList.length : 0}
          next={handleLoadMore}
          hasMore={paginationState.activePage - 1 < links.next}
          loader={<div className="loader">Loading ...</div>}
        >
          {foreignReceiptList && foreignReceiptList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand" onClick={sort('id')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('processType')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.processType">Process Type</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('receiptKind')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.receiptKind">Receipt Kind</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('receiptDate')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.receiptDate">Receipt Date</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('receiptNo')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.receiptNo">Receipt No</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('customerId')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.customerId">Customer Id</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('addressId')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.addressId">Address Id</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('contactPersonId')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.contactPersonId">Contact Person Id</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('diffInvoiceRecipient')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.diffInvoiceRecipient">Diff Invoice Recipient</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('supplierId')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.supplierId">Supplier Id</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('supplierAddress')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.supplierAddress">Supplier Address</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('diffDebtor')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.diffDebtor">Diff Debtor</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('diffCreditor')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.diffCreditor">Diff Creditor</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('warehouseId')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.warehouseId">Warehouse Id</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('proofType')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.proofType">Proof Type</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('blocked')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.blocked">Blocked</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('barcode')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.barcode">Barcode</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('externalReceipt')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.externalReceipt">External Receipt</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('receivingWarehouse')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.receivingWarehouse">Receiving Warehouse</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('externalReceiptDate')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.externalReceiptDate">External Receipt Date</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('costCenter')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.costCenter">Cost Center</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('costCenterDischarge')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.costCenterDischarge">Cost Center Discharge</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('costUnit')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.costUnit">Cost Unit</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('projectId')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.projectId">Project Id</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('glAccount')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.glAccount">Gl Account</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('paymentType')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.paymentType">Payment Type</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('priceGroup')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.priceGroup">Price Group</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('discountGroup')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.discountGroup">Discount Group</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('deliveryCondition')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.deliveryCondition">Delivery Condition</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('paymentCondition')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.paymentCondition">Payment Condition</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('currency')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.currency">Currency</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('exchangeRate')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.exchangeRate">Exchange Rate</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('netrGross')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.netrGross">Netr Gross</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('shippingMethod')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.shippingMethod">Shipping Method</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('desiredDate')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.desiredDate">Desired Date</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('deliveryTimeDays')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.deliveryTimeDays">Delivery Time Days</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('deliverDate')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.deliverDate">Deliver Date</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('creditOrDelivery')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.creditOrDelivery">Credit Or Delivery</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('hasShortageSurcharge')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.hasShortageSurcharge">Has Shortage Surcharge</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('tourId')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.tourId">Tour Id</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('rideId')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.rideId">Ride Id</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('externalNo')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.externalNo">External No</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('externalDate')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.externalDate">External Date</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('partialDeliveryAllowed')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.partialDeliveryAllowed">Partial Delivery Allowed</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('offerValidUntil')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.offerValidUntil">Offer Valid Until</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('completionRate')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.completionRate">Completion Rate</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('dutyFree')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.dutyFree">Duty Free</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('textId')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.textId">Text Id</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('employeeId')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.employeeId">Employee Id</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('processingDate')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.processingDate">Processing Date</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('errorText')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.errorText">Error Text</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('receiptDid')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.receiptDid">Receipt Did</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('billingNo')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.billingNo">Billing No</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('receiptDidSource')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.receiptDidSource">Receipt Did Source</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('keyWord')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.keyWord">Key Word</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('valid')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.valid">Valid</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('bookingDate')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.bookingDate">Booking Date</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('branch')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.branch">Branch</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('desiredTime')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.desiredTime">Desired Time</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('deliveryTime')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.deliveryTime">Delivery Time</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('referenceId')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.referenceId">Reference Id</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('countryCode')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.countryCode">Country Code</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('bankCode')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.bankCode">Bank Code</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('bankAccount')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.bankAccount">Bank Account</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('deliveryBy')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.deliveryBy">Delivery By</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('deliveryTo')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.deliveryTo">Delivery To</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('ltzteditdat')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.ltzteditdat">Ltzteditdat</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('ersteditdat')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.ersteditdat">Ersteditdat</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('deliveryOption')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.deliveryOption">Delivery Option</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('vatId')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.vatId">Vat Id</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('taxCountry')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.taxCountry">Tax Country</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('transferNo')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.transferNo">Transfer No</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('transferNoAlt')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.transferNoAlt">Transfer No Alt</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('campaignId')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.campaignId">Campaign Id</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('foreignCurrency')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.foreignCurrency">Foreign Currency</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('foreignCurrencyRate')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.foreignCurrencyRate">Foreign Currency Rate</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('customsReceiptId')}>
                    <Translate contentKey="integrawebApp.foreignReceipt.customsReceiptId">Customs Receipt Id</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {foreignReceiptList.map((foreignReceipt, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>
                      <Button tag={Link} to={`/foreign-receipt/${foreignReceipt.id}`} color="link" size="sm">
                        {foreignReceipt.id}
                      </Button>
                    </td>
                    <td>{foreignReceipt.processType}</td>
                    <td>{foreignReceipt.receiptKind}</td>
                    <td>
                      {foreignReceipt.receiptDate ? (
                        <TextFormat type="date" value={foreignReceipt.receiptDate} format={APP_LOCAL_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>{foreignReceipt.receiptNo}</td>
                    <td>{foreignReceipt.customerId}</td>
                    <td>{foreignReceipt.addressId}</td>
                    <td>{foreignReceipt.contactPersonId}</td>
                    <td>{foreignReceipt.diffInvoiceRecipient}</td>
                    <td>{foreignReceipt.supplierId}</td>
                    <td>{foreignReceipt.supplierAddress}</td>
                    <td>{foreignReceipt.diffDebtor}</td>
                    <td>{foreignReceipt.diffCreditor}</td>
                    <td>{foreignReceipt.warehouseId}</td>
                    <td>{foreignReceipt.proofType}</td>
                    <td>{foreignReceipt.blocked}</td>
                    <td>{foreignReceipt.barcode}</td>
                    <td>{foreignReceipt.externalReceipt}</td>
                    <td>{foreignReceipt.receivingWarehouse}</td>
                    <td>
                      {foreignReceipt.externalReceiptDate ? (
                        <TextFormat type="date" value={foreignReceipt.externalReceiptDate} format={APP_LOCAL_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>{foreignReceipt.costCenter}</td>
                    <td>{foreignReceipt.costCenterDischarge}</td>
                    <td>{foreignReceipt.costUnit}</td>
                    <td>{foreignReceipt.projectId}</td>
                    <td>{foreignReceipt.glAccount}</td>
                    <td>{foreignReceipt.paymentType}</td>
                    <td>{foreignReceipt.priceGroup}</td>
                    <td>{foreignReceipt.discountGroup}</td>
                    <td>{foreignReceipt.deliveryCondition}</td>
                    <td>{foreignReceipt.paymentCondition}</td>
                    <td>{foreignReceipt.currency}</td>
                    <td>{foreignReceipt.exchangeRate}</td>
                    <td>{foreignReceipt.netrGross}</td>
                    <td>{foreignReceipt.shippingMethod}</td>
                    <td>
                      {foreignReceipt.desiredDate ? (
                        <TextFormat type="date" value={foreignReceipt.desiredDate} format={APP_LOCAL_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>{foreignReceipt.deliveryTimeDays}</td>
                    <td>
                      {foreignReceipt.deliverDate ? (
                        <TextFormat type="date" value={foreignReceipt.deliverDate} format={APP_LOCAL_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>{foreignReceipt.creditOrDelivery}</td>
                    <td>{foreignReceipt.hasShortageSurcharge}</td>
                    <td>{foreignReceipt.tourId}</td>
                    <td>{foreignReceipt.rideId}</td>
                    <td>{foreignReceipt.externalNo}</td>
                    <td>
                      {foreignReceipt.externalDate ? (
                        <TextFormat type="date" value={foreignReceipt.externalDate} format={APP_LOCAL_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>{foreignReceipt.partialDeliveryAllowed}</td>
                    <td>
                      {foreignReceipt.offerValidUntil ? (
                        <TextFormat type="date" value={foreignReceipt.offerValidUntil} format={APP_LOCAL_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>{foreignReceipt.completionRate}</td>
                    <td>{foreignReceipt.dutyFree}</td>
                    <td>{foreignReceipt.textId}</td>
                    <td>{foreignReceipt.employeeId}</td>
                    <td>
                      {foreignReceipt.processingDate ? (
                        <TextFormat type="date" value={foreignReceipt.processingDate} format={APP_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>{foreignReceipt.errorText}</td>
                    <td>{foreignReceipt.receiptDid}</td>
                    <td>{foreignReceipt.billingNo}</td>
                    <td>{foreignReceipt.receiptDidSource}</td>
                    <td>{foreignReceipt.keyWord}</td>
                    <td>{foreignReceipt.valid}</td>
                    <td>
                      {foreignReceipt.bookingDate ? (
                        <TextFormat type="date" value={foreignReceipt.bookingDate} format={APP_LOCAL_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>{foreignReceipt.branch}</td>
                    <td>
                      {foreignReceipt.desiredTime ? (
                        <TextFormat type="date" value={foreignReceipt.desiredTime} format={APP_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>{foreignReceipt.deliveryTime}</td>
                    <td>{foreignReceipt.referenceId}</td>
                    <td>{foreignReceipt.countryCode}</td>
                    <td>{foreignReceipt.bankCode}</td>
                    <td>{foreignReceipt.bankAccount}</td>
                    <td>{foreignReceipt.deliveryBy}</td>
                    <td>{foreignReceipt.deliveryTo}</td>
                    <td>
                      {foreignReceipt.ltzteditdat ? (
                        <TextFormat type="date" value={foreignReceipt.ltzteditdat} format={APP_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>
                      {foreignReceipt.ersteditdat ? (
                        <TextFormat type="date" value={foreignReceipt.ersteditdat} format={APP_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>{foreignReceipt.deliveryOption}</td>
                    <td>{foreignReceipt.vatId}</td>
                    <td>{foreignReceipt.taxCountry}</td>
                    <td>{foreignReceipt.transferNo}</td>
                    <td>{foreignReceipt.transferNoAlt}</td>
                    <td>{foreignReceipt.campaignId}</td>
                    <td>{foreignReceipt.foreignCurrency}</td>
                    <td>{foreignReceipt.foreignCurrencyRate}</td>
                    <td>{foreignReceipt.customsReceiptId}</td>
                    <td className="text-end">
                      <div className="btn-group flex-btn-group-container">
                        <Button
                          tag={Link}
                          to={`/foreign-receipt/${foreignReceipt.id}`}
                          color="info"
                          size="sm"
                          data-cy="entityDetailsButton"
                        >
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button
                          tag={Link}
                          to={`/foreign-receipt/${foreignReceipt.id}/edit`}
                          color="primary"
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button
                          tag={Link}
                          to={`/foreign-receipt/${foreignReceipt.id}/delete`}
                          color="danger"
                          size="sm"
                          data-cy="entityDeleteButton"
                        >
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="integrawebApp.foreignReceipt.home.notFound">No Foreign Receipts found</Translate>
              </div>
            )
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default ForeignReceipt;
