import dayjs from 'dayjs';

export interface IFacilityWarehouse {
  id?: any;
  stdwaein?: boolean | null;
  stdwaaus?: boolean | null;
  stdqs?: boolean | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
}

export const defaultValue: Readonly<IFacilityWarehouse> = {
  stdwaein: false,
  stdwaaus: false,
  stdqs: false,
};
